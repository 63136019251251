import { MultiSelectProps } from '@geeckocom/core-ui';
import { useTranslations } from 'next-intl';
import { forwardRef, ReactElement, Ref } from 'react';

import { ToolResource } from '@/shared/api-utility';
import { MultiSelect } from '@/shared/components/multi-select';

export const getToolSelectOption = (tool: ToolResource): ReactElement<any, any> | null => {
  const { icon_url, name } = tool;

  return (
    <div className="flex space-x-2 items-center">
      {icon_url ? (
        <span className="w-4 h-4 rounded">
          <img className="w-4 h-4 rounded" src={icon_url} alt="" />
        </span>
      ) : null}
      <span className="text-text-primary">{name}</span>
    </div>
  );
};

export const ToolsSelect = forwardRef((props: MultiSelectProps<ToolResource>, ref: Ref<any>) => {
  const t = useTranslations();
  return (
    <MultiSelect<ToolResource>
      ref={ref}
      loadingText={t('shared.components.modern_select.loading')}
      noOptionsPlaceholder={t('shared.components.modern_select.no_options')}
      renderOption={getToolSelectOption}
      renderValue={getToolSelectOption}
      {...props}
    />
  );
});

ToolsSelect.displayName = 'ToolsSelect';
