import { FieldPath, FieldPathValue, FieldValues, Validate } from 'react-hook-form';

import { IntlShape } from '@/shared/types';

export function minYear<TFieldName extends FieldPath<TFieldValues>, TFieldValues extends FieldValues = FieldValues>(
  minYear: number,
  t: IntlShape,
): Validate<FieldPathValue<TFieldValues, TFieldName>> {
  return (value: unknown) => {
    if (typeof value !== 'string') {
      return true;
    }

    if (!/^[12][0-9]{3}$/giu.test(value)) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return t('shared.lib.form_validators.partial_date_is_not_in_future.year_is_not_correct');
    }

    const year = parseInt(value, 10);

    if (year < minYear) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return t('shared.lib.form_validators.min_year', { year: minYear });
    }

    return true;
  };
}
