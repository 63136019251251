import { ToggleGroup, ToggleGroupProps } from '@geeckocom/core-ui';
import get from 'lodash/get';
import { Controller, ControllerProps, FieldValues, useFormState } from 'react-hook-form';

import { FormError } from '@/shared/components/form-error';

type Props<TFieldValues extends FieldValues = FieldValues> = ToggleGroupProps &
  Omit<ControllerProps<TFieldValues>, 'render'>;

export function FormToggleGroup<TFieldValues extends FieldValues = FieldValues>(
  props: Props<TFieldValues>,
): JSX.Element {
  const { name, defaultValue, rules, shouldUnregister, control, ...rest } = props;
  const { errors } = useFormState<TFieldValues>({ control });
  const errorMessage = get(errors, `${name}.message`);

  return (
    <>
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        rules={rules}
        shouldUnregister={shouldUnregister}
        render={({ field }) => <ToggleGroup {...rest} {...field} />}
      />
      {errorMessage ? <FormError>{errorMessage}</FormError> : null}
    </>
  );
}
