import { ResumeFormResource } from '@/features/resume-builder';

export const calculateExperienceFormFillRate = (formValues: ResumeFormResource): number => {
  const allFields = 9;
  let filledFields = 0;

  const experienceFillRates: number[] = formValues.positions
    ? formValues.positions.map((experienceItem) => {
        let experienceFillRate = 0;

        if (experienceItem.position) {
          experienceFillRate += 1;
        }
        if (experienceItem.company) {
          experienceFillRate += 1;
        }
        if (experienceItem.from_month) {
          experienceFillRate += 1;
        }
        if (experienceItem.from_year) {
          experienceFillRate += 1;
        }
        if (experienceItem.current) {
          experienceFillRate += 2;
        } else {
          if (experienceItem.to_month) {
            experienceFillRate += 1;
          }
          if (experienceItem.to_year) {
            experienceFillRate += 1;
          }
        }
        if (experienceItem.description) {
          experienceFillRate += 1;
        }
        if (experienceItem.databases && experienceItem.databases.length > 0) {
          experienceFillRate += 1;
        }
        if (experienceItem.technologies && experienceItem.technologies.length > 0) {
          experienceFillRate += 1;
        }

        return experienceFillRate;
      })
    : [];

  if (experienceFillRates.length > 0) {
    filledFields = Math.max(...experienceFillRates);
  }

  return (filledFields / allFields) * 100;
};
