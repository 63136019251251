import { useMemo } from 'react';

import { FCWithChildren } from '@/shared/types';

interface Props {
  as?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
  size?: '2xl' | '3xl' | '4xl';
}

export const Heading: FCWithChildren<Props> = ({ as = 'h1', size = '3xl', children }) => {
  const Component = as;
  const sizeClass = useMemo(() => {
    switch (size) {
      case '2xl':
        return 'text-2xl';
      case '3xl':
        return 'text-3xl';
      case '4xl':
        return 'text-4xl';
      default:
        return 'text-3xl';
    }
  }, [size]);
  return <Component className={`text-text-primary ${sizeClass} font-bold`}>{children}</Component>;
};
