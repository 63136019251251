import { MultiSelect as CoreMultiSelect, MultiSelectProps } from '@geeckocom/core-ui';
import { useTranslations } from 'next-intl';
import { forwardRef, Ref } from 'react';

const MultiSelectOriginal = forwardRef(
  <ValueType extends Record<string, unknown>>(props: MultiSelectProps<ValueType>, ref: Ref<any>) => {
    const t = useTranslations();
    return (
      <CoreMultiSelect<ValueType>
        ref={ref}
        loadingText={t('shared.components.modern_select.loading')}
        noOptionsPlaceholder={t('shared.components.modern_select.no_options')}
        {...props}
      />
    );
  },
);

MultiSelectOriginal.displayName = 'MultiSelect';

export const MultiSelect = MultiSelectOriginal as <ValueType>(
  props: MultiSelectProps<ValueType> & { ref?: Ref<any> },
) => JSX.Element;
