import { useLocale } from '@/hooks/useLocale';

type NumberFormatOptions = {
  thousandSeparator: string;
  decimalSeparator: string;
};

const defaultOptions: NumberFormatOptions = {
  thousandSeparator: ' ',
  decimalSeparator: ',',
};

const numberFormatOptions: Record<string, NumberFormatOptions> = {
  ru: defaultOptions,
  en: { thousandSeparator: ',', decimalSeparator: '.' },
};

export const useNumberFormat = (): NumberFormatOptions => {
  const locale = useLocale();

  return numberFormatOptions[locale.locale] || defaultOptions;
};
