import dayjs from 'dayjs';
import { FieldPath, FieldPathValue, FieldValues, Path, UseFormGetValues, Validate } from 'react-hook-form';

import { IntlShape } from '@/shared/types';

export function partialDateIsNotInFuture<
  TFieldName extends FieldPath<TFieldValues>,
  TFieldValues extends FieldValues = FieldValues,
>(
  getValues: UseFormGetValues<TFieldValues>,
  monthField: TFieldName,
  yearField: TFieldName,
  t: IntlShape,
  onYearStateChange: (isValid: boolean) => void,
  nullable?: boolean,
): Validate<FieldPathValue<TFieldValues, Path<TFieldValues>>> {
  return () => {
    onYearStateChange(true);
    const month = getValues(monthField);
    const year = getValues(yearField);

    if (!month && !nullable) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return t('shared.lib.form_validators.partial_date_is_not_in_future.month_required');
    }

    if (!/^[12][0-9]{3}$/giu.test(year)) {
      return true;
    }

    const date = !nullable ? dayjs(`${month} ${year}`, 'MMM YYYY').tz() : dayjs(`${year}`, 'YYYY').tz();
    if (!date.isValid()) {
      onYearStateChange(false);
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return t('shared.lib.form_validators.partial_date_is_not_in_future.date_is_not_correct');
    }

    if (date.isAfter(dayjs().tz())) {
      onYearStateChange(false);
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return t('shared.lib.form_validators.partial_date_is_not_in_future.date_is_in_future');
    }

    return true;
  };
}
