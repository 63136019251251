import clsx from 'clsx';
import { CSSProperties, FC, useMemo, useState } from 'react';

interface Props {
  width: number;
  height: number;
  embedUrl: string;
  embedTitle?: string;
  thumbnailUrl: string;
  thumbnailAlt?: string;
}

type PlayerState = 'cover' | 'player';

export const EmbedVideoPlayer: FC<Props> = ({ width, height, embedUrl, embedTitle, thumbnailUrl, thumbnailAlt }) => {
  const [state, setState] = useState<PlayerState>('cover');

  const handleShowEmbed = () => {
    setState('player');
  };

  const paddingStyles: CSSProperties = useMemo(
    () => ({
      paddingTop: `${(height / width) * 100}%`,
    }),
    [width, height],
  );

  return (
    <div className={clsx('rounded-2xl bg-gray-50 mt-4 relative overflow-hidden')} style={paddingStyles}>
      {state === 'cover' ? (
        <>
          <img className="absolute inset-0 w-full h-full rounded-2xl" src={thumbnailUrl} alt={thumbnailAlt} />
          <button
            className="absolute inset-0 w-full flex items-center justify-center opacity-75 hover:opacity-100"
            type="button"
            onClick={handleShowEmbed}
          >
            <svg width="55" height="55" viewBox="0 0 55 55" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle opacity="0.6" cx="27.5" cy="27.5" r="27.5" fill="#1F44CC" />
              <path
                d="M18.3333 16.6633C18.3333 15.081 20.0837 14.1253 21.4148 14.981L39.0496 26.3176C40.2742 27.1049 40.2742 28.8951 39.0496 29.6824L21.4148 41.019C20.0837 41.8747 18.3333 40.919 18.3333 39.3367V16.6633Z"
                fill="white"
              />
            </svg>
          </button>
        </>
      ) : (
        <iframe
          src={embedUrl}
          title={embedTitle || 'Video'}
          className="absolute inset-0 w-full h-full"
          frameBorder={0}
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
      )}
    </div>
  );
};
