import { ModernSelect as CoreModernSelect, ModernSelectProps } from '@geeckocom/core-ui';
import { useTranslations } from 'next-intl';
import { forwardRef, Ref } from 'react';

import styles from './styles.module.css';

const ModernSelectOriginal = forwardRef(
  <ValueType extends Record<string, unknown>>(props: ModernSelectProps<ValueType>, ref: Ref<any>) => {
    const t = useTranslations();
    return (
      <div className={styles.wrapper}>
        <CoreModernSelect<ValueType>
          ref={ref}
          loadingText={t('shared.components.modern_select.loading')}
          noOptionsPlaceholder={t('shared.components.modern_select.no_options')}
          addItemText={t('shared.components.modern_select.add_option')}
          loadOnInit
          {...props}
        />
      </div>
    );
  },
);

ModernSelectOriginal.displayName = 'ModernSelect';

export const ModernSelect = ModernSelectOriginal as <ValueType>(
  props: ModernSelectProps<ValueType> & { ref?: Ref<any> },
) => JSX.Element;
