import { FieldPath, FieldPathValue, FieldValues, Validate } from 'react-hook-form';

export function conditionalValidators<
  TFieldName extends FieldPath<TFieldValues>,
  TFieldValues extends FieldValues = FieldValues,
>(
  conditionFn: () => boolean,
  validateFn: Validate<FieldPathValue<TFieldValues, TFieldName>>,
): Validate<FieldPathValue<TFieldValues, TFieldName>> {
  return (value) => {
    if (!conditionFn()) {
      return true;
    }

    return validateFn(value);
  };
}
