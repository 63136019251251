import { NumberInput, NumberInputProps } from '@geeckocom/core-ui';
import get from 'lodash/get';
import { Controller, ControllerProps, FieldValues, Path, useFormContext, useFormState } from 'react-hook-form';

import { FormError } from '@/shared/components/form-error';

type Props<
  TFieldValues extends FieldValues = FieldValues,
  TName extends Path<TFieldValues> = Path<TFieldValues>,
> = NumberInputProps &
  Omit<ControllerProps<TFieldValues>, 'render'> & {
    name_numeric: TName;
  };

export function FormNumberInput<TFieldValues extends FieldValues = FieldValues>(
  props: Props<TFieldValues>,
): JSX.Element {
  const { name, name_numeric, defaultValue, rules, shouldUnregister, control, ...rest } = props;
  const { errors } = useFormState<TFieldValues>({ control });
  const errorMessage = get(errors, `${name}.message`);

  const form = useFormContext<TFieldValues>();

  return (
    <>
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        rules={rules}
        shouldUnregister={shouldUnregister}
        render={({ field }) => (
          <NumberInput
            {...rest}
            {...field}
            color={errorMessage ? 'danger' : 'general'}
            onValueChange={(values) => {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              form.setValue(name_numeric, values.floatValue);
            }}
          />
        )}
      />
      {errorMessage ? <FormError>{errorMessage}</FormError> : null}
    </>
  );
}
