import { FC } from 'react';

interface Props {
  size?: number;
}

export const GitLabIcon: FC<Props> = ({ size = 36 }) => (
  <svg width={size} height={size} fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="m11.5 20 3.1302-9.8429H8.3697L11.5 20H11.5Z" fill="#E24329" />
    <path d="M11.5 19.9999 8.3697 10.157H3.983L11.5 19.9999Z" fill="#FC6D26" />
    <path
      d="m3.983 10.157-.9513 2.9913a.6758.6758 0 0 0 0 .4092.6613.6613 0 0 0 .2354.331L11.5 20l-7.5171-9.8429v-.0001Z"
      fill="#FCA326"
    />
    <path d="M3.983 10.1571h4.3867L6.4845 4.2288c-.097-.305-.5194-.305-.6163 0L3.9829 10.157Z" fill="#E24329" />
    <path d="m11.5 19.9999 3.1302-9.8429h4.3869l-7.5172 9.8429H11.5Z" fill="#FC6D26" />
    <path
      d="m19.0171 10.157.9512 2.9913a.6756.6756 0 0 1 0 .4092.6607.6607 0 0 1-.2355.331L11.5 20l7.5171-9.8429v-.0001Z"
      fill="#FCA326"
    />
    <path d="M19.0171 10.1571h-4.3869l1.8853-5.9283c.097-.305.5193-.305.6163 0l1.8853 5.9283Z" fill="#E24329" />
  </svg>
);
