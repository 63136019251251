import { useCallback, useMemo, useReducer } from 'react';
import { useQuery, UseQueryResult } from 'react-query';

import {
  calculateContributionsFormFillRate,
  calculateEducationFormFillRate,
  calculateExperienceFormFillRate,
  calculateGeneralFormFillRate,
  calculatePersonalDataFormFillRate,
  calculatePreferencesFormFillRate,
  calculateTechnologyStackFormFillRate,
  calculateVideoIntroductionFormFillRate,
  ResumeBuilderStep,
} from '@/features/resume-builder';
import { calculateTestResultsFillRate } from '@/features/resume-builder/forms/resume-test-results/model';
import { useGeneratedApi } from '@/hooks/useApi';
import { useCurrentUser } from '@/queries/auth/useCurrentUser';
import {
  ApiError,
  PublicResumeResource,
  ResumeContributionResource,
  ResumePositionResource,
  ResumeResource,
  ResumeStateEnum,
  ResumeUniversityResource,
} from '@/shared/api';
import { valueAsString } from '@/shared/lib/valueAsString';

export type ResumeFormResource = ResumeResource & {
  min_salary_string?: string;
  desired_salary_string?: string;
  management_experience_string?: string;
  positions?: Array<ResumePositionResourceWithTmpId>;
  contributions?: Array<ResumeContributionResourceWithTmpId>;
  universities?: Array<ResumeUniversityResourceWithTmpId>;
};

type WithTmpId<T> = T & {
  tmpId?: string;
};

export type ResumePositionResourceWithTmpId = WithTmpId<ResumePositionResource>;

export type ResumeContributionResourceWithTmpId = WithTmpId<ResumeContributionResource>;

export type ResumeUniversityResourceWithTmpId = WithTmpId<ResumeUniversityResource>;

const getDefaultResumeData = (data: Partial<ResumeResource>): ResumeFormResource => ({
  ...data,
  first_name: valueAsString(data.first_name),
  last_name: valueAsString(data.last_name),
  phone: valueAsString(data.phone),
  email: valueAsString(data.email),
  birthday: valueAsString(data.birthday),
  position: valueAsString(data.position),
  about: valueAsString(data.about),
  contacts:
    data.contacts && Array.isArray(data.contacts) && data.contacts.length > 0
      ? data.contacts
      : [{ type: 'github', value: '' }],
  salary_currency: data.salary_currency || 'USD',
  salary_period: data.salary_period || 'monthly',
  min_salary: data.min_salary,
  min_salary_string: valueAsString(data.min_salary?.toString()),
  desired_salary: data.desired_salary,
  desired_salary_string: valueAsString(data.desired_salary?.toString()),
  management_experience_string: valueAsString(data.management_experience?.toString()),
  work_environment: data.work_environment && Array.isArray(data.work_environment) ? data.work_environment : [],
  relocation_cities: data.relocation_cities && Array.isArray(data.relocation_cities) ? data.relocation_cities : [],
  relocation_countries:
    data.relocation_countries && Array.isArray(data.relocation_countries) ? data.relocation_countries : [],
  specialization_areas:
    data.specialization_areas && Array.isArray(data.specialization_areas) ? data.specialization_areas : [],
  specializations: data.specializations && Array.isArray(data.specializations) ? data.specializations : [],
  languages_knowledge:
    data.languages_knowledge && Array.isArray(data.languages_knowledge) ? data.languages_knowledge : [],
  technologies: data.technologies && Array.isArray(data.technologies) ? data.technologies : [],
  databases: data.databases && Array.isArray(data.databases) ? data.databases : [],
  positions: data.positions && Array.isArray(data.positions) ? data.positions : [],
  preferable_features:
    data.preferable_features && Array.isArray(data.preferable_features) ? data.preferable_features : [],
  contributions: data.contributions && Array.isArray(data.contributions) ? data.contributions : [],
  universities: data.universities && Array.isArray(data.universities) ? data.universities : [],
});

export const useGetMyResume = (): UseQueryResult<ResumeFormResource> => {
  const api = useGeneratedApi();
  return useQuery(['my-resume'], async () => {
    try {
      const response = await api.resume.getApiResume();
      if (!response.data) {
        throw new TypeError('No data');
      }
      return getDefaultResumeData(response.data);
    } catch (e: unknown) {
      if (e instanceof ApiError) {
        if (e.status === 404) {
          return getDefaultResumeData({});
        }
      }
      throw new TypeError('Unknown error');
    }
  });
};

export const useGetPublicResume = (
  resumeHash?: string | null,
  signature?: string | null,
): UseQueryResult<PublicResumeResource> => {
  const api = useGeneratedApi();

  return useQuery(
    ['public-resume', resumeHash, signature],
    async () => {
      const response = await api.resume.getApiUsersLookupResume(resumeHash as string, signature as string);
      return response.data;
    },
    { enabled: !!resumeHash },
  );
};

export const MY_PUBLIC_RESUME_QUERY_KEY = ['my-public-resume'];

export const useGetMyPublicResume = (): UseQueryResult<PublicResumeResource> => {
  const api = useGeneratedApi();

  const currentUser = useCurrentUser();

  return useQuery(
    MY_PUBLIC_RESUME_QUERY_KEY,
    async () => {
      if (!currentUser.data?.hash) {
        throw new TypeError('No user');
      }
      const response = await api.resume.getApiUsersLookupResume(currentUser.data.hash);
      return response.data;
    },
    {
      enabled: !!currentUser.data && !!currentUser.data.hash,
    },
  );
};

export const STEPS_ORDER: ResumeBuilderStep[] = [
  'import',
  'personal',
  'general',
  'technology-stack',
  'experience',
  'contribution',
  'education',
  'preferences',
  'test-results',
  'video-introduction',
];

export const useComputeCurrentStep = (
  steps: ResumeBuilderStep[],
  stateFromCv?: ResumeStateEnum,
  stateFromUrl?: ResumeBuilderStep,
): ResumeBuilderStep =>
  useMemo(() => {
    if (stateFromCv === 'completed') {
      return stateFromUrl || 'personal';
    }

    // Check can we go to state from url
    if (stateFromUrl) {
      const stateFromUrlIndex = steps.indexOf(stateFromUrl);
      const stateFromCvIndex = steps.indexOf(stateFromCv || 'personal');
      if (stateFromUrlIndex <= stateFromCvIndex) {
        return stateFromUrl;
      }
    }

    return stateFromCv || 'personal';
  }, [stateFromCv, stateFromUrl]);

// TODO: Check what to do with this omiting
export const getEmptyContribution = (): Omit<ResumeContributionResourceWithTmpId, 'id'> => ({
  title: '',
  type: 'code',
  favorite: false,
  url: '',
});

type ResumeBuilderStepsFillRate = Record<ResumeBuilderStep, number>;
type UseResumeBuilderStepsFillRateResult = {
  fillRate: Partial<ResumeBuilderStepsFillRate>;
  setFillRate: (resume: ResumeFormResource) => void;
  updateStepFillRate: (step: ResumeBuilderStep, resume: ResumeFormResource) => void;
};
type UseResumeBuilderStepsFillRateState = {
  fillRate: Partial<ResumeBuilderStepsFillRate>;
};
type UseResumeBuilderStepsFillRateAction =
  | { type: 'setData'; resume: ResumeFormResource }
  | { type: 'setStepData'; step: ResumeBuilderStep; resume: ResumeFormResource };

const fillRateReducer = (
  state: UseResumeBuilderStepsFillRateState,
  action: UseResumeBuilderStepsFillRateAction,
): UseResumeBuilderStepsFillRateState => {
  switch (action.type) {
    case 'setData':
      return {
        ...state,
        fillRate: {
          personal: calculatePersonalDataFormFillRate(action.resume),
          general: calculateGeneralFormFillRate(action.resume),
          'technology-stack': calculateTechnologyStackFormFillRate(action.resume),
          experience: calculateExperienceFormFillRate(action.resume),
          contribution: calculateContributionsFormFillRate(action.resume),
          preferences: calculatePreferencesFormFillRate(action.resume),
          education: calculateEducationFormFillRate(action.resume),
          'test-results': calculateTestResultsFillRate(action.resume),
          'video-introduction': calculateVideoIntroductionFormFillRate(action.resume),
        },
      };
    case 'setStepData':
      switch (action.step) {
        case 'personal':
          const personalFillRate = calculatePersonalDataFormFillRate(action.resume);

          return personalFillRate === state.fillRate.personal
            ? state
            : {
                ...state,
                fillRate: {
                  ...state.fillRate,
                  personal: personalFillRate,
                },
              };
        case 'general':
          const generalFillRate = calculateGeneralFormFillRate(action.resume);

          return generalFillRate === state.fillRate.general
            ? state
            : {
                ...state,
                fillRate: {
                  ...state.fillRate,
                  general: generalFillRate,
                },
              };
        case 'technology-stack':
          const technologyStackFillRate = calculateTechnologyStackFormFillRate(action.resume);
          return state.fillRate['technology-stack'] === technologyStackFillRate
            ? state
            : {
                ...state,
                fillRate: {
                  ...state.fillRate,
                  'technology-stack': technologyStackFillRate,
                },
              };
        case 'experience':
          const experienceFillRate = calculateExperienceFormFillRate(action.resume);

          return experienceFillRate === state.fillRate.experience
            ? state
            : {
                ...state,
                fillRate: {
                  ...state.fillRate,
                  experience: experienceFillRate,
                },
              };
        case 'contribution':
          const contributionFillRate = calculateContributionsFormFillRate(action.resume);

          return contributionFillRate === state.fillRate.contribution
            ? state
            : {
                ...state,
                fillRate: {
                  ...state.fillRate,
                  contribution: contributionFillRate,
                },
              };
        case 'education':
          const educationFillRate = calculateEducationFormFillRate(action.resume);

          return educationFillRate === state.fillRate.education
            ? state
            : {
                ...state,
                fillRate: {
                  ...state.fillRate,
                  education: educationFillRate,
                },
              };
        case 'preferences':
          const preferencesFillRate = calculatePreferencesFormFillRate(action.resume);

          return preferencesFillRate === state.fillRate.preferences
            ? state
            : {
                ...state,
                fillRate: {
                  ...state.fillRate,
                  preferences: preferencesFillRate,
                },
              };
        case 'video-introduction':
          const videoIntroductionFillRate = calculateVideoIntroductionFormFillRate(action.resume);

          return videoIntroductionFillRate === state.fillRate['video-introduction']
            ? state
            : {
                ...state,
                fillRate: {
                  ...state.fillRate,
                  'video-introduction': videoIntroductionFillRate,
                },
              };
        default:
          return state;
      }
    default:
      return state;
  }
};

export const useResumeBuilderStepsFillRate = (): UseResumeBuilderStepsFillRateResult => {
  const [state, dispatch] = useReducer(fillRateReducer, { fillRate: {} });

  const setFillRate = useCallback((resume: ResumeFormResource) => {
    dispatch({ type: 'setData', resume });
  }, []);

  const updateStepFillRate = useCallback((step: ResumeBuilderStep, resume: ResumeFormResource) => {
    dispatch({ type: 'setStepData', step, resume });
  }, []);

  return { fillRate: state.fillRate, setFillRate, updateStepFillRate };
};
