import { FC } from 'react';

interface Props {
  size?: number;
}

export const StackOverflowIcon: FC<Props> = ({ size = 36 }) => (
  <svg width={size} height={size} fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M15.9905 18.5774V14.291h1.3693V20H5v-5.709h1.3694v4.2864h9.6211Z" fill="#BCBBBB" />
    <path
      d="m7.881 13.8845 6.7223 1.4596.2845-1.4042-6.7222-1.4596-.2845 1.4042H7.881Zm.8892-3.3256 6.2244 3.0115.5691-1.2933-6.2244-3.03-.5691 1.3118Zm1.725-3.1778 5.2819 4.5635.8713-1.0901-5.2818-4.5635-.8714 1.09ZM13.9098 4l-1.1027.8499 4.0903 5.7274L18 9.7275 13.9098 4ZM7.7386 17.1362h6.8647v-1.4226H7.7387v1.4226Z"
      fill="#F48023"
    />
  </svg>
);
