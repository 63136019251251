import { confirmDialog, ToggleGroupOption } from '@geeckocom/core-ui';
import { XIcon } from '@heroicons/react/solid';
import { useTranslations } from 'next-intl';
import { FC, useCallback, useMemo } from 'react';
import { UseFormReturn } from 'react-hook-form/dist/types';

import { ResumeFormResource } from '@/features/resume-builder';
import { useUtilityApi } from '@/hooks/useApi';
import { ToolResource } from '@/shared/api-utility';
import { FormField } from '@/shared/components/form-field';
import { FormInput } from '@/shared/components/form-input';
import { FormMultiSelect } from '@/shared/components/form-multi-select';
import { FormTextarea } from '@/shared/components/form-textarea';
import { FormToggleGroup } from '@/shared/components/form-toggle-group';
import { GhostButton } from '@/shared/components/ghost-button';
import { FormPartialDate } from '@/shared/data-components/form-partial-date';
import { getToolSelectOption } from '@/shared/data-components/tools-select';
import { CodeIcon } from '@/shared/icons/CodeIcon';
import { JournalIcon } from '@/shared/icons/JournalIcon';
import { TalkIcon } from '@/shared/icons/TalkIcon';

interface Props {
  formMethods: UseFormReturn<ResumeFormResource>;
  index: number;
  onRemove: () => void;
}

export const ResumeContributionFormItem: FC<Props> = ({ index, formMethods, onRemove }) => {
  const t = useTranslations();

  const utilityApi = useUtilityApi();

  const loadTools = useCallback(
    async (query: string) => {
      const response = await utilityApi.dictionaries.getApiDictionariesTools(null, null, null, query);

      return response.data || [];
    },
    [utilityApi],
  );

  const {
    control,
    watch,
    getValues,
    trigger,
    formState: { isSubmitted },
  } = formMethods;

  const type = watch(`contributions.${index}.type`);
  const namePlaceholder = useMemo(() => {
    if (type === 'code') {
      return t('features.resume_builder.resume_contributions_form.contribution_type.code.name_placeholder');
    }
    if (type === 'article') {
      return t('features.resume_builder.resume_contributions_form.contribution_type.article.name_placeholder');
    }
    if (type === 'talk') {
      return t('features.resume_builder.resume_contributions_form.contribution_type.talk.name_placeholder');
    }

    return '';
  }, [type, t]);

  const descriptionPlaceholder = useMemo(() => {
    if (type === 'code') {
      return t('features.resume_builder.resume_contributions_form.contribution_type.code.description_placeholder');
    }
    if (type === 'article') {
      return t('features.resume_builder.resume_contributions_form.contribution_type.article.description_placeholder');
    }
    if (type === 'talk') {
      return t('features.resume_builder.resume_contributions_form.contribution_type.talk.description_placeholder');
    }

    return '';
  }, [type, t]);

  const contributionTypeOptions: ToggleGroupOption[] = useMemo(
    () => [
      {
        value: 'code',
        label: (
          <div className="flex items-center space-x-2">
            <CodeIcon />
            <span>{t('features.resume_builder.resume_contributions_form.contribution_type.code.title')}</span>
          </div>
        ),
      },
      {
        value: 'article',
        label: (
          <div className="flex items-center space-x-2">
            <JournalIcon />
            <span>{t('features.resume_builder.resume_contributions_form.contribution_type.article.title')}</span>
          </div>
        ),
      },
      {
        value: 'talk',
        label: (
          <div className="flex items-center space-x-2">
            <TalkIcon />
            <span>{t('features.resume_builder.resume_contributions_form.contribution_type.talk.title')}</span>
          </div>
        ),
      },
    ],
    [t],
  );

  return (
    <>
      {index > 0 ? <div className="h-[1px] bg-neutral-lines-one mt-10 mb-14" /> : null}
      <div className="relative">
        <div className="mb-8 sm:absolute sm:right-0 sm:top-0 sm:mb-0">
          <GhostButton
            onClick={() => {
              confirmDialog({
                message: t('features.resume_builder.resume_contributions_form.contribution_remove.message'),
                title: t('features.resume_builder.resume_contributions_form.contribution_remove.title'),
                confirmButton: t('features.resume_builder.resume_contributions_form.contribution_remove.delete_button'),
                cancelButton: t('features.resume_builder.resume_contributions_form.contribution_remove.cancel_button'),
                onConfirm: async () => {
                  onRemove();
                },
              });
            }}
            aria-label={t('features.resume_builder.resume_contributions_form.contribution_remove_button')}
          >
            <XIcon className="h-5 w-5" />
          </GhostButton>
        </div>

        <FormToggleGroup
          control={control}
          name={`contributions.${index}.type` as `contributions.0.type`}
          options={contributionTypeOptions}
          rules={{ required: t('features.resume_builder.validation.required_field') }}
        />
      </div>
      <div className="row gy-5 mt-8">
        <div className="col-24 md:col-12">
          <FormField
            label={t('features.resume_builder.resume_contributions_form.name_label')}
            htmlFor={`resume-builder--${index}--contribution-title`}
            required
          >
            <FormInput
              id={`resume-builder--${index}--contribution-title`}
              name={`contributions.${index}.title` as `contributions.0.title`}
              control={control}
              placeholder={namePlaceholder}
              rules={{ required: t('features.resume_builder.validation.required_field') }}
            />
          </FormField>
        </div>
        <div className="col-24 md:col-12">
          <FormField
            label={t('features.resume_builder.resume_contributions_form.url_label')}
            htmlFor={`resume-builder--${index}--contribution-url`}
            required
          >
            <FormInput
              id={`resume-builder--${index}--contribution-url`}
              type="url"
              name={`contributions.${index}.url` as `contributions.0.url`}
              control={control}
              placeholder="https://"
              rules={{ required: t('features.resume_builder.validation.required_field') }}
            />
          </FormField>
        </div>
      </div>
      <div className="row gy-5 mt-8">
        <div className="col-24 md:col-14">
          <FormField
            label={t('features.resume_builder.resume_contributions_form.technologies_label')}
            htmlFor={`resume-builder--${index}--contribution-tools`}
          >
            <FormMultiSelect<ToolResource, ResumeFormResource>
              id={`resume-builder--${index}--contribution-tools`}
              name={`contributions.${index}.tools` as `contributions.0.tools`}
              async
              loadOptions={loadTools}
              getValue={(tool) => tool.slug!}
              getLabel={(tool) => tool.name!}
              renderValue={getToolSelectOption}
              renderOption={getToolSelectOption}
              loadOnInit
              placeholder={t('features.resume_builder.resume_contributions_form.technologies_placeholder')}
              control={control}
            />
          </FormField>
        </div>
        <div className="col-24 md:col-10">
          <FormField label={t('features.resume_builder.resume_contributions_form.date_label')}>
            <FormPartialDate
              control={control}
              watch={watch}
              trigger={trigger}
              getValues={getValues}
              isSubmitted={isSubmitted}
              monthFieldName={`contributions.${index}.month` as `contributions.0.month`}
              yearFieldName={`contributions.${index}.year` as `contributions.0.year`}
              withoutPast
              nullable
            />
          </FormField>
        </div>
      </div>
      <div className="row gy-5 mt-8">
        <div className="col-24">
          <FormField
            label={t('features.resume_builder.resume_contributions_form.description_label')}
            htmlFor={`resume-builder--${index}--contribution-description`}
          >
            <FormTextarea
              id={`resume-builder--${index}--contribution-description`}
              name={`contributions.${index}.description` as `contributions.0.description`}
              control={control}
              placeholder={descriptionPlaceholder}
            />
          </FormField>
        </div>
      </div>
    </>
  );
};
