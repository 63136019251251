import { Checkbox, CheckboxProps } from '@geeckocom/core-ui';
import get from 'lodash/get';
import { ReactNode } from 'react';
import { Controller, ControllerProps, FieldValues, useFormState } from 'react-hook-form';

import { FormError } from '@/shared/components/form-error';

type Props<TFieldValues extends FieldValues = FieldValues> = CheckboxProps &
  Omit<ControllerProps<TFieldValues>, 'render'> & {
    label?: ReactNode;
  };

export function FormCheckbox<TFieldValues extends FieldValues = FieldValues>(props: Props<TFieldValues>): JSX.Element {
  const { name, defaultValue, rules, shouldUnregister, control, label, ...rest } = props;
  const { errors } = useFormState<TFieldValues>({ control });
  const errorMessage = get(errors, `${name}.message`);

  return (
    <>
      <label className="flex cursor-pointer text-text-primary">
        <div>
          <Controller
            name={name}
            control={control}
            defaultValue={defaultValue}
            rules={rules}
            shouldUnregister={shouldUnregister}
            aria-label={label}
            render={({ field }) => <Checkbox checked={!!field.value} {...rest} {...field} />}
          />
        </div>
        {label ? <div className="ml-2 -mt-1">{label}</div> : null}
      </label>
      {errorMessage ? <FormError>{errorMessage}</FormError> : null}
    </>
  );
}
