import { useQuery } from 'react-query';
import { UseQueryResult } from 'react-query/types/react/types';

import { useUtilityApi } from '@/hooks/useApi';
import { Language } from '@/shared/api-utility';

export const useFetchLanguages = (): UseQueryResult<Required<Language>[]> => {
  const api = useUtilityApi();

  return useQuery(['languages'], async () => {
    const response = await api.dictionaries.getApiDictionariesLanguages();
    return response.data || [];
  });
};
