import { useTranslations } from 'next-intl';
import { FC } from 'react';

import { useRemovePhoto, useUploadPhoto } from '@/entities/resume/resume-photo-uploader/model';
import { PhotoUploader } from '@/shared/components/photo-uploader';
import { processNetworkError } from '@/utils/processNetworkError';
import { showErrorToast, showSuccessToast } from '@/utils/showToast';

interface Props {
  userPhoto?: string | null;
  onChange: (imageURL: string) => void;
}

export const ResumePhotoUploader: FC<Props> = ({ userPhoto, onChange }) => {
  const t = useTranslations();
  const uploadPhoto = useUploadPhoto();
  const removePhoto = useRemovePhoto();

  const handleSelectFiles = async (acceptedFiles: File[]) => {
    if (acceptedFiles.length) {
      try {
        const response = await uploadPhoto.mutateAsync(acceptedFiles[0]);
        if (response.data?.photo_url) {
          onChange(response.data.photo_url);
        }
        showSuccessToast(t('shared.components.photo_uploader.toast_uploaded'));
      } catch (e) {
        showErrorToast(processNetworkError(e, t));
      }
    } else {
      onChange('');
      removePhoto.mutate(undefined);
    }
  };

  const handleRemovePhoto = async () => {
    await removePhoto.mutateAsync(undefined).then(() => {
      onChange('');
    });
  };

  return (
    <PhotoUploader
      id="resume-builder--photo"
      value={userPhoto}
      onRemoveConfirm={handleRemovePhoto}
      handleSelectFiles={handleSelectFiles}
      isLoading={removePhoto.isLoading || uploadPhoto.isLoading}
    />
  );
};
