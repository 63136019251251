import { CurrencyEnum } from '@/shared/api';

const CURRENCY_SIGNS: Record<CurrencyEnum, string> = {
  RUB: '₽',
  USD: '$',
  EUR: '€',
};

export const getCurrencySign = (currency: CurrencyEnum | undefined): string | undefined =>
  currency ? CURRENCY_SIGNS[currency] : undefined;
