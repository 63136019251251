import { ResumePositionResource } from '@/shared/api';
import { valueAsString } from '@/shared/lib/valueAsString';

export const getResumePosition = (position: Partial<ResumePositionResource>): ResumePositionResource => ({
  ...position,
  current: !!position.current,
  position: valueAsString(position.position),
  company: valueAsString(position.company),
  website: valueAsString(position.website),
  description: valueAsString(position.description),
  specialization_areas:
    position.specialization_areas && Array.isArray(position.specialization_areas) ? position.specialization_areas : [],
  specializations: position.specializations && Array.isArray(position.specializations) ? position.specializations : [],
  programming_languages:
    position.programming_languages && Array.isArray(position.programming_languages)
      ? position.programming_languages
      : [],
  technologies: position.technologies && Array.isArray(position.technologies) ? position.technologies : [],
  databases: position.databases && Array.isArray(position.databases) ? position.databases : [],
  projects: position.projects && Array.isArray(position.projects) ? position.projects : [],
});
