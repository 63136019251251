import { useTranslations } from 'next-intl';
import { ReactNode, useEffect, useState } from 'react';
import { FieldPath, FieldValues } from 'react-hook-form';
import { Control, UseFormGetValues, UseFormTrigger, UseFormWatch } from 'react-hook-form/dist/types/form';

import { FormCheckbox } from '@/shared/components/form-checkbox';
import { FormInput } from '@/shared/components/form-input';
import { FormMonthSelect } from '@/shared/data-components/month-select';
import { combineValidators } from '@/shared/lib/form-validators/combine-validators';
import { conditionalValidators } from '@/shared/lib/form-validators/conditional-validators';
import { isYearValid } from '@/shared/lib/form-validators/is-year-valid';
import { minYear } from '@/shared/lib/form-validators/min-year';
import { partialDateIsNotBefore } from '@/shared/lib/form-validators/partial-date-is-not-before';
import { partialDateIsNotInFuture } from '@/shared/lib/form-validators/partial-date-is-not-in-future';
import { required } from '@/shared/lib/form-validators/required';

interface Props<TFieldName extends FieldPath<TFieldValues>, TFieldValues extends FieldValues = FieldValues> {
  testId?: string;
  control: Control<TFieldValues>;
  watch: UseFormWatch<TFieldValues>;
  getValues: UseFormGetValues<TFieldValues>;
  trigger: UseFormTrigger<TFieldValues>;
  monthFieldName: TFieldName;
  monthId?: string;
  yearFieldName: TFieldName;
  yearId?: string;
  isSubmitted: boolean;
  currentFieldName?: TFieldName;
  currentLabel?: ReactNode;
  withoutPast?: boolean;
  nullable?: boolean;
  previousMonthFieldName?: TFieldName;
  previousYearFieldName?: TFieldName;
}

export const FormPartialDate = <
  TFieldName extends FieldPath<TFieldValues>,
  TFieldValues extends FieldValues = FieldValues,
>({
  testId,
  control,
  watch,
  trigger,
  getValues,
  monthFieldName,
  monthId,
  yearFieldName,
  yearId,
  currentFieldName,
  currentLabel,
  withoutPast,
  nullable,
  isSubmitted,
  previousMonthFieldName,
  previousYearFieldName,
}: Props<TFieldName, TFieldValues>) => {
  const t = useTranslations();

  const monthValue = watch(monthFieldName);
  const yearValue = watch(yearFieldName);
  const previousMonthValue = previousMonthFieldName ? watch(previousMonthFieldName) : null;
  const previousYearValue = previousYearFieldName ? watch(previousYearFieldName) : null;
  const currentValue = currentFieldName ? watch(currentFieldName) : false;

  useEffect(() => {
    if (isSubmitted) {
      trigger([monthFieldName, yearFieldName]);
    }
  }, [isSubmitted, monthValue, yearValue, previousMonthValue, previousYearValue, currentValue]);

  const isCurrent = currentFieldName ? watch(currentFieldName) : false;

  const [isYearValueValid, setIsYearValueValid] = useState<false | null>(null);

  const handleChangeYearValid = (isValid) => {
    setIsYearValueValid(isValid ? null : false);
  };

  return (
    <div data-testid={testId}>
      <div className="flex space-x-5">
        <div className="flex-grow w-24 text-text-primary" data-testid={monthId ? `${monthId}-wrapper` : undefined}>
          {monthId ? (
            <label htmlFor={monthId} className="uppercase text-xs text-text-secondary">
              {t('shared.data_components.form_partial_date.month_label')}
            </label>
          ) : null}
          <FormMonthSelect
            id={monthId}
            name={monthFieldName}
            control={control}
            placeholder={t('shared.data_components.form_partial_date.month_placeholder')}
            disabled={!!isCurrent}
            deletable={nullable}
            rules={{
              validate: conditionalValidators(() => {
                if (nullable) {
                  return !!getValues(yearFieldName);
                }
                return currentFieldName ? !getValues(currentFieldName) : true;
              }, combineValidators<TFieldValues>(!nullable ? required(t) : null, withoutPast ? partialDateIsNotInFuture<TFieldName, TFieldValues>(getValues, monthFieldName, yearFieldName, t, handleChangeYearValid, nullable) : null, previousMonthFieldName && previousYearFieldName ? partialDateIsNotBefore(getValues, previousMonthFieldName, previousYearFieldName, monthFieldName, yearFieldName, t, handleChangeYearValid) : null)),
              deps: [monthFieldName, yearFieldName],
            }}
          />
        </div>
        <div className="w-24 shrink-0" data-testid={yearId ? `${yearId}-wrapper` : undefined}>
          {yearId ? (
            <label className="uppercase text-xs text-text-secondary" htmlFor={yearId}>
              {t('shared.data_components.form_partial_date.year_label')}
            </label>
          ) : null}
          <FormInput
            id={yearId}
            name={yearFieldName}
            control={control}
            placeholder="2022"
            disabled={!!isCurrent}
            color={isYearValueValid === false ? 'danger' : undefined}
            rules={{
              validate: conditionalValidators(() => {
                if (nullable) {
                  return !!getValues(monthFieldName);
                }
                return currentFieldName ? !getValues(currentFieldName) : true;
              }, combineValidators(required(t), isYearValid(t), minYear(1970, t))),
            }}
          />
        </div>
      </div>
      {currentFieldName ? (
        <div className="mt-2">
          <FormCheckbox name={currentFieldName} control={control} label={currentLabel} />
        </div>
      ) : null}
    </div>
  );
};
