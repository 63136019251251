import { ChipsGroup, ChipsGroupProps } from '@geeckocom/core-ui';
import clsx from 'clsx';
import get from 'lodash/get';
import { Controller, ControllerProps, FieldValues, useFormState } from 'react-hook-form';

import { FormError } from '@/shared/components/form-error';

type Props<TFieldValues extends FieldValues = FieldValues> = ChipsGroupProps &
  Omit<ControllerProps<TFieldValues>, 'render'>;

export function FormChipsGroup<TFieldValues extends FieldValues = FieldValues>(
  props: Props<TFieldValues>,
): JSX.Element {
  const { name, defaultValue, rules, shouldUnregister, control, ...rest } = props;
  const { errors } = useFormState<TFieldValues>({ control });
  const errorMessage = get(errors, `${name}.message`);

  return (
    <>
      <div className={clsx('-m-2 rounded', !!errorMessage && 'bg-red-50')}>
        <Controller
          name={name}
          control={control}
          defaultValue={defaultValue}
          rules={rules}
          shouldUnregister={shouldUnregister}
          render={({ field }) => (
            <div className="p-2">
              <ChipsGroup {...rest} {...field} />
            </div>
          )}
        />
      </div>
      {errorMessage ? <FormError>{errorMessage}</FormError> : null}
    </>
  );
}
