import { ResumeFormResource } from '@/features/resume-builder';

export const calculatePersonalDataFormFillRate = (formValues: ResumeFormResource): number => {
  const allFields = 6;
  let filledFields = 0;

  if (formValues.first_name) {
    filledFields += 1;
  }

  if (formValues.last_name) {
    filledFields += 1;
  }

  if (formValues.email) {
    filledFields += 1;
  }

  if (formValues.phone && formValues.phone.length > 5) {
    filledFields += 1;
  }

  if (formValues.birthday) {
    filledFields += 1;
  }

  const filledContact = formValues.contacts ? formValues.contacts.filter((contact) => !!contact.value) : [];
  if (filledContact.length > 0) {
    filledFields += 1;
  }

  return (filledFields / allFields) * 100;
};
