import { useMutation } from 'react-query';
import { UseMutationResult } from 'react-query/types/react/types';

import { useApi, useGeneratedApi } from '@/hooks/useApi';
import { ResumeResource } from '@/shared/api';

export const useUploadPhoto = (): UseMutationResult<{ data?: ResumeResource }, unknown, Blob> => {
  const api = useApi();

  return useMutation(async (photo: Blob) => {
    const formData = new FormData();
    formData.append('photo', photo);

    const response = await api.post('/api/resume/personal/photo', formData);
    return response.data;
  });
};

export const useRemovePhoto = (): UseMutationResult<unknown, void> => {
  const api = useGeneratedApi();

  return useMutation(() => api.resume.deleteApiResumePersonalPhoto());
};
