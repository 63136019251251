import { FC } from 'react';

interface Props {
  size?: number;
}

export const TelegramIcon: FC<Props> = ({ size = 24 }) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12 4a8 8 0 1 0 0 16 8 8 0 0 0 0-16Z" fill="#40B3E0" />
    <path
      d="m15.893 8.602-1.43 7.204s-.2.5-.749.26l-3.297-2.528-1.199-.58L7.2 12.28s-.31-.11-.34-.35c-.03-.24.35-.37.35-.37l8.023-3.147s.66-.29.66.19"
      fill="#fff"
    />
    <path
      d="M10.164 15.725s-.097-.009-.217-.389-.729-2.378-.729-2.378l4.846-3.077s.28-.17.27 0c0 0 .05.03-.1.17-.15.14-3.807 3.427-3.807 3.427"
      fill="#D2E5F1"
    />
    <path d="m11.681 14.507-1.304 1.19s-.102.077-.213.028l.25-2.208" fill="#B5CFE4" />
  </svg>
);
