import { FC } from 'react';

interface Props {
  size?: number;
}

export const TalkIcon: FC<Props> = ({ size = 24 }) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14.6667 10.2228L18.7138 8.1997C18.8493 8.132 18.9998 8.10004 19.1511 8.10686C19.3024 8.11367 19.4495 8.15904 19.5783 8.23864C19.7072 8.31825 19.8136 8.42946 19.8874 8.56172C19.9612 8.69399 19.9999 8.84291 20 8.99437V15.0068C19.9999 15.1583 19.9612 15.3072 19.8874 15.4395C19.8136 15.5717 19.7072 15.6829 19.5783 15.7625C19.4495 15.8422 19.3024 15.8875 19.1511 15.8943C18.9998 15.9011 18.8493 15.8692 18.7138 15.8015L14.6667 13.7784V10.2228Z"
      stroke="currentColor"
      strokeWidth="1.62963"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.8889 6.66699H5.77778C4.79594 6.66699 4 7.46293 4 8.44477V15.5559C4 16.5377 4.79594 17.3337 5.77778 17.3337H12.8889C13.8707 17.3337 14.6667 16.5377 14.6667 15.5559V8.44477C14.6667 7.46293 13.8707 6.66699 12.8889 6.66699Z"
      stroke="currentColor"
      strokeWidth="1.62963"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
