import { Alert, Button } from '@geeckocom/core-ui';
import { PlusCircleIcon } from '@heroicons/react/outline';
import { useTranslations } from 'next-intl';
import { FC, useEffect } from 'react';
import { FormProvider, UnpackNestedValue, useFieldArray, useForm } from 'react-hook-form';

import { ResumeContributionFormItem } from '@/features/resume-builder/forms/resume-contributions-form/ResumeContributionFormItem';
import { ResumeBuilderFormProps } from '@/features/resume-builder/forms/types';
import { getEmptyContribution, ResumeFormResource } from '@/features/resume-builder/model';
import { useGeneratedApi } from '@/hooks/useApi';
import { ApiError, ResumeContribution } from '@/shared/api';
import { Heading } from '@/shared/components/heading';
import { WideButton } from '@/shared/components/wide-button/WideButton';
import { usePreventFormLeaving } from '@/shared/hooks/use-prevent-form-leaving';
import { handleValidationErrors } from '@/shared/lib/handle-validation-errors';
import { PageTitle } from '@/shared/page-title';

export const ResumeContributionsForm: FC<ResumeBuilderFormProps> = ({ resume, onFieldChanged, onSubmitted }) => {
  const t = useTranslations();
  const api = useGeneratedApi();

  const formMethods = useForm<ResumeFormResource>({ defaultValues: resume });
  const {
    control,
    handleSubmit,
    formState: { isSubmitting, isSubmitSuccessful, isDirty },
    setError,
    watch,
  } = formMethods;

  usePreventFormLeaving(isSubmitting, isSubmitSuccessful, isDirty);

  const isResumeCompleted = resume.state === 'completed';

  // Update fields calculation
  const formFields = watch();

  useEffect(() => {
    onFieldChanged('contribution', formFields as ResumeFormResource);
  }, [formFields]);

  // Contributions
  const { fields, append, remove } = useFieldArray<ResumeFormResource, 'contributions', 'tmpId'>({
    control,
    name: 'contributions',
    keyName: 'tmpId',
  });

  const handleFormSubmit = async (data: UnpackNestedValue<ResumeFormResource>) => {
    try {
      const response = await api.resume.patchApiResumeContribution({
        contributions: (data.contributions || []) as Array<ResumeContribution>,
      });

      if (response.data) {
        await onSubmitted(response.data);
      }
    } catch (e) {
      if (e instanceof ApiError) {
        if (e.status === 422) {
          handleValidationErrors(setError, e.body.errors);
        }
      }
    }
  };

  return (
    <FormProvider {...formMethods}>
      <PageTitle title={t('features.resume_builder.steps.contribution.title')} />
      <form onSubmit={handleSubmit(handleFormSubmit)}>
        <div className="container">
          <div className="mb-11">
            <Heading>{t('features.resume_builder.resume_contributions_form.title')}</Heading>
          </div>

          <div className="mb-8">
            <Alert>{t('features.resume_builder.resume_contributions_form.hint_text')}</Alert>
          </div>

          {fields.length > 0 ? (
            fields.map((contribution, contributionIndex) => (
              <ResumeContributionFormItem
                key={contribution.tmpId}
                formMethods={formMethods}
                index={contributionIndex}
                onRemove={() => remove(contributionIndex)}
              />
            ))
          ) : (
            <Alert>{t('features.resume_builder.resume_contributions_form.no_contributions')}</Alert>
          )}

          <div className="mt-8">
            <WideButton
              onClick={() => {
                append(getEmptyContribution());
              }}
            >
              <div className="flex items-center space-x-1">
                <span>
                  <PlusCircleIcon className="w-6 h-6" />
                </span>
                <span>{t('features.resume_builder.resume_contributions_form.add_contribution')}</span>
              </div>
            </WideButton>
          </div>

          <div className="flex space-x-4 mt-6">
            <Button type="submit" isLoading={isSubmitting} size="lg">
              {isResumeCompleted
                ? t('features.resume_builder.submit_button_save')
                : t('features.resume_builder.submit_button_continue')}
            </Button>
          </div>
        </div>
      </form>
    </FormProvider>
  );
};

export { calculateContributionsFormFillRate } from './model';
