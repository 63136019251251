import Markdown from 'markdown-to-jsx';
import { FC, memo } from 'react';
import nl2br from 'react-nl2br';

import styles from './Text.module.scss';

interface Props {
  text?: string | null;
  displayLinks?: boolean;
}

/**
 * Replaces all page breaks in text to <br /> tags
 * @param {string} text
 * @constructor
 */

export const Text: FC<Props> = memo(({ text, displayLinks }) => {
  const regExUrl =
    /(?:(?:https?|ftp|file):\/\/|www\.|ftp\.)(?:\([-A-Z0-9+&@#/%=~_|$?!:,.]*\)|[-A-Z0-9+&@#/%=~_|$?!:,.])*(?:\([-A-Z0-9+&@#/%=~_|$?!:,.]*\)|[A-Z0-9+&@#/%=~_|$])/gim;
  if (typeof text !== 'string' || !text) {
    return null;
  }

  const markdownText = nl2br(text).map((textItem) => {
    if (displayLinks && typeof textItem === 'string' && textItem.match(regExUrl)) {
      return (
        <Markdown className={styles.textItem} key={textItem}>
          {textItem}
        </Markdown>
      );
    }
    return textItem;
  });

  return <>{markdownText}</>;
});

Text.displayName = 'Text';
