import { confirmDialog } from '@geeckocom/core-ui';
import { useTranslations } from 'next-intl';
import { FC, useMemo } from 'react';

import { ResumeExperienceStackInfo } from '@/entities/resume/resume-experience-item/ui/ResumeExperienceStackInfo';
import { ResumePositionResourceWithTmpId } from '@/features/resume-builder/model';
import { GhostButton } from '@/shared/components/ghost-button';
import { Text } from '@/shared/components/text';
import { useGetDates } from '@/shared/hooks/useGetDates';

interface Props {
  position: ResumePositionResourceWithTmpId;
  index: number;
  onEdit?: (position: ResumePositionResourceWithTmpId) => void;
  onRemove?: (index: number) => void;
  missingFields?: string[];
}

const missingFieldsNames: Record<string, string> = {
  specializations_areas: 'entities.resume.resume_experience_item.missing_fields_names.specializations_areas',
  specializations: 'entities.resume.resume_experience_item.missing_fields_names.specializations',
  position: 'entities.resume.resume_experience_item.missing_fields_names.position',
  description: 'entities.resume.resume_experience_item.missing_fields_names.description',
};

export const ResumeExperienceItem: FC<Props> = ({ position, index, missingFields, onEdit, onRemove }) => {
  const t = useTranslations();

  const { dateRangeFormatted, duration } = useGetDates(
    position.from_month,
    position.from_year,
    position.current,
    position.to_month,
    position.to_year,
  );

  const missingFieldsFormatted = useMemo(() => {
    if (!missingFields || !Array.isArray(missingFields) || missingFields.length === 0) {
      return null;
    }

    const fields = missingFields
      .filter((field) => !!missingFieldsNames[field])
      .map((field) => t(missingFieldsNames[field]))
      .join(', ');

    return t('entities.resume.resume_experience_item.error_you_have_missing_fields', { missingFields: fields });
  }, [t, missingFields]);

  return (
    <>
      {index > 0 ? <div className="h-[1px] bg-neutral-lines-one mt-10 mb-14" /> : null}
      <div className="container p-0 break-inside-avoid">
        <div className="row gy-5 print:flex">
          <div className="col-24 md:col-8 print:w-1/3">
            <div className="text-text-primary font-semibold">{duration}</div>
            <div className="text-text-secondary text-xs mt-2">{dateRangeFormatted}</div>

            <div className="flex mt-5 space-x-2">
              {onEdit ? (
                <GhostButton onClick={() => onEdit(position)}>
                  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M7.33334 2.6665H2.66668C2.31305 2.6665 1.97392 2.80698 1.72387 3.05703C1.47382 3.30708 1.33334 3.64622 1.33334 3.99984V13.3332C1.33334 13.6868 1.47382 14.0259 1.72387 14.276C1.97392 14.526 2.31305 14.6665 2.66668 14.6665H12C12.3536 14.6665 12.6928 14.526 12.9428 14.276C13.1929 14.0259 13.3333 13.6868 13.3333 13.3332V8.6665"
                      stroke="var(--text-secondary)"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M12.3333 1.66666C12.5986 1.40144 12.9583 1.25244 13.3333 1.25244C13.7084 1.25244 14.0681 1.40144 14.3333 1.66666C14.5986 1.93187 14.7476 2.29158 14.7476 2.66666C14.7476 3.04173 14.5986 3.40144 14.3333 3.66666L8.00001 9.99999L5.33334 10.6667L6.00001 7.99999L12.3333 1.66666Z"
                      stroke="var(--text-secondary)"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </GhostButton>
              ) : null}

              {onRemove ? (
                <GhostButton
                  onClick={() => {
                    confirmDialog({
                      message: t('entities.resume.resume_experience_item.remove_experience_item.message'),
                      title: t('entities.resume.resume_experience_item.remove_experience_item.title'),
                      confirmButton: t('entities.resume.resume_experience_item.remove_experience_item.delete_button'),
                      cancelButton: t('entities.resume.resume_experience_item.remove_experience_item.cancel_button'),
                      onConfirm: async () => {
                        onRemove(index);
                      },
                    });
                  }}
                  aria-label={t('entities.resume.resume_experience_item.remove_experience_item_button')}
                >
                  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M2 4H3.33333H14"
                      stroke="var(--text-secondary)"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M5.33334 4.00016V2.66683C5.33334 2.31321 5.47382 1.97407 5.72387 1.72402C5.97392 1.47397 6.31305 1.3335 6.66668 1.3335H9.33334C9.68697 1.3335 10.0261 1.47397 10.2762 1.72402C10.5262 1.97407 10.6667 2.31321 10.6667 2.66683V4.00016M12.6667 4.00016V13.3335C12.6667 13.6871 12.5262 14.0263 12.2762 14.2763C12.0261 14.5264 11.687 14.6668 11.3333 14.6668H4.66668C4.31305 14.6668 3.97392 14.5264 3.72387 14.2763C3.47382 14.0263 3.33334 13.6871 3.33334 13.3335V4.00016H12.6667Z"
                      stroke="var(--text-secondary)"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M6.66666 7.3335V11.3335"
                      stroke="var(--text-secondary)"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M9.33334 7.3335V11.3335"
                      stroke="var(--text-secondary)"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </GhostButton>
              ) : null}
            </div>

            {missingFieldsFormatted ? <div className="mt-2 text-negative-accent">{missingFieldsFormatted}</div> : null}
          </div>
          <div className="col-24 md:col-16 print:w-2/3">
            <h2 className="font-semibold text-text-primary text-base flex items-center">
              {position.website_icon_url ? (
                <img src={position.website_icon_url} className="mr-2 max-h-6" alt="" />
              ) : null}
              {position.position} {t('entities.resume.resume_experience_item.at')} {position.company}
            </h2>
            {position.website ? (
              <a
                href={position.website}
                target="_blank"
                className="mt-4 block text-active-accent text-sm break-all"
                rel="noreferrer"
              >
                {position.website?.replace('https://', '')}
              </a>
            ) : null}
            <p className="text-sm text-text-primary mt-4">
              <Text text={position.description} displayLinks />
            </p>
            <ResumeExperienceStackInfo
              specializations={position.specializations || []}
              programming_languages={position.programming_languages || []}
              technologies={position.technologies || []}
              databases={position.databases || []}
            />
          </div>
        </div>
      </div>
    </>
  );
};
