import { Button, Modal } from '@geeckocom/core-ui';
import { useTranslations } from 'next-intl';
import { FC, useEffect, useMemo, useRef } from 'react';
import { FormProvider, UnpackNestedValue, useForm } from 'react-hook-form';

import { SkillSetBuilder } from '@/entities/skill-set-builder';
import { ResumePositionResourceWithTmpId } from '@/features/resume-builder/model';
import { SpecializationAreaEnum } from '@/shared/api-utility';
import { FormField } from '@/shared/components/form-field';
import { FormInput } from '@/shared/components/form-input';
import { FormTextarea } from '@/shared/components/form-textarea';
import { FormPartialDate } from '@/shared/data-components/form-partial-date';

interface Props {
  isOpen: boolean;
  position: ResumePositionResourceWithTmpId;
  skillsAreas?: SpecializationAreaEnum[];
  onDone: (position: ResumePositionResourceWithTmpId) => void;
  onClose: () => void;
}

export const ExperienceFormModal: FC<Props> = ({ position, skillsAreas, isOpen, onDone, onClose }) => {
  const t = useTranslations();
  const actionType = useMemo(() => (position.id || position.tmpId ? 'edit' : 'create'), [position.id]);

  const formMethods = useForm<ResumePositionResourceWithTmpId>({ defaultValues: position });
  const {
    control,
    watch,
    handleSubmit,
    getValues,
    trigger,
    formState: { isSubmitted },
  } = formMethods;

  const isCurrent = watch('current');

  const isFirstRender = useRef(true);

  useEffect(() => {
    if (!isFirstRender.current) {
      trigger(['to_month', 'to_year']);
    }

    isFirstRender.current = false;
  }, [isCurrent]);

  const handleFormSubmit = (values: UnpackNestedValue<ResumePositionResourceWithTmpId>) => {
    onClose();
    onDone(values);
  };

  return (
    <Modal
      title={
        actionType === 'create'
          ? t('features.resume_builder.resume_experience_form.edit_modal.create_title')
          : t('features.resume_builder.resume_experience_form.edit_modal.edit_title')
      }
      width="5xl"
      isOpen={isOpen}
      onClose={onClose}
    >
      <FormProvider {...formMethods}>
        <form onSubmit={handleSubmit(handleFormSubmit)}>
          <div className="row gy-5">
            <div className="col-24 md:col-12">
              <FormField
                label={t('features.resume_builder.resume_experience_form.company_label')}
                htmlFor="resume-builder--position-company"
                required
              >
                <FormInput
                  id="resume-builder--position-company"
                  name="company"
                  control={control}
                  rules={{ required: t('features.resume_builder.validation.required_field') }}
                />
              </FormField>
            </div>
            <div className="col-24 md:col-12">
              <FormField
                label={t('features.resume_builder.resume_experience_form.website_label')}
                htmlFor="resume-builder--position-website"
              >
                <FormInput id="resume-builder--position-website" name="website" control={control} />
              </FormField>
            </div>
          </div>
          <div className="mt-5">
            <FormField label={t('features.resume_builder.resume_experience_form.work_period_label')} required>
              <div className="row gy-5">
                <div className="col-24 lg:col-9 md:col-11 text-text-primary">
                  <FormPartialDate
                    control={control}
                    watch={watch}
                    trigger={trigger}
                    getValues={getValues}
                    isSubmitted={isSubmitted}
                    monthFieldName="from_month"
                    monthId="resume-builder--position-from-month"
                    yearFieldName="from_year"
                    yearId="resume-builder--position-from-year"
                    withoutPast
                  />
                </div>

                <div className="col-auto">
                  <label className="text-xs">&nbsp;</label>
                  <div className="h-10 flex items-center justify-center text-xl text-slate-400">&ndash;</div>
                </div>

                <div className="col-24 lg:col-9 md:col-11">
                  <FormPartialDate
                    control={control}
                    watch={watch}
                    trigger={trigger}
                    getValues={getValues}
                    isSubmitted={isSubmitted}
                    monthFieldName="to_month"
                    monthId="resume-builder--position-to-month"
                    yearFieldName="to_year"
                    yearId="resume-builder--position-to-year"
                    currentFieldName="current"
                    currentLabel={t('features.resume_builder.resume_experience_form.till_present')}
                    withoutPast
                    previousMonthFieldName="from_month"
                    previousYearFieldName="from_year"
                  />
                </div>
              </div>
            </FormField>
          </div>
          <div className="row gy-5 mt-5">
            <div className="col-24">
              <FormField
                label={t('features.resume_builder.resume_experience_form.position_label')}
                htmlFor="resume-builder--position-name"
                required
              >
                <FormInput
                  id="resume-builder--position-name"
                  name="position"
                  control={control}
                  rules={{ required: t('features.resume_builder.validation.required_field') }}
                />
              </FormField>
            </div>
          </div>
          <div className="row gy-5 mt-5">
            <div className="col-24">
              <FormField
                label={t('features.resume_builder.resume_experience_form.description_label')}
                htmlFor="resume-builder--position-desciption"
                required
              >
                <FormTextarea
                  id="resume-builder--position-desciption"
                  name="description"
                  autosize
                  control={control}
                  placeholder={t('features.resume_builder.resume_experience_form.description_placeholder')}
                  rules={{ required: t('features.resume_builder.validation.required_field') }}
                />
              </FormField>
            </div>
          </div>
          <div className="mt-5">
            <SkillSetBuilder limitAreas={skillsAreas} withoutJobSearch />
          </div>

          <div className="flex space-x-4 mt-16 mb-20">
            <Button type="submit" size="lg">
              {actionType === 'create'
                ? t('features.resume_builder.resume_experience_form.edit_modal.create_button')
                : t('features.resume_builder.resume_experience_form.edit_modal.edit_button')}
            </Button>
            <Button type="button" size="lg" variant="outlined" onClick={onClose}>
              {t('features.resume_builder.resume_experience_form.edit_modal.close_button')}
            </Button>
          </div>
        </form>
      </FormProvider>
    </Modal>
  );
};
