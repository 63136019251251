import { FC } from 'react';

interface Props {
  size?: number;
}

export const LinkedInIcon: FC<Props> = ({ size }) => (
  <svg width={size} height={size} fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="6" y="6" width="12" height="12" fill="white" />
    <path
      d="M17.6328 17.633H15.2621V13.9203C15.2621 13.035 15.2463 11.8953 14.0291 11.8953C12.7943 11.8953 12.6054 12.8599 12.6054 13.8558V17.6327H10.2347V9.99798H12.5106V11.0413H12.5424C12.7702 10.6519 13.0993 10.3315 13.4947 10.1144C13.8902 9.89717 14.3371 9.79131 14.7879 9.80804C17.1908 9.80804 17.6338 11.3885 17.6338 13.4447L17.6328 17.633ZM7.55975 8.95441C6.79994 8.95454 6.18387 8.33866 6.18375 7.57885C6.18362 6.81904 6.79944 6.20298 7.55925 6.20285C8.31906 6.20266 8.93512 6.81854 8.93525 7.57835C8.93532 7.94323 8.79044 8.29319 8.53248 8.55125C8.27453 8.80931 7.92463 8.95433 7.55975 8.95441ZM8.74512 17.633H6.37194V9.99798H8.74506V17.633L8.74512 17.633ZM18.8146 4.00116H5.18069C4.53631 3.99391 4.00788 4.5101 4 5.15448V18.8453C4.00763 19.49 4.536 20.0067 5.18062 19.9999H18.8146C19.4606 20.0079 19.9911 19.4912 20 18.8453V5.15341C19.9908 4.50779 19.4603 3.99166 18.8146 4.0001"
      fill="#0A66C2"
    />
  </svg>
);
