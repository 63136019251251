import { FieldPathValue, FieldValues, Path, Validate } from 'react-hook-form';

export function combineValidators<TFieldValues extends FieldValues = FieldValues>(
  ...validators: Array<Validate<FieldPathValue<TFieldValues, Path<TFieldValues>>> | false | null | undefined>
): Validate<FieldPathValue<TFieldValues, Path<TFieldValues>>> {
  return async (value: FieldPathValue<TFieldValues, Path<TFieldValues>>) => {
    for (let i = 0; i < validators.length; i += 1) {
      const validator = validators[i];
      if (typeof validator === 'function') {
        let result = validator(value);
        if (result instanceof Promise) {
          // eslint-disable-next-line no-await-in-loop
          result = await result;
        }
        if (result !== true) {
          return result;
        }
      }
    }
    return true;
  };
}
