import { FC } from 'react';

export const NoTargetIcon: FC = () => (
  <svg width="14.5" height="14.5" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.0001 18.3334C14.6025 18.3334 18.3334 14.6024 18.3334 10C18.3334 5.39765 14.6025 1.66669 10.0001 1.66669C5.39771 1.66669 1.66675 5.39765 1.66675 10C1.66675 14.6024 5.39771 18.3334 10.0001 18.3334Z"
      stroke="var(--text-secondary)"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10 15C12.7614 15 15 12.7614 15 10C15 7.23858 12.7614 5 10 5C7.23858 5 5 7.23858 5 10C5 12.7614 7.23858 15 10 15Z"
      stroke="var(--text-secondary)"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.0001 12.2916C8.73443 12.2916 7.70842 11.2656 7.70842 9.99998C7.70842 8.73433 8.73443 7.70831 10.0001 7.70831C11.2657 7.70831 12.2917 8.73433 12.2917 9.99998C12.2917 11.2656 11.2657 12.2916 10.0001 12.2916Z"
      fill="var(--text-secondary)"
    />
    <line x1="18.4697" y1="19.5303" x2="0.46967" y2="1.53033" stroke="var(--text-secondary)" strokeWidth="1.5" />
    <line x1="19.4697" y1="18.5303" x2="1.46967" y2="0.53033" stroke="var(--surface-three)" strokeWidth="1.5" />
  </svg>
);
