import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { UseQueryResult } from 'react-query/types/react/types';

import { useUtilityApi } from '@/hooks/useApi';
import { ResumeTechnologyStackTool } from '@/shared/api';
import { SpecializationAreaMap, ToolResource, ToolTypeEnum } from '@/shared/api-utility';

export const useFetchAreasAndSpecializations = (): UseQueryResult<SpecializationAreaMap[]> => {
  const utilityApi = useUtilityApi();

  return useQuery(['areas-and-specializations'], async () => {
    const response = await utilityApi.dictionaries.getApiDictionariesSpecializationsMap();
    if (!response.data) {
      throw new Error('No data');
    }
    return response.data;
  });
};

export const useFetchTools = (
  type: ToolTypeEnum,
  specializations?: ResumeTechnologyStackTool[],
  languages?: ResumeTechnologyStackTool[],
): UseQueryResult<ToolResource[]> => {
  const utilityApi = useUtilityApi();

  const specializationsArray = specializations || [];
  const specializationsString = useMemo(() => {
    if (!specializations) {
      return '';
    }
    return specializations.map((s) => s.slug).join(',');
  }, [specializations]);

  const languagesArray = languages || [];
  const languagesString = useMemo(() => {
    if (!languages) {
      return '';
    }
    return languages.map((s) => s.slug).join(',');
  }, [languages]);

  return useQuery(
    ['tools', type, specializationsString, languagesString],
    async () => {
      const response = await utilityApi.dictionaries.getApiDictionariesTools(
        type,
        specializationsArray.map((s) => s.slug),
        languagesArray.map((s) => s.id),
      );
      if (!response.data) {
        throw new Error('No data');
      }
      return response.data;
    },
    {
      enabled: specializationsArray.length > 0,
    },
  );
};
