export const TargetIcon = () => (
  <svg width="16" height="16" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 3.54175C6.43318 3.54175 3.54169 6.43324 3.54169 10.0001C3.54169 13.5669 6.43318 16.4584 10 16.4584C13.5669 16.4584 16.4584 13.5669 16.4584 10.0001C16.4584 6.43324 13.5669 3.54175 10 3.54175ZM2.29169 10.0001C2.29169 5.74289 5.74282 2.29175 10 2.29175C14.2572 2.29175 17.7084 5.74289 17.7084 10.0001C17.7084 14.2573 14.2572 17.7084 10 17.7084C5.74282 17.7084 2.29169 14.2573 2.29169 10.0001ZM10 6.45841C8.04401 6.45841 6.45835 8.04407 6.45835 10.0001C6.45835 11.9561 8.04401 13.5418 10 13.5418C11.956 13.5418 13.5417 11.9561 13.5417 10.0001C13.5417 8.04407 11.956 6.45841 10 6.45841ZM5.20835 10.0001C5.20835 7.35372 7.35366 5.20841 10 5.20841C12.6464 5.20841 14.7917 7.35372 14.7917 10.0001C14.7917 12.6465 12.6464 14.7918 10 14.7918C7.35366 14.7918 5.20835 12.6465 5.20835 10.0001ZM8.12502 10.0001C8.12502 11.0356 8.96449 11.8751 10 11.8751C11.0356 11.8751 11.875 11.0356 11.875 10.0001C11.875 8.96455 11.0356 8.12508 10 8.12508C8.96449 8.12508 8.12502 8.96455 8.12502 10.0001Z"
      fill="var(--text-contrast)"
    />
  </svg>
);
