import { FC } from 'react';

interface Props {
  size?: number;
}

export const TwitterIcon: FC<Props> = ({ size = 24 }) => (
  <svg width={size} height={size} fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M20 6.657a6.904 6.904 0 0 1-2.003.557 3.536 3.536 0 0 0 1.533-1.956 6.929 6.929 0 0 1-2.214.858A3.462 3.462 0 0 0 14.77 5c-1.926 0-3.488 1.582-3.488 3.534 0 .277.03.547.09.806-2.898-.148-5.468-1.555-7.189-3.693-.3.522-.472 1.13-.472 1.777 0 1.226.616 2.308 1.552 2.942a3.439 3.439 0 0 1-1.58-.443v.045c0 1.712 1.202 3.14 2.798 3.466a3.448 3.448 0 0 1-1.575.06c.443 1.404 1.731 2.426 3.258 2.454A6.94 6.94 0 0 1 3 17.412 9.778 9.778 0 0 0 8.346 19c6.416 0 9.924-5.386 9.924-10.056a10.2 10.2 0 0 0-.01-.457A7.142 7.142 0 0 0 20 6.657"
      fill="#55ACEE"
    />
  </svg>
);
