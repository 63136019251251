import { Alert, Button, confirmDialog } from '@geeckocom/core-ui';
import { ReplyIcon } from '@heroicons/react/outline';
import { XIcon } from '@heroicons/react/solid';
import { useTranslations } from 'next-intl';
import { FC, useEffect, useRef } from 'react';
import { FormProvider, UnpackNestedValue, useForm } from 'react-hook-form';

import { ResumeFormResource } from '@/features/resume-builder';
import { ResumeBuilderFormProps } from '@/features/resume-builder/forms/types';
import { useGeneratedApi } from '@/hooks/useApi';
import { ApiError } from '@/shared/api';
import { handleError } from '@/shared/api/schema/handle-error';
import { EmbedVideoPlayer } from '@/shared/components/embed-video-player';
import { FormField } from '@/shared/components/form-field';
import { FormInput } from '@/shared/components/form-input';
import { Heading } from '@/shared/components/heading';
import { usePreventFormLeaving } from '@/shared/hooks/use-prevent-form-leaving';
import { handleValidationErrors } from '@/shared/lib/handle-validation-errors';
import { PageTitle } from '@/shared/page-title';
import { showErrorToast } from '@/utils/showToast';

interface VideoUrlFormValues {
  video_url: string;
}

export const ResumeVideoIntroductionForm: FC<ResumeBuilderFormProps> = ({ resume, onFieldChanged, onSubmitted }) => {
  const t = useTranslations();
  const api = useGeneratedApi();

  const formMethods = useForm<ResumeFormResource>({ defaultValues: resume });
  const {
    handleSubmit,
    formState: { isSubmitting, isSubmitSuccessful, isDirty },
    setError,
    setValue,
    watch,
  } = formMethods;

  const videoUrlFormMethods = useForm<VideoUrlFormValues>({ defaultValues: { video_url: resume.video?.url || '' } });
  const {
    handleSubmit: handleVideoUrlSubmit,
    formState: { isSubmitting: videoUrlIsSubmitting },
    setError: setVideoUrlError,
    setValue: setVideoUrlValue,
  } = videoUrlFormMethods;

  usePreventFormLeaving(isSubmitting, isSubmitSuccessful, isDirty);

  // Update fields calculation
  const formFields = watch();

  const originalVideoRef = useRef(resume.video);

  const video = watch('video');

  useEffect(() => {
    onFieldChanged('video-introduction', formFields as ResumeFormResource);
  }, [formFields]);

  const isResumeCompleted = resume.state === 'completed';

  const handleFormSubmit = async (data: UnpackNestedValue<ResumeFormResource>) => {
    try {
      const response = await api.resume.patchApiResumeVideoIntroduction({
        video_url: data.video?.url,
      });

      if (response.data) {
        await onSubmitted(response.data);
      }
    } catch (e) {
      if (e instanceof ApiError) {
        if (e.status === 422) {
          handleValidationErrors(setError, e.body.errors);
          return;
        }
      }

      showErrorToast(handleError(e, t));
    }
  };

  const handleVideoFormSubmit = async (data: UnpackNestedValue<VideoUrlFormValues>) => {
    try {
      const response = await api.resume.postApiResumeVideoIntroductionPreview({
        video_url: data.video_url,
      });

      setValue('video', response.data);
    } catch (e) {
      if (e instanceof ApiError) {
        if (e.status === 422) {
          handleValidationErrors(setVideoUrlError, e.body.errors);
          return;
        }
      }

      showErrorToast(handleError(e, t));
    }
  };

  const videoFormSubmit = handleVideoUrlSubmit(handleVideoFormSubmit);

  useEffect(() => {
    const handlePaste = (event: ClipboardEvent) => {
      if ((event.target as HTMLElement).tagName === 'INPUT') {
        return;
      }
      const pasteData = event.clipboardData?.getData('text');
      if (!pasteData || !/^https?:\/\//iu.test(pasteData)) {
        return;
      }

      setVideoUrlValue('video_url', pasteData);
      videoFormSubmit();
    };

    document.addEventListener('paste', handlePaste);

    return () => {
      document.removeEventListener('paste', handlePaste);
    };
  }, []);

  const handleRestoreVideo = () => {
    if (originalVideoRef.current) {
      setValue('video', originalVideoRef.current);
      setVideoUrlValue('video_url', originalVideoRef.current.url || '');
    }
  };

  return (
    <>
      <PageTitle title={t('features.resume_builder.resume_video_introduction_form.title')} />

      <div className="container">
        <div className="mb-11">
          <Heading>{t('features.resume_builder.resume_video_introduction_form.title')}</Heading>
        </div>

        <div className="mt-16">
          <Alert>
            <div className="flex items-center space-x-6">
              <div className="shrink-0 ml-2">
                <svg width="24" height="40" viewBox="0 0 24 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M23 7L16 12L23 17V7Z"
                    stroke="var(--text-primary)"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M14 5H3C1.89543 5 1 5.89543 1 7V17C1 18.1046 1.89543 19 3 19H14C15.1046 19 16 18.1046 16 17V7C16 5.89543 15.1046 5 14 5Z"
                    stroke="var(--text-primary)"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M2.345 35L5.165 30.92L2.45 26.96H4.895L6.395 29.3L7.91 26.96H10.295L7.58 30.875L10.415 35H7.97L6.35 32.495L4.73 35H2.345ZM15.4248 35.18C13.7148 35.18 12.3498 34.575 11.3298 33.365L12.9198 31.85C13.6698 32.69 14.5148 33.11 15.4548 33.11C15.8948 33.11 16.2498 32.995 16.5198 32.765C16.7998 32.525 16.9398 32.205 16.9398 31.805C16.9398 31.375 16.7598 31.045 16.3998 30.815C16.0398 30.585 15.5348 30.47 14.8848 30.47H13.9248L13.5648 29L16.0698 26.48H11.9448V24.5H19.0398V26.24L16.3848 28.775C18.2648 29.095 19.2048 30.085 19.2048 31.745C19.2048 32.755 18.8598 33.58 18.1698 34.22C17.4898 34.86 16.5748 35.18 15.4248 35.18Z"
                    fill="var(--text-primary)"
                  />
                </svg>
              </div>
              <div>{t.rich('features.resume_builder.resume_video_introduction_form.video_benefits_alert')}</div>
            </div>
          </Alert>
        </div>

        <div className="row gy-5 mt-8">
          <section className="col-24 md:col-12">
            <Heading as="h3" size="2xl">
              {t('features.resume_builder.resume_video_introduction_form.example_title')}
            </Heading>

            <EmbedVideoPlayer
              width={560}
              height={315}
              embedUrl="https://www.youtube.com/embed/aUt6sqjhTPo"
              thumbnailUrl="/images/resume_builder/video-cv-example.jpg"
              thumbnailAlt={t('features.resume_builder.resume_video_introduction_form.example_image_alt')}
            />
          </section>

          <section className="col-24 md:col-12">
            <Heading as="h3" size="2xl">
              {t('features.resume_builder.resume_video_introduction_form.how_to.title')}
            </Heading>

            <ul className="mt-3 text-sm">
              <li className="flex items-center mt-2 text-text-secondary">
                <svg
                  className="block"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7.99999 14.6663C11.6819 14.6663 14.6667 11.6816 14.6667 7.99967C14.6667 4.31778 11.6819 1.33301 7.99999 1.33301C4.3181 1.33301 1.33333 4.31778 1.33333 7.99967C1.33333 11.6816 4.3181 14.6663 7.99999 14.6663Z"
                    stroke="var(--text-primary)"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M8 4V8L10.6667 9.33333"
                    stroke="var(--text-primary)"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>

                <span className="ml-2">
                  {t('features.resume_builder.resume_video_introduction_form.how_to.max_time')}
                </span>
              </li>
              <li className="flex items-center mt-2 text-text-secondary">
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M15.0267 4.28033C14.9475 3.96393 14.7862 3.67404 14.5591 3.43993C14.332 3.20582 14.0472 3.03578 13.7333 2.94699C12.5867 2.66699 8 2.66699 8 2.66699C8 2.66699 3.41334 2.66699 2.26667 2.97366C1.95284 3.06245 1.66799 3.23249 1.4409 3.4666C1.21382 3.70071 1.05253 3.9906 0.973338 4.30699C0.763481 5.4707 0.660829 6.6512 0.666671 7.83366C0.659191 9.02502 0.76185 10.2145 0.973338 11.387C1.06064 11.6936 1.22554 11.9724 1.4521 12.1966C1.67866 12.4209 1.95922 12.5829 2.26667 12.667C3.41334 12.9737 8 12.9737 8 12.9737C8 12.9737 12.5867 12.9737 13.7333 12.667C14.0472 12.5782 14.332 12.4082 14.5591 12.1741C14.7862 11.9399 14.9475 11.6501 15.0267 11.3337C15.2349 10.1787 15.3376 9.00722 15.3333 7.83366C15.3408 6.6423 15.2382 5.45279 15.0267 4.28033V4.28033Z"
                    stroke="var(--text-primary)"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M6.5 10.0133L10.3333 7.83332L6.5 5.65332V10.0133Z"
                    stroke="var(--text-primary)"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>

                <span className="ml-2">
                  {t('features.resume_builder.resume_video_introduction_form.how_to.video_hosting')}
                </span>
              </li>
              <li className="flex items-center mt-2 text-text-secondary">
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M14 7.66669C14.0023 8.5466 13.7967 9.41461 13.4 10.2C12.9296 11.1412 12.2065 11.9328 11.3116 12.4862C10.4168 13.0396 9.3855 13.3329 8.33333 13.3334C7.45342 13.3356 6.58541 13.1301 5.8 12.7334L2 14L3.26667 10.2C2.86995 9.41461 2.66437 8.5466 2.66667 7.66669C2.66707 6.61452 2.96041 5.58325 3.51381 4.68839C4.06722 3.79352 4.85884 3.0704 5.8 2.60002C6.58541 2.20331 7.45342 1.99772 8.33333 2.00002H8.66667C10.0562 2.07668 11.3687 2.66319 12.3528 3.64726C13.3368 4.63132 13.9233 5.94379 14 7.33335V7.66669Z"
                    stroke="var(--text-primary)"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>

                <span className="ml-2">
                  {t('features.resume_builder.resume_video_introduction_form.how_to.language')}
                </span>
              </li>
              <li className="flex items-center mt-2 text-text-secondary">
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M7.99999 14.6663C11.6819 14.6663 14.6667 11.6816 14.6667 7.99967C14.6667 4.31778 11.6819 1.33301 7.99999 1.33301C4.3181 1.33301 1.33333 4.31778 1.33333 7.99967C1.33333 11.6816 4.3181 14.6663 7.99999 14.6663Z"
                    stroke="var(--text-primary)"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M5.33333 9.33301C5.33333 9.33301 6.33333 10.6663 7.99999 10.6663C9.66666 10.6663 10.6667 9.33301 10.6667 9.33301"
                    stroke="var(--text-primary)"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path d="M6 6H6.00667" stroke="var(--text-primary)" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M10 6H10.0067" stroke="var(--text-primary)" strokeLinecap="round" strokeLinejoin="round" />
                </svg>

                <span className="ml-2">{t('features.resume_builder.resume_video_introduction_form.how_to.relax')}</span>
              </li>
            </ul>
          </section>
        </div>

        <FormProvider {...videoUrlFormMethods}>
          <form onSubmit={videoFormSubmit} aria-label="Preview Form">
            <div className="row gy-5 mt-8">
              <div className="col-24">
                <FormField
                  label={t('features.resume_builder.resume_video_introduction_form.video_url_label')}
                  htmlFor="resume-builder--video-url"
                >
                  <div className="flex w-full space-x-4">
                    <div className="flex-grow">
                      <FormInput
                        id="resume-builder--video-url"
                        name="video_url"
                        type="url"
                        placeholder={t('features.resume_builder.resume_video_introduction_form.video_url_placeholder')}
                        rules={{ required: t('features.resume_builder.validation.required_field') }}
                      />
                    </div>
                    <div>
                      <Button type="submit" variant="outlined" isLoading={videoUrlIsSubmitting}>
                        Check
                      </Button>
                    </div>
                  </div>
                </FormField>
              </div>
            </div>

            {video ? (
              <>
                <div className="mt-5">
                  <FormField label={t('features.resume_builder.resume_video_introduction_form.preview_title')}>
                    <EmbedVideoPlayer
                      key={video.embed_url}
                      width={video.thumbnail_width}
                      height={video.thumbnail_height}
                      embedUrl={video.embed_url}
                      thumbnailUrl={video.thumbnail_url}
                      thumbnailAlt={t('features.resume_builder.resume_video_introduction_form.preview_alt')}
                    />
                  </FormField>
                </div>
                <div className="flex flex-wrap">
                  {originalVideoRef.current && originalVideoRef.current?.url !== video.url ? (
                    <div className="mr-3 mb-3">
                      <Button
                        variant="outlined"
                        leadingIcon={<ReplyIcon className="h-5 w-5" />}
                        onClick={handleRestoreVideo}
                      >
                        {t('features.resume_builder.resume_video_introduction_form.restore_video')}
                      </Button>
                    </div>
                  ) : null}
                  <div className="mr-3 mb-3">
                    <Button
                      color="danger"
                      variant="outlined"
                      leadingIcon={<XIcon className="h-5 w-5" />}
                      onClick={() => {
                        confirmDialog({
                          message: t(
                            'features.resume_builder.resume_video_introduction_form.remove_confirm_modal.message',
                          ),
                          title: t('features.resume_builder.resume_video_introduction_form.remove_confirm_modal.title'),
                          confirmButton: t(
                            'features.resume_builder.resume_video_introduction_form.remove_confirm_modal.do_delete_button',
                          ),
                          cancelButton: t(
                            'features.resume_builder.resume_video_introduction_form.remove_confirm_modal.do_not_delete_button',
                          ),
                          onConfirm: async () => {
                            setValue('video', undefined);
                            setVideoUrlValue('video_url', '');
                          },
                        });
                      }}
                    >
                      {t('features.resume_builder.resume_video_introduction_form.remove_video')}
                    </Button>
                  </div>
                </div>
              </>
            ) : (
              <div className="mt-5">
                {originalVideoRef.current ? (
                  <div className="mr-3 mb-3">
                    <Button
                      variant="outlined"
                      leadingIcon={<ReplyIcon className="h-5 w-5" />}
                      onClick={handleRestoreVideo}
                    >
                      {t('features.resume_builder.resume_video_introduction_form.restore_video')}
                    </Button>
                  </div>
                ) : null}
              </div>
            )}
          </form>
        </FormProvider>

        <FormProvider {...formMethods}>
          <form onSubmit={handleSubmit(handleFormSubmit)}>
            <div className="flex space-x-4 mt-16 mb-10">
              <Button type="submit" isLoading={isSubmitting || videoUrlIsSubmitting} size="lg">
                {isResumeCompleted
                  ? t('features.resume_builder.submit_button_save')
                  : t('features.resume_builder.submit_button_continue')}
              </Button>
            </div>
          </form>
        </FormProvider>
      </div>
    </>
  );
};

export { calculateVideoIntroductionFormFillRate } from './model';
