import { Button, confirmDialog } from '@geeckocom/core-ui';
import { CameraIcon, TrashIcon, UserCircleIcon } from '@heroicons/react/outline';
import clsx from 'clsx';
import { useTranslations } from 'next-intl';
import React, { FC, MouseEvent, useId } from 'react';
import { FileRejection, useDropzone } from 'react-dropzone';

import { Spinner } from '@/components/UI/Spinner';
import { showErrorToast, showSuccessToast } from '@/utils/showToast';

import styles from './PhotoUploader.module.css';

const MAX_SIZE = 8;

interface Props {
  id?: string;
  value?: string | null;
  handleSelectFiles: (acceptedFiles: File[]) => void;
  onRemoveConfirm: () => Promise<unknown>;
  isLoading?: boolean;
}

export const PhotoUploader: FC<Props> = ({ value, id, handleSelectFiles, onRemoveConfirm, isLoading }) => {
  const t = useTranslations();
  const reactId = useId();
  const completeId = id || reactId;

  const handleRemove = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();

    confirmDialog({
      message: t('shared.components.photo_uploader.photo_delete.message'),
      title: t('shared.components.photo_uploader.photo_delete.title'),
      confirmButton: t('shared.components.photo_uploader.photo_delete.delete_button'),
      cancelButton: t('shared.components.photo_uploader.photo_delete.cancel_button'),
      color: 'danger',
      onConfirm: async () => {
        await onRemoveConfirm();
        showSuccessToast(t('shared.components.photo_uploader.toast_deleted'));
      },
    });
  };

  const handleRejected = (fileRejections: FileRejection[]) => {
    if (fileRejections.length > 0) {
      const rejection = fileRejections[0];
      for (let i = 0; i < rejection.errors.length; i += 1) {
        const error = rejection.errors[i];
        if (error.code === 'file-invalid-type') {
          showErrorToast(t('shared.components.photo_uploader.error_format'));
          break;
        }

        if (error.code === 'file-too-large') {
          showErrorToast(t('shared.components.photo_uploader.error_too_big', { size: MAX_SIZE }));
          break;
        }
      }
    }
  };

  const { getRootProps, getInputProps, isDragAccept, isDragReject } = useDropzone({
    accept: ['image/jpeg', 'image/png'],
    maxSize: MAX_SIZE * 1024 * 1024,
    onDropAccepted: handleSelectFiles,
    onDropRejected: handleRejected,
  });

  return (
    <div
      className={clsx(styles.Wrapper, isDragAccept && styles.WrapperActive, isDragReject && styles.WrapperReject)}
      {...getRootProps()}
    >
      <input multiple={false} id={completeId} {...getInputProps()} disabled={isLoading} accept="image/jpeg,image/png" />
      <div className={styles.Inner}>
        <div className={styles.PhotoWrapper}>
          {value && <img src={value} alt="" />}
          {!value && <UserCircleIcon className="w-6 h-6" />}
          {isLoading && (
            <div className={styles.PhotoUploading}>
              <Spinner color="#fff" secondaryColor="#777" size={36} />
            </div>
          )}
        </div>
        <div className={styles.ContentWrapper}>
          <div className={styles.MainTextWrapper}>
            <CameraIcon className="w-4 h-4" />
            <div className={styles.MainText}>{t('shared.components.photo_uploader.select_file')}</div>
          </div>
          <div className={styles.SecondText}>
            {t('shared.components.photo_uploader.file_requirements', { size: MAX_SIZE })}
          </div>
          <div className={styles.SecondText}>{t('shared.components.photo_uploader.or_drop_here')}</div>
        </div>
        {value && !value.includes('gravatar') && (
          <div className={styles.RemotePhotoWrapper}>
            <Button
              variant="ghost"
              color="danger"
              size="sm"
              leadingIcon={<TrashIcon className="w-4 h-4" />}
              onClick={handleRemove}
            >
              {t('shared.components.photo_uploader.delete_button')}
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};
