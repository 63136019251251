import { Alert, Button, Chip } from '@geeckocom/core-ui';
import { useTranslations } from 'next-intl';
import { FC, useEffect } from 'react';
import { FormProvider, UnpackNestedValue, useForm } from 'react-hook-form';

import { SkillSetBuilder } from '@/entities/skill-set-builder';
import { NoTargetIcon } from '@/entities/skill-set-builder/NoTargetIcon';
import { TargetIcon } from '@/entities/skill-set-builder/TargetIcon';
import { ResumeBuilderFormProps } from '@/features/resume-builder/forms/types';
import { ResumeFormResource } from '@/features/resume-builder/model';
import { useGeneratedApi } from '@/hooks/useApi';
import { ApiError } from '@/shared/api-utility';
import { Heading } from '@/shared/components/heading';
import { usePrefilledFields } from '@/shared/hooks/use-prefilled-fields';
import { usePreventFormLeaving } from '@/shared/hooks/use-prevent-form-leaving';
import { ArrowRightIcon } from '@/shared/icons/ArrowRightIcon';
import { InformationIcon } from '@/shared/icons/InformationIcon';
import { handleValidationErrors } from '@/shared/lib/handle-validation-errors';
import { PageTitle } from '@/shared/page-title';

const formFieldsToPrefill = ['specialization_areas', 'specializations', 'programming_languages'];

export const ResumeTechnologyStackForm: FC<ResumeBuilderFormProps> = ({ resume, onFieldChanged, onSubmitted }) => {
  const t = useTranslations();
  const api = useGeneratedApi();

  const formMethods = useForm<ResumeFormResource>({ defaultValues: resume });
  const {
    handleSubmit,
    formState: { isSubmitting, isSubmitSuccessful, isDirty },
    setError,
    watch,
  } = formMethods;

  const hasPrefilledFields = usePrefilledFields(formFieldsToPrefill, resume.prefilled_fields);
  usePreventFormLeaving(isSubmitting, isSubmitSuccessful, isDirty);

  // Update fields calculation
  const formFields = watch();

  useEffect(() => {
    onFieldChanged('technology-stack', formFields as ResumeFormResource);
  }, [formFields]);

  const isResumeCompleted = resume.state === 'completed';

  const handleFormSubmit = async (data: UnpackNestedValue<ResumeFormResource>) => {
    try {
      const response = await api.resume.patchApiResumeTechnologyStack({
        specialization_areas: data.specialization_areas,
        specializations: data.specializations,
        programming_languages: data.programming_languages,
        technologies: data.technologies,
        databases: data.databases,
      });

      if (response.data) {
        await onSubmitted(response.data);
      }
    } catch (e) {
      if (e instanceof ApiError) {
        if (e.status === 422) {
          handleValidationErrors(setError, e.body.errors);
        }
      }
    }
  };

  return (
    <FormProvider {...formMethods}>
      <PageTitle title={t('features.resume_builder.steps.technology_stack.title')} />
      <form onSubmit={handleSubmit(handleFormSubmit)}>
        <div className="container">
          <div className="mb-11">
            <Heading>{t('features.resume_builder.resume_technology_stack_form.title')}</Heading>
          </div>

          <div className="mb-8">
            <Alert>{t('features.resume_builder.resume_technology_stack_form.hint_text')}</Alert>
          </div>

          {hasPrefilledFields && resume.state === 'technology-stack' ? (
            <div className="mb-8">
              <Alert icon={<InformationIcon />}>{t('features.resume_builder.prefilled_fields_information')}</Alert>
            </div>
          ) : null}

          <div className="mt-8">
            <Alert>
              <div className="p-3">
                <span>{t('features.resume_builder.resume_technology_stack_form.job_search_alert_description')}</span>
                <div className="flex flex-wrap">
                  <div className="flex mt-3 items-center">
                    <Chip>{t('features.resume_builder.resume_technology_stack_form.not_proficient')}</Chip>
                    <span className="mx-4 text-text-tertiary">
                      <ArrowRightIcon />
                    </span>
                  </div>
                  <div className="flex mt-3 items-center">
                    <Chip active>
                      {t('features.resume_builder.resume_technology_stack_form.proficient')}
                      <span className="ml-2">
                        <TargetIcon />
                      </span>
                    </Chip>
                    <span className="mx-4 text-text-tertiary">
                      <ArrowRightIcon />
                    </span>
                  </div>
                  <div className="flex mt-3 items-center">
                    <Chip active partial>
                      {t('features.resume_builder.resume_technology_stack_form.proficient_not_looking_for_a_job')}
                      <span className="ml-2">
                        <NoTargetIcon />
                      </span>
                    </Chip>
                  </div>
                </div>
              </div>
            </Alert>
          </div>

          <div className="mt-9">
            <SkillSetBuilder />
          </div>

          <div className="flex space-x-4 mt-16 mb-10">
            <Button type="submit" isLoading={isSubmitting} size="lg">
              {isResumeCompleted
                ? t('features.resume_builder.submit_button_save')
                : t('features.resume_builder.submit_button_continue')}
            </Button>
          </div>
        </div>
      </form>
    </FormProvider>
  );
};

export { calculateTechnologyStackFormFillRate } from './model';
