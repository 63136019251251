import format from 'date-fns/format';
import formatDuration from 'date-fns/formatDuration';
import intervalToDuration from 'date-fns/intervalToDuration';
import parse from 'date-fns/parse';
import upperFirst from 'lodash/upperFirst';
import { useTranslations } from 'next-intl';
import { useMemo } from 'react';

import { useLocale } from '@/hooks/useLocale';
import { dateFnsLocales } from '@/shared/lib/date-fns-locales';

type DateTypes = {
  dateFrom: Date | number;
  dateTo: Date | number;
  formattedDateFrom: string | undefined;
  formattedDateTo: string | undefined;
  dateRangeFormatted: string;
  duration: string | undefined;
  finishDate: string;
};

export const useGetDates = (
  from_month?: string | null | undefined,
  from_year?: number | null | undefined,
  current?: boolean | undefined,
  to_month?: string | null | undefined,
  to_year?: number | null | undefined,
): DateTypes => {
  const locale = useLocale();
  const t = useTranslations();
  const dateFrom = useMemo(() => {
    if (from_month && from_year) {
      return parse(`${from_month}/${from_year}`, 'MMM/yyyy', new Date());
    }
    return 0;
  }, [from_month, from_year]);

  const dateTo = useMemo(() => {
    if (current || (to_month && to_year)) {
      return !current ? parse(`${to_month}/${to_year}`, 'MMM/yyyy', new Date()) : new Date();
    }
    return 0;
  }, [to_month, to_year, current]);

  const formattedDateFrom = useMemo(() => {
    const dateString = format(dateFrom, 'LLLL yyyy', { locale: dateFnsLocales[locale.locale] });
    return dateFrom ? upperFirst(dateString) : undefined;
  }, [dateFrom, locale.locale]);

  const formattedDateTo = useMemo(() => {
    if (to_month && to_year) {
      const dateString = format(dateTo, 'LLLL yyyy', { locale: dateFnsLocales[locale.locale] });
      return dateTo ? upperFirst(dateString) : undefined;
    }
    return to_year;
  }, [dateTo, locale.locale]);

  const dateRangeFormatted = useMemo(() => {
    const toDateString = current ? t('entities.resume.resume_experience_item.till_present') : formattedDateTo;
    return formattedDateFrom ? `${upperFirst(formattedDateFrom)} - ${toDateString}` : toDateString;
  }, [formattedDateTo, t]);

  const duration = useMemo(() => {
    if (!dateFrom || !dateTo) {
      return;
    }
    const duration = intervalToDuration({ start: dateFrom, end: dateTo });
    return formatDuration(duration, { locale: dateFnsLocales[locale.locale], format: ['years', 'months'] });
  }, [dateFrom, dateTo, locale.locale]);

  const finishDate = useMemo(
    () => (current ? t('entities.resume.resume_experience_item.till_present') : formattedDateTo),
    [formattedDateTo, t],
  );

  return {
    dateFrom,
    dateTo,
    formattedDateFrom,
    formattedDateTo,
    dateRangeFormatted,
    duration,
    finishDate,
  };
};
