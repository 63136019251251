import { Alert, Button, confirmDialog } from '@geeckocom/core-ui';
import { PlusCircleIcon } from '@heroicons/react/outline';
import { XIcon } from '@heroicons/react/solid';
import { useTranslations } from 'next-intl';
import { FC, useEffect, useMemo } from 'react';
import { UnpackNestedValue, useFieldArray, useForm } from 'react-hook-form';

import { ResumePhotoUploader } from '@/entities/resume/resume-photo-uploader';
import { ResumeFormResource } from '@/features/resume-builder';
import { ResumeBuilderFormProps } from '@/features/resume-builder/forms/types';
import { useGeneratedApi } from '@/hooks/useApi';
import { ApiError, ResumeContact, ResumeContactTypeEnum, ResumeResource } from '@/shared/api';
import { FormField } from '@/shared/components/form-field';
import { FormInput } from '@/shared/components/form-input';
import { FormPhoneInput } from '@/shared/components/form-phone-input';
import { GhostButton } from '@/shared/components/ghost-button';
import { Heading } from '@/shared/components/heading';
import { WideButton } from '@/shared/components/wide-button/WideButton';
import {
  FormContactTypeSelect,
  getContactTypeToAdd,
  getPlaceholderForResumeContactType,
} from '@/shared/data-components/contact-type-select';
import { usePrefilledFields } from '@/shared/hooks/use-prefilled-fields';
import { usePreventFormLeaving } from '@/shared/hooks/use-prevent-form-leaving';
import { InformationIcon } from '@/shared/icons/InformationIcon';
import { handleValidationErrors } from '@/shared/lib/handle-validation-errors';
import { stringValue } from '@/shared/lib/string-value';
import { PageTitle } from '@/shared/page-title';

const formFieldsToPrefill = ['photo', 'first_name', 'last_name', 'email', 'phone'];

export const ResumePersonalDataForm: FC<ResumeBuilderFormProps> = ({ resume, onFieldChanged, onSubmitted }) => {
  const t = useTranslations();
  const api = useGeneratedApi();

  const {
    control,
    handleSubmit,
    formState: { isSubmitting, isDirty, isSubmitSuccessful },
    setError,
    setValue,
    watch,
  } = useForm<ResumeResource>({ defaultValues: resume });

  const hasPrefilledFields = usePrefilledFields(formFieldsToPrefill, resume.prefilled_fields);
  usePreventFormLeaving(isSubmitting, isSubmitSuccessful, isDirty);

  // Contacts field
  const { fields, append, remove } = useFieldArray<ResumeResource>({
    control,
    name: 'contacts',
  });

  // Update fields calculation
  const formFields = watch();

  useEffect(() => {
    onFieldChanged('personal', formFields as ResumeFormResource);
  }, [formFields]);

  const isResumeCompleted = resume.state === 'completed';

  const contactsField = watch('contacts');

  const userPhoto = watch('photo_url');
  const handleUploadedPhoto = (imageURL: string) => {
    setValue('photo_url', imageURL);
  };

  const usedContactTypes: ResumeContactTypeEnum[] = (contactsField || [])
    .filter((contact) => !!contact.type)
    .map((contact) => contact.type as ResumeContactTypeEnum);

  const contactTypeToAdd = useMemo(() => getContactTypeToAdd(usedContactTypes), [usedContactTypes]);

  const handleFormSubmit = async (data: UnpackNestedValue<ResumeResource>) => {
    try {
      const response = await api.resume.patchApiResumePersonal({
        first_name: stringValue(data.first_name),
        last_name: stringValue(data.last_name),
        email: stringValue(data.email),
        phone: stringValue(data.phone),
        birthday: stringValue(data.birthday),
        contacts: data.contacts ? Array.from(data.contacts) : null,
      });

      if (response.data) {
        await onSubmitted(response.data);
      }
    } catch (e) {
      if (e instanceof ApiError) {
        if (e.status === 422) {
          handleValidationErrors(setError, e.body.errors);
        }
      }
    }
  };

  return (
    <form onSubmit={handleSubmit(handleFormSubmit)}>
      <PageTitle title={t('features.resume_builder.steps.personal.title')} />
      <div className="container">
        <div className="mb-11">
          <Heading>{t('features.resume_builder.resume_personal_form.title')}</Heading>
        </div>

        <div className="mb-8">
          <Alert>{t('features.resume_builder.resume_personal_form.hint_text')}</Alert>
        </div>

        {hasPrefilledFields && resume.state === 'personal' ? (
          <div className="mb-8">
            <Alert icon={<InformationIcon />}>{t('features.resume_builder.prefilled_fields_information')}</Alert>
          </div>
        ) : null}

        <FormField
          htmlFor="resume-builder--photo"
          label={t('features.resume_builder.resume_personal_form.photo_label')}
        >
          <ResumePhotoUploader userPhoto={userPhoto} onChange={handleUploadedPhoto} />
        </FormField>

        <div className="row gx-4 mt-6">
          <div className="col-24 sm:col-12 md:col-8">
            <FormField
              label={t('features.resume_builder.resume_personal_form.name_label')}
              htmlFor="resume-builder--first-name"
              required
            >
              <FormInput
                id="resume-builder--first-name"
                name="first_name"
                spellCheck="false"
                control={control}
                rules={{ required: t('features.resume_builder.validation.required_field') }}
              />
            </FormField>
          </div>
          <div className="col-24 sm:col-12 md:col-8">
            <FormField
              label={t('features.resume_builder.resume_personal_form.last_name_label')}
              htmlFor="resume-builder--last-name"
              required
            >
              <FormInput
                id="resume-builder--last-name"
                name="last_name"
                spellCheck="false"
                control={control}
                rules={{ required: t('features.resume_builder.validation.required_field') }}
              />
            </FormField>
          </div>
          <div className="col-24 sm:col-12 md:col-8">
            <FormField
              label={t('features.resume_builder.resume_personal_form.birthday_label')}
              htmlFor="resume-builder--birthday"
              required
            >
              <FormInput id="resume-builder--birthday" name="birthday" type="date" control={control} max="9999-12-31" />
            </FormField>
          </div>
        </div>

        <div className="row gx-4 mt-6">
          <div className="col-24 sm:col-12 md:col-8">
            <FormField
              label={t('features.resume_builder.resume_personal_form.email_label')}
              htmlFor="resume-builder--email"
              required
            >
              <FormInput
                id="resume-builder--email"
                type="email"
                name="email"
                control={control}
                rules={{ required: t('features.resume_builder.validation.required_field') }}
              />
            </FormField>
          </div>
          <div className="col-24 sm:col-12 md:col-8">
            <FormField
              label={t('features.resume_builder.resume_personal_form.phone_label')}
              htmlFor="resume-builder--phone"
              required
            >
              <FormPhoneInput
                id="resume-builder--phone"
                name="phone"
                control={control}
                pattern="[+0-9 \(\)]*"
                rules={{ required: t('features.resume_builder.validation.required_field') }}
              />
            </FormField>
          </div>
        </div>

        <div className="mt-10">
          <h3 className="text-lg font-semibold mb-8 text-text-primary">
            {t('features.resume_builder.resume_personal_form.contacts_and_community_header')}
          </h3>

          {fields.length === 0 ? (
            <div className="mb-10">
              <Alert color="warning">{t('features.resume_builder.resume_personal_form.no_contacts')}</Alert>
            </div>
          ) : null}
          {fields.map((field: ResumeContact & { id: string }, index) => {
            const contactType = watch(`contacts.${index}.type`);
            const contactValue = watch(`contacts.${index}.value`);
            return (
              <div key={field.id}>
                <div className="row row-cols-auto gx-4">
                  <div className="col-24 sm:col-12 md:col-8 text-text-primary">
                    <FormField>
                      <FormContactTypeSelect
                        name={`contacts.${index}.type`}
                        usedContactTypes={usedContactTypes}
                        control={control}
                      />
                    </FormField>
                  </div>
                  <div className="col-auto flex-grow">
                    <FormField>
                      <FormInput
                        name={`contacts.${index}.value`}
                        placeholder={getPlaceholderForResumeContactType(contactType)}
                        control={control}
                      />
                    </FormField>
                  </div>
                  <div className="col">
                    <GhostButton
                      onClick={() => {
                        if (contactValue) {
                          confirmDialog({
                            message: t('features.resume_builder.resume_personal_form.contact_remove.message'),
                            title: t('features.resume_builder.resume_personal_form.contact_remove.title'),
                            confirmButton: t(
                              'features.resume_builder.resume_personal_form.contact_remove.delete_button',
                            ),
                            cancelButton: t(
                              'features.resume_builder.resume_personal_form.contact_remove.cancel_button',
                            ),
                            onConfirm: async () => {
                              remove(index);
                            },
                          });
                        } else {
                          remove(index);
                        }
                      }}
                      aria-label={t('features.resume_builder.resume_personal_form.contact_remove_button')}
                    >
                      <XIcon className="h-5 w-5" />
                    </GhostButton>
                  </div>
                </div>
              </div>
            );
          })}
        </div>

        <WideButton
          disabled={!contactTypeToAdd}
          onClick={() => {
            if (contactTypeToAdd) {
              append({ type: contactTypeToAdd, value: '' });
            }
          }}
        >
          <div className="flex items-center space-x-1">
            <span>
              <PlusCircleIcon className="w-6 h-6" />
            </span>
            <span>{t('features.resume_builder.resume_personal_form.add_contact')}</span>
          </div>
        </WideButton>

        <div className="flex space-x-4 mt-6">
          <Button type="submit" isLoading={isSubmitting} size="lg">
            {isResumeCompleted
              ? t('features.resume_builder.submit_button_save')
              : t('features.resume_builder.submit_button_continue')}
          </Button>
        </div>
      </div>
    </form>
  );
};

export { calculatePersonalDataFormFillRate } from './model';
