import { FC } from 'react';
import ContentLoader from 'react-content-loader';
import { useId } from 'react-id-generator';

interface Props {
  variant?: 1 | 2 | 3 | 4 | 5;
}

export const ChipsGroupSkeleton: FC<Props> = ({ variant = 1 }) => {
  const [uniqueId1, uniqueId2, uniqueId3, uniqueId4, uniqueId5] = useId(5);

  if (variant === 1) {
    return (
      <div className="-m-2 flex flex-wrap">
        <ContentLoader
          backgroundColor="var(--surface-muted)"
          foregroundColor="var(--surface-default)"
          width="60"
          height="40"
          viewBox="0 0 60 40"
          uniqueKey={uniqueId1}
          className="m-2"
        >
          <rect width="60" height="40" rx="8" />
        </ContentLoader>
        <ContentLoader
          backgroundColor="var(--surface-muted)"
          foregroundColor="var(--surface-default)"
          width="80"
          height="40"
          viewBox="0 0 80 40"
          uniqueKey={uniqueId2}
          className="m-2"
        >
          <rect width="80" height="40" rx="8" />
        </ContentLoader>
        <ContentLoader
          backgroundColor="var(--surface-muted)"
          foregroundColor="var(--surface-default)"
          width="86"
          height="40"
          viewBox="0 0 86 40"
          uniqueKey={uniqueId3}
          className="m-2"
        >
          <rect width="86" height="40" rx="8" />
        </ContentLoader>
        <ContentLoader
          backgroundColor="var(--surface-muted)"
          foregroundColor="var(--surface-default)"
          width="100"
          height="40"
          viewBox="0 0 100 40"
          uniqueKey={uniqueId4}
          className="m-2"
        >
          <rect width="100" height="40" rx="8" />
        </ContentLoader>
      </div>
    );
  }

  if (variant === 2) {
    return (
      <div className="-m-2 flex flex-wrap">
        <ContentLoader
          backgroundColor="var(--surface-muted)"
          foregroundColor="var(--surface-default)"
          width="80"
          height="40"
          viewBox="0 0 80 40"
          uniqueKey={uniqueId2}
          className="m-2"
        >
          <rect width="80" height="40" rx="8" />
        </ContentLoader>
        <ContentLoader
          backgroundColor="var(--surface-muted)"
          foregroundColor="var(--surface-default)"
          width="60"
          height="40"
          viewBox="0 0 60 40"
          uniqueKey={uniqueId1}
          className="m-2"
        >
          <rect width="60" height="40" rx="8" />
        </ContentLoader>
        <ContentLoader
          backgroundColor="var(--surface-muted)"
          foregroundColor="var(--surface-default)"
          width="100"
          height="40"
          viewBox="0 0 100 40"
          uniqueKey={uniqueId4}
          className="m-2"
        >
          <rect width="100" height="40" rx="8" />
        </ContentLoader>
        <ContentLoader
          backgroundColor="var(--surface-muted)"
          foregroundColor="var(--surface-default)"
          width="86"
          height="40"
          viewBox="0 0 86 40"
          uniqueKey={uniqueId3}
          className="m-2"
        >
          <rect width="86" height="40" rx="8" />
        </ContentLoader>
        <ContentLoader
          backgroundColor="var(--surface-muted)"
          foregroundColor="var(--surface-default)"
          width="90"
          height="40"
          viewBox="0 0 90 40"
          uniqueKey={uniqueId5}
          className="m-2"
        >
          <rect width="90" height="40" rx="8" />
        </ContentLoader>
      </div>
    );
  }

  return (
    <div className="-m-2 flex flex-wrap">
      <ContentLoader
        backgroundColor="var(--surface-muted)"
        foregroundColor="var(--surface-default)"
        width="80"
        height="40"
        viewBox="0 0 80 40"
        uniqueKey={uniqueId1}
        className="m-2"
      >
        <rect width="80" height="40" rx="8" />
      </ContentLoader>
      <ContentLoader
        backgroundColor="var(--surface-muted)"
        foregroundColor="var(--surface-default)"
        width="90"
        height="40"
        viewBox="0 0 90 40"
        uniqueKey={uniqueId2}
        className="m-2"
      >
        <rect width="90" height="40" rx="8" />
      </ContentLoader>
      <ContentLoader
        backgroundColor="var(--surface-muted)"
        foregroundColor="var(--surface-default)"
        width="140"
        height="40"
        viewBox="0 0 140 40"
        uniqueKey={uniqueId3}
        className="m-2"
      >
        <rect width="140" height="40" rx="8" />
      </ContentLoader>
      <ContentLoader
        backgroundColor="var(--surface-muted)"
        foregroundColor="var(--surface-default)"
        width="80"
        height="40"
        viewBox="0 0 80 40"
        uniqueKey={uniqueId4}
        className="m-2"
      >
        <rect width="80" height="40" rx="8" />
      </ContentLoader>
    </div>
  );
};
