/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Select, SelectProps } from '@geeckocom/core-ui';
import clsx from 'clsx';
import get from 'lodash/get';
import { ReactNode } from 'react';
import { Controller, ControllerProps, FieldValues, useFormState } from 'react-hook-form';

import { ResumeContactTypeEnum } from '@/shared/api';
import { FormError } from '@/shared/components/form-error';
import { BitbucketIcon } from '@/shared/icons/BitbucketIcon';
import { GitHubIcon } from '@/shared/icons/GitHubIcon';
import { GitLabIcon } from '@/shared/icons/GitLabIcon';
import { HabrIcon } from '@/shared/icons/HabrIcon';
import { LinkedInIcon } from '@/shared/icons/LinkedInIcon';
import { SkypeIcon } from '@/shared/icons/SkypeIcon';
import { StackOverflowIcon } from '@/shared/icons/StackOverflowIcon';
import { TelegramIcon } from '@/shared/icons/TelegramIcon';
import { TwitterIcon } from '@/shared/icons/TwitterIcon';
import { VkIcon } from '@/shared/icons/VkIcon';

type ContactOption = {
  id: ResumeContactTypeEnum;
  name: string;
  icon: ReactNode;
  disabled?: boolean;
};

const CONTACTS: ContactOption[] = [
  { id: 'github', name: 'GitHub', icon: <GitHubIcon size={24} /> },
  { id: 'linkedin', name: 'LinkedIn', icon: <LinkedInIcon size={24} /> },
  { id: 'twitter', name: 'Twitter', icon: <TwitterIcon size={24} /> },
  { id: 'telegram', name: 'Telegram', icon: <TelegramIcon size={24} /> },
  { id: 'stackoverflow', name: 'StackOverflow', icon: <StackOverflowIcon size={24} /> },
  { id: 'gitlab', name: 'GitLab', icon: <GitLabIcon size={24} /> },
  { id: 'skype', name: 'Skype', icon: <SkypeIcon size={24} /> },
  { id: 'vk', name: 'VK', icon: <VkIcon size={24} /> },
  { id: 'habr', name: 'Habr', icon: <HabrIcon size={24} /> },
  { id: 'bitbucket', name: 'Bitbucket', icon: <BitbucketIcon size={24} /> },
];

const CONTACT_TYPES_PLACEHOLDERS: Record<ResumeContactTypeEnum, string> = {
  github: 'https://github.com/username',
  gitlab: 'https://gitlab.com/username',
  telegram: '@username',
  linkedin: 'https://www.linkedin.com/in/username',
  stackoverflow: 'https://stackoverflow.com/users/...',
  habr: 'https://habr.com/users/...',
  bitbucket: 'https://bitbucket.org/username',
  skype: 'username',
  vk: 'https://vk.com/username',
  twitter: 'https://twitter.com/username',
};

export const getPlaceholderForResumeContactType = (contactType: ResumeContactTypeEnum | undefined): string =>
  contactType ? CONTACT_TYPES_PLACEHOLDERS[contactType] : '';

export const getContactTypeToAdd = (usedContactTypes: ResumeContactTypeEnum[]): ResumeContactTypeEnum | undefined => {
  const unusedContactTypes = CONTACTS.find(({ id }) => !usedContactTypes.includes(id));
  return unusedContactTypes?.id;
};

interface Props extends Omit<SelectProps, 'value' | 'onChange' | 'options'> {
  value?: ResumeContactTypeEnum;
  onChange?: (value?: ResumeContactTypeEnum) => void;
}

type FormFieldProps<TFieldValues extends FieldValues = FieldValues> = Props &
  Omit<ControllerProps<TFieldValues>, 'render'> & {
    usedContactTypes?: ResumeContactTypeEnum[];
  };

export function FormContactTypeSelect<TFieldValues extends FieldValues = FieldValues>(
  props: FormFieldProps<TFieldValues>,
): JSX.Element {
  const { name, defaultValue, rules, shouldUnregister, control, usedContactTypes, ...rest } = props;
  const { errors } = useFormState<TFieldValues>({ control });
  const errorMessage = get(errors, `${name}.message`);
  const usedContactTypesArray = usedContactTypes ?? [];

  return (
    <>
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        rules={rules}
        shouldUnregister={shouldUnregister}
        render={({ field }) => (
          <Select<ContactOption>
            {...rest}
            {...field}
            color={errorMessage ? 'danger' : 'default'}
            options={CONTACTS.map((option) => ({
              ...option,
              disabled: option.id !== field.value && usedContactTypesArray.includes(option.id),
            }))}
            renderOption={(option: ContactOption) => (
              <div className="flex items-center">
                {option.icon}
                <span className="ml-2">{option.name}</span>
              </div>
            )}
            renderLabel={(option?: ContactOption) => {
              if (!option) {
                return <>&nbsp;</>;
              }
              return (
                <div className={clsx('flex items-center', option.disabled && 'opacity-40 cursor-not-allowed')}>
                  {option.icon}
                  <span className="ml-2">{option.name}</span>
                </div>
              );
            }}
          />
        )}
      />
      {errorMessage ? <FormError>{errorMessage}</FormError> : null}
    </>
  );
}
