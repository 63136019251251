import { FieldPath, FieldValues, UseFormSetError } from 'react-hook-form';

export const handleValidationErrors = <TFieldValues extends FieldValues = FieldValues>(
  setError: UseFormSetError<TFieldValues>,
  errors?: Record<FieldPath<TFieldValues>, unknown> | Record<string, unknown>, // TODO: Investigate Record<string, unknown>
  fieldMatches?: Partial<Record<string, FieldPath<TFieldValues>>>,
): void => {
  if (errors) {
    Object.keys(errors).forEach((key) => {
      if (Array.isArray(errors[key]) && errors[key].length > 0) {
        const error = errors[key][0];

        const localKey: FieldPath<TFieldValues> = (
          fieldMatches && fieldMatches[key] && typeof fieldMatches[key] !== 'undefined' ? fieldMatches[key] : key
        ) as FieldPath<TFieldValues>;

        setError(localKey, { type: 'submit', message: error }, { shouldFocus: true });
      }
    });
  }
};
