import { useCallback } from 'react';

import { useUtilityApi } from '@/hooks/useApi';
import { useLocale } from '@/hooks/useLocale';
import { City, Country } from '@/shared/api-utility';

export type LocalizedCountry = Country & {
  name_localized: string;
};

export type LocalizedCity = City & {
  name_localized: string;
  country?: LocalizedCountry;
};

type GetCitiesResult = (query: string) => Promise<LocalizedCity[]>;

type NameAttrs = {
  name?: string;
  name_en?: string;
  name_localized?: string;
};

export const getLocalizedName = (locale: string, attrs: NameAttrs): string => {
  if (attrs.name_localized) {
    return attrs.name_localized;
  }
  const { name, name_en } = attrs;
  if (locale === 'en') {
    return name_en || name || '';
  }
  return name || name_en || '';
};

export const useGetLocalizedName = () => {
  const { locale } = useLocale();
  return useCallback((attrs: NameAttrs) => getLocalizedName(locale, attrs), [locale]);
};

export const useGetCities = (): GetCitiesResult => {
  const { locale } = useLocale();
  const api = useUtilityApi();

  return useCallback(
    async (query?: string) => {
      const response = await api.dictionaries.getApiDictionariesCities(query);
      return (
        response.data?.map((city) => ({
          ...city,
          name_localized: getLocalizedName(locale, city),
          country: city.country
            ? {
                ...city.country,
                name_localized: getLocalizedName(locale, city.country),
              }
            : undefined,
        })) || []
      );
    },
    [locale, api],
  );
};

type GetCountriesResult = (query: string) => Promise<LocalizedCountry[]>;

export const useGetCountries = (): GetCountriesResult => {
  const { locale } = useLocale();
  const api = useUtilityApi();

  return useCallback(
    async (query?: string) => {
      const response = await api.dictionaries.getApiDictionariesCountries(query);
      return (
        response.data?.map((country) => ({
          ...country,
          name_localized: getLocalizedName(locale, country),
        })) || []
      );
    },
    [locale, api],
  );
};
