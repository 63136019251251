import { ResumeFormResource } from '@/features/resume-builder';

export const calculateEducationFormFillRate = (formValues: ResumeFormResource): number => {
  const allFields = 6;
  let filledFields = 0;

  const educationFillRates: number[] = formValues.universities
    ? formValues.universities.map((educationItem) => {
        let educationFillRate = 0;

        if (educationItem.name) {
          educationFillRate += 1;
        }
        if (educationItem.faculty) {
          educationFillRate += 1;
        }
        if (educationItem.from_month) {
          educationFillRate += 1;
        }
        if (educationItem.from_year) {
          educationFillRate += 1;
        }
        if (educationItem.current) {
          educationFillRate += 2;
        } else {
          if (educationItem.to_month) {
            educationFillRate += 1;
          }
          if (educationItem.to_year) {
            educationFillRate += 1;
          }
        }

        return educationFillRate;
      })
    : [];

  if (educationFillRates.length > 0) {
    filledFields = Math.max(...educationFillRates);
  }

  return (filledFields / allFields) * 100;
};
