import { FC } from 'react';
import ContentLoader from 'react-content-loader';

export const LanguagesSkeleton: FC = () => (
  <div className="flex">
    <ContentLoader
      backgroundColor="var(--surface-muted)"
      foregroundColor="var(--surface-default)"
      width="480"
      height="100"
      viewBox="0 0 480 100"
      className="hidden sm:block "
    >
      <rect width="230" height="40" rx="8" />
      <rect x="251" y="58" width="230" height="40" rx="8" />
      <rect y="58" width="230" height="40" rx="8" />
      <rect x="251" width="230" height="40" rx="8" />
    </ContentLoader>

    <ContentLoader
      backgroundColor="var(--surface-muted)"
      foregroundColor="var(--surface-default)"
      width="250"
      height="300"
      viewBox="0 0 250 300"
      className="block sm:hidden "
    >
      <rect width="250" height="50" rx="8" />
      <rect y="67" width="140" height="50" rx="8" />
      <rect y="250" width="140" height="50" rx="8" />
      <rect y="181" width="250" height="50" rx="8" />
    </ContentLoader>
  </div>
);
