import { Alert, Button } from '@geeckocom/core-ui';
import { PlusCircleIcon } from '@heroicons/react/outline';
import { useTranslations } from 'next-intl';
import { FC, useEffect, useMemo, useState } from 'react';
import { FormProvider, SubmitHandler, useFieldArray, useForm } from 'react-hook-form';

import { ResumeExperienceItem } from '@/entities/resume/resume-experience-item';
import { ExperienceFormModal } from '@/features/resume-builder/forms/resume-experience-form/ExperienceFormModal';
import { getResumePosition } from '@/features/resume-builder/forms/resume-experience-form/types';
import { ResumeBuilderFormProps } from '@/features/resume-builder/forms/types';
import { ResumeFormResource, ResumePositionResourceWithTmpId } from '@/features/resume-builder/model';
import { useGeneratedApi } from '@/hooks/useApi';
import { ApiError, ResumePositionResource } from '@/shared/api';
import { SpecializationAreaEnum } from '@/shared/api-utility';
import { Heading } from '@/shared/components/heading';
import { WideButton } from '@/shared/components/wide-button/WideButton';
import { usePreventFormLeaving } from '@/shared/hooks/use-prevent-form-leaving';
import { handleValidationErrors } from '@/shared/lib/handle-validation-errors';
import { PageTitle } from '@/shared/page-title';

export const ResumeExperienceForm: FC<ResumeBuilderFormProps> = ({ resume, onFieldChanged, onSubmitted }) => {
  const t = useTranslations();
  const api = useGeneratedApi();

  const formMethods = useForm<ResumeFormResource>({
    defaultValues: resume,
  });

  const {
    handleSubmit,
    formState: { isSubmitting, isSubmitSuccessful, isDirty },
    register,
    setError,
    control,
    watch,
    setValue,
  } = formMethods;

  usePreventFormLeaving(isSubmitting, isSubmitSuccessful, isDirty);

  // Update fields calculation
  const formFields = watch();

  useEffect(() => {
    onFieldChanged('experience', formFields as ResumeFormResource);
  }, [formFields]);

  const isResumeCompleted = resume.state === 'completed';

  // Positions
  const { fields, append, remove, update } = useFieldArray<ResumeFormResource, 'positions', 'tmpId'>({
    control,
    name: 'positions',
    keyName: 'tmpId',
  });

  // Missing fields in positions
  const { replace: replaceMissingItems } = useFieldArray<ResumeFormResource, 'completion_state.positions.items'>({
    control,
    name: 'completion_state.positions.items',
  });

  register('completion_state.positions.has_missing_values');

  const missingFieldsValue = watch('completion_state.positions.items');
  const hasMissingValues = watch('completion_state.positions.has_missing_values');

  const isSaveButtonDisabled = !!hasMissingValues;

  const missingFieldsByItem = useMemo(() => {
    const result: Record<number, string[]> = {};

    missingFieldsValue?.forEach((item) => {
      if (item.missing_values.length > 0) {
        result[item.id] = item.missing_values;
      }
    });

    return result;
  }, [missingFieldsValue]);

  const experienceItems = watch('positions');

  const specializationsAreas = watch('specialization_areas');

  const handleFormSubmit: SubmitHandler<ResumeFormResource> = async (data) => {
    try {
      const response = await api.resume.patchApiResumeExperience({
        positions: data.positions as ResumePositionResourceWithTmpId[],
      });

      if (response.data) {
        await onSubmitted(response.data);
      }
    } catch (e) {
      if (e instanceof ApiError) {
        if (e.status === 422) {
          handleValidationErrors(setError, e.body.errors);
        }
      }
    }
  };

  const [currentPositions, setCurrentPositions] = useState<ResumePositionResource>(getResumePosition({}));
  const [isEditModalOpened, setIsEditModalOpened] = useState(false);

  const handleOpenCreateModal = () => {
    setCurrentPositions(getResumePosition({}));
    setIsEditModalOpened(true);
  };

  const handleOpenEditModal = (positionToEdit: ResumePositionResourceWithTmpId) => {
    setCurrentPositions(getResumePosition(positionToEdit));
    setIsEditModalOpened(true);
  };

  const handleCloseCreateModal = () => {
    setCurrentPositions(getResumePosition({}));
    setIsEditModalOpened(false);
  };

  const handleMissingValues = (position: ResumePositionResourceWithTmpId) => {
    if (hasMissingValues) {
      const newItemsWithMissingFields = [...missingFieldsValue].filter(
        (item) => item.id !== position.id && item.missing_values.length > 0,
      );

      const newHasMissingFields = newItemsWithMissingFields.length > 0;

      setValue('completion_state.positions.has_missing_values', newHasMissingFields);
      replaceMissingItems(newItemsWithMissingFields);
    }
  };

  const handleDonePosition = (newPosition: ResumePositionResourceWithTmpId) => {
    const itemIndex = fields.findIndex(
      (field) =>
        (newPosition.id && field.id === newPosition.id) || (newPosition.tmpId && field.tmpId === newPosition.tmpId),
    );
    const actionType = itemIndex >= 0 ? 'update' : 'create';

    if (actionType === 'update') {
      update(itemIndex, newPosition);
    } else {
      append(newPosition);
    }

    // If we had missing fields in this position item, we should flush them
    if (actionType === 'update') {
      handleMissingValues(newPosition);
    }
  };

  return (
    <>
      <PageTitle title={t('features.resume_builder.steps.experience.title')} />
      <FormProvider {...formMethods}>
        <form onSubmit={handleSubmit(handleFormSubmit)}>
          <div className="container">
            <div className="mb-11">
              <Heading>{t('features.resume_builder.resume_experience_form.title')}</Heading>
            </div>

            <div className="mb-8">
              <Alert>{t('features.resume_builder.resume_experience_form.hint_text')}</Alert>
            </div>

            {fields.map((field, fieldIndex) => (
              <ResumeExperienceItem
                key={field.id || field.tmpId}
                position={field}
                index={fieldIndex}
                onEdit={handleOpenEditModal}
                onRemove={() => {
                  handleMissingValues(field);
                  remove(fieldIndex);
                }}
                missingFields={field.id ? missingFieldsByItem[field.id] : undefined}
              />
            ))}

            {fields.length === 0 ? (
              <Alert color="danger">
                {t('features.resume_builder.resume_experience_form.error_positions_required')}
              </Alert>
            ) : null}

            <div className="mt-10">
              <WideButton onClick={handleOpenCreateModal}>
                <div className="flex items-center space-x-1">
                  <span>
                    <PlusCircleIcon className="w-6 h-6" />
                  </span>
                  <span>{t('features.resume_builder.resume_experience_form.add_position_button')}</span>
                </div>
              </WideButton>
            </div>

            {isSaveButtonDisabled ? (
              <div className="mt-4">
                <Alert color="danger">{t('features.resume_builder.resume_experience_form.error_missing_fields')}</Alert>
              </div>
            ) : null}

            <div className="flex space-x-4 mt-6">
              <Button
                type="submit"
                disabled={experienceItems?.length === 0 || isSaveButtonDisabled}
                isLoading={isSubmitting}
                size="lg"
              >
                {isResumeCompleted
                  ? t('features.resume_builder.submit_button_save')
                  : t('features.resume_builder.submit_button_continue')}
              </Button>
            </div>
          </div>
        </form>
      </FormProvider>
      {isEditModalOpened ? (
        <ExperienceFormModal
          isOpen={isEditModalOpened}
          position={currentPositions}
          skillsAreas={(specializationsAreas || []).map((area) => area.slug! as SpecializationAreaEnum)}
          onDone={handleDonePosition}
          onClose={handleCloseCreateModal}
        />
      ) : null}
    </>
  );
};

export { calculateExperienceFormFillRate } from './model';
