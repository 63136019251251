import { ChipsGroupOption } from '@geeckocom/core-ui';
import { useTranslations } from 'next-intl';
import { useMemo } from 'react';

import { WorkingEnvironmentEnum } from '@/shared/api';

export const useGetWorkingEnvironmentOptions = (): ChipsGroupOption[] => {
  const t = useTranslations();

  return useMemo(
    () => [
      { value: 'office', label: t('enums.work_environment.office') },
      { value: 'remote', label: t('enums.work_environment.remote') },
    ],
    [t],
  );
};

export const useGetWorkingEnvironment = (working_environment?: WorkingEnvironmentEnum[]): string | null => {
  const t = useTranslations();

  return useMemo(() => {
    if (!working_environment) {
      return null;
    }

    if (working_environment.includes('remote') && working_environment.includes('office')) {
      return t('entities.resume.public_resume.work_environment.office_or_remote');
    }
    if (working_environment.includes('remote')) {
      return t('entities.resume.public_resume.work_environment.remote');
    }
    if (working_environment.includes('office')) {
      return t('entities.resume.public_resume.work_environment.office');
    }

    return null;
  }, [t, working_environment]);
};
