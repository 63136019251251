import dayjs from 'dayjs';
import { FieldPath, FieldPathValue, FieldValues, Path, UseFormGetValues, Validate } from 'react-hook-form';

import { IntlShape } from '@/shared/types';

export function partialDateIsNotBefore<
  TFieldName extends FieldPath<TFieldValues>,
  TFieldValues extends FieldValues = FieldValues,
>(
  getValues: UseFormGetValues<TFieldValues>,
  fromMonthField: TFieldName,
  fromYearField: TFieldName,
  toMonthField: TFieldName,
  toYearField: TFieldName,
  t: IntlShape,
  onYearStateChange: (isValid: boolean) => void,
): Validate<FieldPathValue<TFieldValues, Path<TFieldValues>>> {
  return () => {
    onYearStateChange(true);
    const fromMonth = getValues(fromMonthField);
    const fromYear = getValues(fromYearField);
    const fromDate = dayjs(`${fromMonth} ${fromYear}`, 'MMM YYYY').tz();

    const toMonth = getValues(toMonthField);
    const toYear = getValues(toYearField);
    const toDate = dayjs(`${toMonth} ${toYear}`, 'MMM YYYY').tz();

    if (fromDate.isAfter(toDate)) {
      onYearStateChange(false);
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return t('shared.lib.form_validators.partial_date_is_not_before.date_is_before');
    }

    return true;
  };
}
