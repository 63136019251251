// TODO: Remove this
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { FieldPath, FieldPathValue, FieldValues, Validate } from 'react-hook-form';

import { IntlShape } from '@/shared/types';

export function required<TFieldName extends FieldPath<TFieldValues>, TFieldValues extends FieldValues = FieldValues>(
  t: IntlShape,
  message?: string,
): Validate<FieldPathValue<TFieldValues, TFieldName>> {
  return (value: unknown) => {
    if (Array.isArray(value)) {
      return value.length > 0 ? true : message || t('shared.lib.form_validators.required_multi_field');
    }

    return value ? true : message || t('shared.lib.form_validators.required_field');
  };
}
