import { FC } from 'react';

interface Props {
  size?: number;
}

export const GitHubIcon: FC<Props> = ({ size = 36 }) => (
  <svg width={size} height={size} fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11.5001 4C6.8062 4 3 7.6723 3 12.2026c0 3.624 2.4355 6.6988 5.8129 7.7833.4248.076.5808-.1779.5808-.3946 0-.1955-.008-.8417-.0116-1.527-2.3647.4961-2.8637-.9679-2.8637-.9679-.3867-.9481-.9438-1.2002-.9438-1.2002-.7712-.5091.0581-.4986.0581-.4986.8536.0578 1.303.8453 1.303.8453.7582 1.2539 1.9885.8914 2.4736.6818.0763-.5301.2965-.892.5396-1.0968-1.888-.2074-3.8726-.9108-3.8726-4.0537 0-.8956.332-1.6273.8757-2.2018-.0882-.2066-.3791-1.0408.0824-2.1707 0 0 .7138-.2204 2.3381.8408.6781-.1817 1.4052-.2728 2.1276-.276.7223.0032 1.4501.0942 2.1293.276 1.6224-1.0611 2.3352-.8407 2.3352-.8407.4627 1.1297.1716 1.9641.0834 2.1706.545.5745.8747 1.3062.8747 2.2018 0 3.1504-1.9884 3.8441-3.8812 4.0472.3049.2545.5765.7537.5765 1.519 0 1.0975-.0098 1.9808-.0098 2.2509 0 .2184.153.4741.5838.3935C17.5675 18.8991 20 15.8255 20 12.2026 20 7.6723 16.1943 4 11.5001 4ZM6.1836 15.6847c-.0187.0408-.0852.053-.1457.025-.0617-.0267-.0963-.0823-.0764-.1232.0183-.0419.085-.0537.1464-.0255.0618.0267.097.0828.0757.1237Zm.418.36c-.0404.0363-.1197.0194-.1735-.0379-.0556-.0571-.066-.1336-.025-.1704.042-.0363.1188-.0193.1745.0379.0556.0578.0664.1338.024.1704Zm.287.4606c-.0522.035-.1373.0022-.19-.0707-.052-.0729-.052-.1604.0012-.1955.0528-.035.1366-.0035.19.0689.0519.0742.0519.1616-.0013.1974v-.0001Zm.485.5335c-.0466.0496-.1458.0363-.2185-.0314-.0743-.0661-.095-.1599-.0482-.2095.0471-.0498.147-.0358.2201.0314.0737.066.0963.1605.0466.2095Zm.627.1801c-.0206.0643-.1162.0935-.2124.0662-.0962-.0281-.159-.1034-.1397-.1683.02-.0647.116-.0951.213-.0659.096.028.159.1027.139.1681v-.0001Zm.7135.0764c.0023.0676-.0793.1237-.1803.125-.1016.0022-.184-.0526-.185-.1192 0-.0682.0798-.1238.1814-.1255.1011-.0019.1839.0525.1839.1197Zm.7009-.0259c.012.066-.0581.1338-.1585.1519-.0987.0173-.19-.0234-.2026-.0889-.0122-.0676.0593-.1354.1578-.1529.1006-.0169.1905.0228.2033.0899Z"
      fill="var(--text-primary)"
    />
  </svg>
);
