import { localeToEmoji } from '@geeckocom/core-react-hooks';
import { Alert, Button, confirmDialog, ToggleGroupOption } from '@geeckocom/core-ui';
import { PlusCircleIcon } from '@heroicons/react/outline';
import { XIcon } from '@heroicons/react/solid';
import { useTranslations } from 'next-intl';
import { FC, Fragment, useEffect, useMemo } from 'react';
import { FormProvider, UnpackNestedValue, useFieldArray, useForm } from 'react-hook-form';

import {
  LocalizedCity,
  LocalizedCountry,
  useGetCities,
  useGetCountries,
  useGetLocalizedName,
} from '@/entities/enums/geo';
import { useFetchLanguages } from '@/entities/enums/languages';
import { ResumeBuilderFormProps } from '@/features/resume-builder/forms/types';
import { ResumeFormResource } from '@/features/resume-builder/model';
import { useGeneratedApi } from '@/hooks/useApi';
import { ApiError, LanguageKnowledgeEnum } from '@/shared/api';
import { FormCheckbox } from '@/shared/components/form-checkbox';
import { FormChipsGroup } from '@/shared/components/form-chips-group';
import { FormField } from '@/shared/components/form-field';
import { FormInput } from '@/shared/components/form-input';
import { FormModernSelect } from '@/shared/components/form-modern-select';
import { FormMultiSelect } from '@/shared/components/form-multi-select';
import { FormNumberInput } from '@/shared/components/form-number-input';
import { FormSelect } from '@/shared/components/form-select';
import { FormTextarea } from '@/shared/components/form-textarea';
import { FormToggleGroup } from '@/shared/components/form-toggle-group';
import { GhostButton } from '@/shared/components/ghost-button';
import { Heading } from '@/shared/components/heading';
import { LanguagesSkeleton } from '@/shared/components/languages-skeleton';
import { WideButton } from '@/shared/components/wide-button/WideButton';
import { getCurrencySign } from '@/shared/currency';
import { usePrefilledFields } from '@/shared/hooks/use-prefilled-fields';
import { usePreventFormLeaving } from '@/shared/hooks/use-prevent-form-leaving';
import { useNumberFormat } from '@/shared/hooks/useNumberFormat';
import { InformationIcon } from '@/shared/icons/InformationIcon';
import { handleValidationErrors } from '@/shared/lib/handle-validation-errors';
import { stringValue } from '@/shared/lib/string-value';
import { useGetWorkingEnvironmentOptions } from '@/shared/lib/work-environments';
import { PageTitle } from '@/shared/page-title';

const CURRENCY_OPTIONS: ToggleGroupOption[] = [
  { value: 'USD', label: '$' },
  { value: 'RUB', label: '₽' },
  { value: 'EUR', label: '€' },
];

type LanguageLevelOption = {
  id: LanguageKnowledgeEnum;
  name: string;
};

const LANGUAGE_LEVEL_OPTIONS: LanguageLevelOption[] = [
  { id: 'beginner', name: 'Beginner' },
  { id: 'elementary', name: 'Elementary' },
  { id: 'pre-intermediate', name: 'Pre-intermediate' },
  { id: 'intermediate', name: 'Intermediate' },
  { id: 'upper-intermediate', name: 'Upper-intermediate' },
  { id: 'advanced', name: 'Advanced' },
  { id: 'proficient', name: 'Proficient' },
  { id: 'native', name: 'Native' },
];

const formFieldsToPrefill = ['city'];

export const ResumeGeneralForm: FC<ResumeBuilderFormProps> = ({ resume, onFieldChanged, onSubmitted }) => {
  const t = useTranslations();
  const api = useGeneratedApi();

  const formMethods = useForm<ResumeFormResource>({ defaultValues: resume });
  const {
    control,
    handleSubmit,
    formState: { isSubmitting, isSubmitSuccessful, isDirty },
    watch,
    setError,
  } = formMethods;

  const hasPrefilledFields = usePrefilledFields(formFieldsToPrefill, resume.prefilled_fields);
  usePreventFormLeaving(isSubmitting, isSubmitSuccessful, isDirty);

  // Update fields calculation
  const formFields = watch();

  useEffect(() => {
    onFieldChanged('general', formFields as ResumeFormResource);
  }, [formFields]);

  const isResumeCompleted = resume.state === 'completed';

  const salaryPeriodOptions: ToggleGroupOption[] = useMemo(
    () => [
      { value: 'monthly', label: t('enums.salary_period_short.monthly') },
      { value: 'annual', label: t('enums.salary_period_short.annual') },
    ],
    [t],
  );

  const workingScheduleOptions: ToggleGroupOption[] = [
    { value: 'full-time', label: t('enums.working_schedule.full_time') },
    { value: 'part-time', label: t('enums.working_schedule.part_time') },
  ];

  const numberFormat = useNumberFormat();

  const currency = watch('salary_currency');
  const relocationToDiffCity = watch('relocation_to_diff_city');
  const relocationToDiffCountry = watch('relocation_to_diff_country');

  const currencySign = useMemo(() => getCurrencySign(currency), [currency]);

  const loadCities = useGetCities();
  const loadCountries = useGetCountries();

  const workEnvironmentOptions = useGetWorkingEnvironmentOptions();

  const getLocalizedName = useGetLocalizedName();

  // Languages knowledge
  const { fields, append, remove } = useFieldArray<ResumeFormResource, 'languages_knowledge'>({
    control,
    name: 'languages_knowledge',
  });

  const hasManagementExperience = watch('has_management_experience');

  const languages = useFetchLanguages();
  const isFormReady = !languages.isLoading;

  const languagesValue = watch('languages_knowledge');

  const usedLanguages = (languagesValue || []).map(({ id }) => id);

  const handleFormSubmit = async (data: UnpackNestedValue<ResumeFormResource>) => {
    try {
      const response = await api.resume.patchApiResumeGeneral({
        position: stringValue(data.position),
        about: stringValue(data.about),
        min_salary: data.min_salary,
        desired_salary: data.desired_salary,
        desired_salary_visible: data.desired_salary_visible,
        salary_currency: data.salary_currency,
        salary_period: data.salary_period,
        work_environment: data.work_environment,
        city: data.city,
        relocation_to_diff_country: data.relocation_to_diff_country,
        relocation_to_diff_city: data.relocation_to_diff_city,
        relocation_countries: data.relocation_countries,
        relocation_cities: data.relocation_cities,
        languages_knowledge: data.languages_knowledge,
        work_schedule: data.work_schedule,
        has_management_experience: !!data.has_management_experience,
        management_experience_description: data.management_experience_description,
        management_experience: data.management_experience,
      });

      if (response.data) {
        await onSubmitted(response.data);
      }
    } catch (e) {
      if (e instanceof ApiError) {
        if (e.status === 422) {
          handleValidationErrors<ResumeFormResource>(setError, e.body.errors, {
            min_salary: 'min_salary_string',
            desired_salary: 'desired_salary_string',
            management_experience: 'management_experience_string',
          });
        }
      }
    }
  };

  return (
    <FormProvider {...formMethods}>
      <PageTitle title={t('features.resume_builder.steps.general.title')} />
      <form onSubmit={handleSubmit(handleFormSubmit)}>
        <div className="container">
          <div className="mb-11">
            <Heading>{t('features.resume_builder.resume_general_form.title')}</Heading>
          </div>

          <div className="mb-8">
            <Alert>{t('features.resume_builder.resume_general_form.hint_text')}</Alert>
          </div>

          {hasPrefilledFields && resume.state === 'general' ? (
            <div className="mb-8">
              <Alert icon={<InformationIcon />}>{t('features.resume_builder.prefilled_fields_information')}</Alert>
            </div>
          ) : null}

          <div className="row gy-5">
            <div className="col-24">
              <FormField
                label={t('features.resume_builder.resume_general_form.position_label')}
                htmlFor="resume-builder--position"
                required
              >
                <FormInput
                  id="resume-builder--position"
                  name="position"
                  control={control}
                  placeholder={t('features.resume_builder.resume_general_form.position_placeholder')}
                  rules={{ required: t('features.resume_builder.validation.required_field') }}
                />
              </FormField>
            </div>
          </div>

          <div className="row gy-5 mt-4">
            <div className="col-24">
              <FormField
                label={t('features.resume_builder.resume_general_form.about_label')}
                htmlFor="resume-builder--about"
              >
                <div>
                  <p className="text-xs text-text-secondary mb-4">
                    {t.rich('features.resume_builder.resume_general_form.about_alert')}
                  </p>
                  <FormTextarea name="about" id="resume-builder--about" autosize control={control} />
                </div>
              </FormField>
            </div>
          </div>

          <div className="row gy-5 mt-7 row-cols-auto">
            <div className="col">
              <FormField
                label={t('features.resume_builder.resume_general_form.salary_currency_label')}
                htmlFor="resume-builder--salary-period"
                required
              >
                <FormToggleGroup
                  name="salary_currency"
                  allowUnset={false}
                  options={CURRENCY_OPTIONS}
                  control={control}
                  rules={{ required: t('features.resume_builder.validation.required_field') }}
                />
              </FormField>
            </div>
            <div className="col">
              <FormField
                label={t('features.resume_builder.resume_general_form.salary_currency_period')}
                htmlFor="resume-builder--salary-period"
                required
              >
                <FormToggleGroup
                  name="salary_period"
                  allowUnset={false}
                  options={salaryPeriodOptions}
                  control={control}
                  rules={{ required: t('features.resume_builder.validation.required_field') }}
                />
              </FormField>
            </div>
          </div>

          <div className="row gy-5 mt-4">
            <div className="col-24 md:col">
              <FormField
                label={t('features.resume_builder.resume_general_form.min_salary_label')}
                htmlFor="resume-builder--min-salary"
                required
              >
                <FormNumberInput
                  id="resume-builder--min-salary"
                  name="min_salary_string"
                  name_numeric="min_salary"
                  control={control}
                  addonLeft={currencySign}
                  isNumericString
                  allowNegative={false}
                  thousandSeparator={numberFormat.thousandSeparator}
                  rules={{ required: t('features.resume_builder.validation.required_field') }}
                />
              </FormField>
            </div>
            <div className="col-24 md:col">
              <FormField
                label={t('features.resume_builder.resume_general_form.desired_salary_label')}
                htmlFor="resume-builder--desired-salary"
                required
              >
                <FormNumberInput
                  id="resume-builder--desired-salary"
                  name="desired_salary_string"
                  name_numeric="desired_salary"
                  control={control}
                  addonLeft={currencySign}
                  thousandSeparator={numberFormat.thousandSeparator}
                  rules={{ required: t('features.resume_builder.validation.required_field') }}
                />
              </FormField>
            </div>
            <div className="col">
              <FormField label={<>&nbsp;</>}>
                <div className="h-10 flex items-center text-text-primary">
                  <FormCheckbox
                    name="desired_salary_visible"
                    control={control}
                    label={t('features.resume_builder.resume_general_form.desired_salary_visible_label')}
                  />
                </div>
              </FormField>
            </div>
          </div>

          <div className="row gy-5 mt-4">
            <div className="col-24">
              <FormField
                label={t('features.resume_builder.resume_general_form.city_label')}
                htmlFor="resume-builder--city"
                required
              >
                <FormModernSelect<LocalizedCity, ResumeFormResource>
                  id="resume-builder--city"
                  async
                  loadOptions={loadCities}
                  name="city"
                  control={control}
                  getValue={(city: LocalizedCity) => `${city.id}`}
                  getLabel={(city: LocalizedCity) => getLocalizedName(city)}
                  renderOption={(option) => (
                    <>
                      <span>{getLocalizedName(option)}</span>
                      <span className="text-sm ml-4">
                        {localeToEmoji(option.country?.iso || '')} {option.country?.name_localized}
                      </span>
                    </>
                  )}
                  renderValue={(option) => (
                    <span className="text-text-primary">
                      {localeToEmoji(option?.country?.iso || '')} {getLocalizedName(option)}
                    </span>
                  )}
                  placeholder={t('features.resume_builder.resume_general_form.city_placeholder')}
                  rules={{ required: t('features.resume_builder.validation.required_field') }}
                />
              </FormField>
            </div>
          </div>

          <div className="row gy-5 mt-5 text-text-primary">
            <div className="w-full sm:w-1/2">
              <FormField label={t('features.resume_builder.resume_general_form.work_environment_label')} required>
                <FormChipsGroup
                  name="work_environment"
                  control={control}
                  options={workEnvironmentOptions}
                  rules={{ required: t('features.resume_builder.validation.required_field') }}
                />
              </FormField>
            </div>
            <div className="w-full mt-2 sm:w-1/2 sm:mt-0">
              <FormField label={t('features.resume_builder.resume_general_form.working_schedule_label')} required>
                <FormChipsGroup
                  name="work_schedule"
                  control={control}
                  options={workingScheduleOptions}
                  rules={{ required: t('features.resume_builder.validation.required_field') }}
                />
              </FormField>
            </div>
            <div className="flex flex-col md:flex-row mt-2">
              <div className="col-auto md:mr-8">
                <FormField label={t('features.resume_builder.resume_general_form.relocation_label')}>
                  <div className="h-10 flex items-center">
                    <FormCheckbox
                      name="relocation_to_diff_city"
                      control={control}
                      label={
                        <span className="text-sm">
                          {t('features.resume_builder.resume_general_form.relocation_to_diff_city_label')}
                        </span>
                      }
                    />
                  </div>
                </FormField>
              </div>
              <div className="col-auto">
                <FormField>
                  <div className="h-10 flex items-center md:mt-7">
                    <FormCheckbox
                      name="relocation_to_diff_country"
                      control={control}
                      label={
                        <span className="text-sm">
                          {t('features.resume_builder.resume_general_form.relocation_to_diff_country_label')}
                        </span>
                      }
                    />
                  </div>
                </FormField>
              </div>
            </div>
          </div>

          {relocationToDiffCity || relocationToDiffCountry ? (
            <div className="row gy-5 mt-4">
              {relocationToDiffCity ? (
                <div className="col-24 md:col-12">
                  <FormField
                    label={t('features.resume_builder.resume_general_form.relocation_cities_label')}
                    htmlFor="resume-builder--relocation-cities"
                  >
                    <FormMultiSelect<LocalizedCity, ResumeFormResource>
                      id="resume-builder--relocation-cities"
                      async
                      loadOptions={loadCities}
                      loadOnInit
                      name="relocation_cities"
                      control={control}
                      getValue={(city: LocalizedCity) => `${city.id}`}
                      getLabel={(city: LocalizedCity) => getLocalizedName(city)}
                      maxValues={5}
                      size="md"
                      renderOption={(option) => (
                        <>
                          <span>{option.name_localized}</span>
                          <span className="text-sm text-gray-500 ml-4">
                            {localeToEmoji(option.country?.iso || '')} {option.country?.name_localized}
                          </span>
                        </>
                      )}
                      renderValue={(option) => (
                        <span className="text-text-primary">
                          {localeToEmoji(option?.country?.iso || '')} {getLocalizedName(option)}
                        </span>
                      )}
                      placeholder={t('features.resume_builder.resume_general_form.relocation_cities_placeholder')}
                    />
                  </FormField>
                </div>
              ) : null}
              {relocationToDiffCountry ? (
                <div className="col-24 md:col-12">
                  <FormField
                    label={t('features.resume_builder.resume_general_form.relocation_countries_label')}
                    htmlFor="resume-builder--relocation-countries"
                  >
                    <FormMultiSelect<LocalizedCountry, ResumeFormResource>
                      id="resume-builder--relocation-countries"
                      async
                      loadOptions={loadCountries}
                      loadOnInit
                      name="relocation_countries"
                      control={control}
                      getValue={(country: LocalizedCountry) => country.iso!}
                      getLabel={(country: LocalizedCountry) => getLocalizedName(country)}
                      maxValues={5}
                      size="md"
                      renderOption={(option) => (
                        <span className={`text-base ${option.prioritized ? 'font-bold' : ''}`}>
                          {localeToEmoji(option?.iso || '')} {getLocalizedName(option)}
                        </span>
                      )}
                      renderValue={(option) => (
                        <span className="text-text-primary">
                          {localeToEmoji(option?.iso || '')} {getLocalizedName(option)}
                        </span>
                      )}
                      placeholder={t('features.resume_builder.resume_general_form.relocation_countries_placeholder')}
                    />
                  </FormField>
                </div>
              ) : null}
            </div>
          ) : null}

          <div className="mt-4 text-text-primary">
            <FormCheckbox
              name="has_management_experience"
              control={control}
              label={t('features.resume_builder.resume_general_form.has_management_experience_label')}
            />
          </div>

          {hasManagementExperience ? (
            <div className="row gy-5 mt-4">
              <div className="col-24 md:col-18">
                <FormField
                  label={t('features.resume_builder.resume_general_form.management_experience_description_label')}
                  htmlFor="resume-builder--management-experience-description"
                  required
                >
                  <FormTextarea
                    name="management_experience_description"
                    id="resume-builder--management-experience-description"
                    placeholder={t(
                      'features.resume_builder.resume_general_form.management_experience_description_placeholder',
                    )}
                    rows={4}
                    autosize
                    control={control}
                    rules={{ required: t('features.resume_builder.validation.required_field') }}
                  />
                </FormField>
              </div>
              <div className="col-24 md:col-6">
                <FormField
                  label={t('features.resume_builder.resume_general_form.management_experience_years_label')}
                  htmlFor="resume-builder--management-experience"
                  required
                >
                  <FormNumberInput
                    id="resume-builder--management-experience"
                    name="management_experience_string"
                    name_numeric="management_experience"
                    control={control}
                    isNumericString
                    allowNegative={false}
                    rules={{
                      required: t('features.resume_builder.validation.required_field'),
                      max: { value: 99, message: t('features.resume_builder.validation.only_two_digits_value') },
                    }}
                  />
                </FormField>
              </div>
            </div>
          ) : null}

          <div className="mt-9 text-text-primary">
            <FormField label={t('features.resume_builder.resume_general_form.languages_knowledge_label')}>
              {!languages.isLoading && languages.data ? (
                <>
                  {fields.length === 0 ? (
                    <div className="mb-10">
                      <Alert>{t('features.resume_builder.resume_general_form.no_languages_knowledge')}</Alert>
                    </div>
                  ) : null}
                  {fields.length > 0 ? (
                    <>
                      {fields.map((fieldValue, index) => {
                        const currentLanguage = watch(`languages_knowledge.${index}.id` as 'languages_knowledge.0.id');
                        return (
                          <Fragment key={fieldValue.id}>
                            {index > 0 ? <div className="block sm:hidden h-2 bg-gray-100 mt-2 mb-6" /> : null}
                            <div className="row row-cols-auto gx-4">
                              <div className="col-24 sm:col-10 md:col-8">
                                <FormField>
                                  <FormSelect
                                    name={`languages_knowledge.${index}.id` as 'languages_knowledge.0.id'}
                                    options={(languages.data || []).map((language) => ({
                                      ...language,
                                      disabled: currentLanguage !== language.id && usedLanguages.includes(language.id),
                                      prioritized: language.prioritized,
                                    }))}
                                    control={control}
                                    placeholder={t('features.resume_builder.resume_general_form.language_placeholder')}
                                    rules={{ required: t('features.resume_builder.validation.required_field') }}
                                  />
                                </FormField>
                              </div>
                              <div className="col-18 sm:col-10 md:col-8">
                                <FormField>
                                  <FormSelect
                                    name={`languages_knowledge.${index}.level` as 'languages_knowledge.0.level'}
                                    options={LANGUAGE_LEVEL_OPTIONS}
                                    control={control}
                                    placeholder={t(
                                      'features.resume_builder.resume_general_form.language_level_placeholder',
                                    )}
                                    rules={{ required: t('features.resume_builder.validation.required_field') }}
                                  />
                                </FormField>
                              </div>
                              <div className="col">
                                <GhostButton
                                  onClick={() => {
                                    confirmDialog({
                                      message: t(
                                        'features.resume_builder.resume_general_form.language_knowledge_remove.message',
                                      ),
                                      title: t(
                                        'features.resume_builder.resume_general_form.language_knowledge_remove.title',
                                      ),
                                      confirmButton: t(
                                        'features.resume_builder.resume_general_form.language_knowledge_remove.delete_button',
                                      ),
                                      cancelButton: t(
                                        'features.resume_builder.resume_general_form.language_knowledge_remove.cancel_button',
                                      ),
                                      onConfirm: async () => {
                                        remove(index);
                                      },
                                    });
                                  }}
                                  aria-label={t(
                                    'features.resume_builder.resume_general_form.language_knowledge_remove_button',
                                  )}
                                >
                                  <XIcon className="h-5 w-5" />
                                </GhostButton>
                              </div>
                            </div>
                          </Fragment>
                        );
                      })}
                    </>
                  ) : null}
                </>
              ) : (
                <LanguagesSkeleton />
              )}
            </FormField>
          </div>

          <WideButton
            onClick={() => {
              append({});
            }}
          >
            <div className="flex items-center space-x-1">
              <span>
                <PlusCircleIcon className="w-6 h-6" />
              </span>
              <span>{t('features.resume_builder.resume_general_form.add_language_knowledge')}</span>
            </div>
          </WideButton>

          <div className="flex space-x-4 mt-6">
            <Button type="submit" isLoading={isSubmitting || !isFormReady} size="lg">
              {isResumeCompleted
                ? t('features.resume_builder.submit_button_save')
                : t('features.resume_builder.submit_button_continue')}
            </Button>
          </div>
        </div>
      </form>
    </FormProvider>
  );
};

export { calculateGeneralFormFillRate } from './model';
