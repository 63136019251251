import { ResumeFormResource } from '@/features/resume-builder';

export const calculateContributionsFormFillRate = (formValues: ResumeFormResource): number => {
  const allFields = 7;
  let filledFields = 0;

  const contributionsFillRates: number[] = formValues.contributions
    ? formValues.contributions.map((contributionItem) => {
        let experienceFillRate = 0;

        if (contributionItem.type) {
          experienceFillRate += 1;
        }
        if (contributionItem.title) {
          experienceFillRate += 1;
        }
        if (contributionItem.url) {
          experienceFillRate += 1;
        }
        if (contributionItem.tools && contributionItem.tools.length > 0) {
          experienceFillRate += 1;
        }
        if (contributionItem.month) {
          experienceFillRate += 1;
        }
        if (contributionItem.year) {
          experienceFillRate += 1;
        }
        if (contributionItem.description) {
          experienceFillRate += 1;
        }

        return experienceFillRate;
      })
    : [];

  if (contributionsFillRates.length > 0) {
    filledFields = Math.max(...contributionsFillRates);
  }

  return (filledFields / allFields) * 100;
};
