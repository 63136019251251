import { Input, InputProps } from '@geeckocom/core-ui';
import get from 'lodash/get';
import { Controller, ControllerProps, FieldValues, useFormState } from 'react-hook-form';

import { FormError } from '@/shared/components/form-error';

type Props<TFieldValues extends FieldValues = FieldValues> = InputProps & Omit<ControllerProps<TFieldValues>, 'render'>;

export function FormInput<TFieldValues extends FieldValues = FieldValues>(props: Props<TFieldValues>): JSX.Element {
  const { name, defaultValue, rules, shouldUnregister, control, color, ...rest } = props;
  const { errors } = useFormState<TFieldValues>({ control });
  const errorMessage = get(errors, `${name}.message`);

  return (
    <>
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        rules={rules}
        shouldUnregister={shouldUnregister}
        render={({ field }) => (
          <Input
            {...rest}
            {...field}
            value={typeof field.value === 'undefined' ? '' : field.value}
            color={color || (errorMessage ? 'danger' : 'default')}
          />
        )}
      />
      {errorMessage ? <FormError>{errorMessage}</FormError> : null}
    </>
  );
}
