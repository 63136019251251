/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Select, SelectProps } from '@geeckocom/core-ui';
import get from 'lodash/get';
import { useMemo } from 'react';
import { Controller, ControllerProps, FieldValues, useFormState } from 'react-hook-form';

import { useLocale } from '@/hooks/useLocale';
import { ResumeContactTypeEnum } from '@/shared/api';
import { FormError } from '@/shared/components/form-error';
import { capitalizeFirstLetter } from '@/shared/lib/capitalize-first-letter';
import { monthsForLocale } from '@/shared/lib/months-for-locale';

type MonthData = {
  id: string;
  name: string;
};

const MONTHS_VALUES = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

interface Props extends Omit<SelectProps, 'value' | 'onChange' | 'options'> {
  value?: ResumeContactTypeEnum;
  onChange?: (value?: ResumeContactTypeEnum) => void;
}

type FormFieldProps<TFieldValues extends FieldValues = FieldValues> = Props &
  Omit<ControllerProps<TFieldValues>, 'render'>;

export function FormMonthSelect<TFieldValues extends FieldValues = FieldValues>(
  props: FormFieldProps<TFieldValues>,
): JSX.Element {
  const { locale } = useLocale();
  const { name, defaultValue, rules, shouldUnregister, control, ...rest } = props;
  const { errors } = useFormState<TFieldValues>({ control });
  const errorMessage = get(errors, `${name}.message`);
  const months = useMemo(() => {
    const actual = monthsForLocale(locale);
    return actual.map((month, monthIndex) => ({ id: MONTHS_VALUES[monthIndex], name: capitalizeFirstLetter(month) }));
  }, [locale]);

  return (
    <>
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        rules={rules}
        shouldUnregister={shouldUnregister}
        render={({ field }) => (
          <Select<MonthData> {...rest} {...field} color={errorMessage ? 'danger' : 'default'} options={months} />
        )}
      />
      {errorMessage ? <FormError>{errorMessage}</FormError> : null}
    </>
  );
}
