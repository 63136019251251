import { Alert, Button } from '@geeckocom/core-ui';
import { useTranslations } from 'next-intl';
import { FC, useEffect, useMemo } from 'react';
import { UnpackNestedValue, useForm } from 'react-hook-form';

import { useGetFeatures } from '@/features/resume-builder/forms/resume-preferences-form/model';
import { ResumeBuilderFormProps } from '@/features/resume-builder/forms/types';
import { ResumeFormResource } from '@/features/resume-builder/model';
import { useGeneratedApi } from '@/hooks/useApi';
import { ApiError } from '@/shared/api';
import { ChipsGroupSkeleton } from '@/shared/components/chips-group-skeleton';
import { FormChipsGroup } from '@/shared/components/form-chips-group';
import { FormField } from '@/shared/components/form-field';
import { Heading } from '@/shared/components/heading';
import { usePreventFormLeaving } from '@/shared/hooks/use-prevent-form-leaving';
import { handleValidationErrors } from '@/shared/lib/handle-validation-errors';

export const ResumePreferencesForm: FC<ResumeBuilderFormProps> = ({ resume, onFieldChanged, onSubmitted }) => {
  const t = useTranslations();
  const api = useGeneratedApi();
  const featuresPreferences = useGetFeatures();

  const workFeatureOptions = useMemo(() => {
    if (!featuresPreferences.data) {
      return [];
    }
    return featuresPreferences.data.map((option) => ({
      value: option.id ?? '',
      label: option.name ?? '',
    }));
  }, [featuresPreferences.data]);

  const {
    control,
    handleSubmit,
    setError,
    formState: { isSubmitting, isSubmitSuccessful, isDirty },
    watch,
  } = useForm<ResumeFormResource>({
    defaultValues: resume,
  });

  usePreventFormLeaving(isSubmitting, isSubmitSuccessful, isDirty);

  // Update fields calculation
  const formFields = watch();

  useEffect(() => {
    onFieldChanged('preferences', formFields as ResumeFormResource);
  }, [formFields]);

  const isResumeCompleted = resume.state === 'completed';

  const handleFormSubmit = async (data: UnpackNestedValue<ResumeFormResource>) => {
    try {
      const response = await api.resume.patchApiResumePreferences({
        preferable_features: data.preferable_features || [],
      });

      if (response.data) {
        await onSubmitted(response.data);
      }
    } catch (e) {
      if (e instanceof ApiError) {
        if (e.status === 422) {
          handleValidationErrors(setError, e.body.errors);
        }
      }
    }
  };

  return (
    <form onSubmit={handleSubmit(handleFormSubmit)}>
      <div className="container">
        <div className="mb-14">
          <Heading>{t('features.resume_builder.resume_preferences_form.title')}</Heading>
        </div>

        <div className="mb-8">
          <Alert>{t('features.resume_builder.resume_preferences_form.hint_text')}</Alert>
        </div>

        <FormField>
          <p className="text-xs text-text-secondary mb-4">
            {t('features.resume_builder.resume_preferences_form.work_features_description')}
          </p>
          {featuresPreferences.isLoading ? (
            <ChipsGroupSkeleton />
          ) : (
            <FormChipsGroup maxSelected={5} name="preferable_features" control={control} options={workFeatureOptions} />
          )}
        </FormField>

        <div className="flex space-x-4 mt-12">
          <Button type="submit" isLoading={isSubmitting || featuresPreferences.isLoading} size="lg">
            {isResumeCompleted
              ? t('features.resume_builder.submit_button_save')
              : t('features.resume_builder.submit_button_continue')}
          </Button>
        </div>
      </div>
    </form>
  );
};

export { calculatePreferencesFormFillRate } from './model';
