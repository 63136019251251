import { ButtonHTMLAttributes, DetailedHTMLProps, ForwardedRef, forwardRef } from 'react';

import styles from './GhostButton.module.css';

type Props = DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> & {
  size?: 'sm' | 'md' | 'lg';
};

const GhostButtonInner = ({ size = 'md', ...rest }: Props, ref: ForwardedRef<HTMLButtonElement>) => (
  <button type="button" ref={ref} data-size={size} {...rest} className={styles.Button} />
);

export const GhostButton = forwardRef<HTMLButtonElement, Props>(GhostButtonInner);
