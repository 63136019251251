import { ApiError } from '@/shared/api';
import { IntlShape } from '@/shared/types';

export const handleError = (error: unknown, t: IntlShape): string => {
  // @ts-ignore
  let errorMessage = t('error.unknown_error');

  if (error instanceof ApiError) {
    if (typeof error.body?.message === 'string' && error.body?.message) {
      errorMessage = error.body?.message;
    } else if (typeof error.body?.error === 'string' && error.body?.error) {
      errorMessage = error.body?.error;
    }

    errorMessage = `${errorMessage} (Code ${error.status})`;
  }

  return errorMessage;
};
