import { ResumeFormResource } from '@/features/resume-builder';

export const calculateGeneralFormFillRate = (formValues: ResumeFormResource): number => {
  let allFields = 7;
  let filledFields = 0;

  if (formValues.position) {
    filledFields += 1;
  }

  if (formValues.about) {
    filledFields += 1;
  }

  if (formValues.min_salary) {
    filledFields += 1;
  }

  if (formValues.desired_salary) {
    filledFields += 1;
  }

  if (formValues.city) {
    filledFields += 1;
  }

  if (formValues.languages_knowledge && formValues.languages_knowledge.length > 0) {
    filledFields += 1;
  }

  if (formValues.work_environment && formValues.work_environment.filter((item) => !!item).length > 0) {
    filledFields += 1;
  }

  if (formValues.relocation_to_diff_country) {
    allFields += 1;
    if (formValues.relocation_countries && formValues.relocation_countries.length > 0) {
      filledFields += 1;
    }
  }

  if (formValues.relocation_to_diff_city) {
    allFields += 1;
    if (formValues.relocation_cities && formValues.relocation_cities.length > 0) {
      filledFields += 1;
    }
  }

  return (filledFields / allFields) * 100;
};
