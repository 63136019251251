import { FC } from 'react';

interface Props {
  size?: number;
}

export const VkIcon: FC<Props> = ({ size }) => (
  <svg width={size} height={size} fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="6" y="6" width="12" height="12" fill="white" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.12333 5.12333C4 6.25 4 8.05667 4 11.68V12.32C4 15.94 4 17.7533 5.12333 18.8767C6.25 20 8.05667 20 11.68 20H12.32C15.94 20 17.7533 20 18.8767 18.8767C20 17.75 20 15.9433 20 12.32V11.68C20 8.06 20 6.24667 18.8767 5.12333C17.75 4 15.9433 4 12.32 4H11.68C8.06 4 6.24667 4 5.12333 5.12333ZM6.70333 8.89C6.78667 13.05 8.97667 15.5567 12.5867 15.5567H12.7967V13.1733C14.1133 13.3067 15.0933 14.29 15.4933 15.5567H17.39C17.2029 14.843 16.868 14.1766 16.4069 13.6006C15.9458 13.0247 15.3688 12.552 14.7133 12.2133C15.54 11.7167 16.7133 10.5167 16.99 8.89H15.2633C14.9033 10.2133 13.8267 11.4133 12.7967 11.5267V8.89H11.0433V13.5067C9.97667 13.24 8.58667 11.9467 8.53 8.89H6.70333Z"
      fill="#0077FF"
    />
  </svg>
);
