import { ResumeFormResource } from '@/features/resume-builder';

export const calculateTechnologyStackFormFillRate = (formValues: ResumeFormResource): number => {
  const allFields = 5;
  let filledFields = 0;

  if (formValues.specialization_areas && formValues.specialization_areas.length > 0) {
    filledFields += 1;
  }

  if (formValues.specializations && formValues.specializations.length > 0) {
    filledFields += 1;
  }

  if (formValues.programming_languages && formValues.programming_languages.length > 0) {
    filledFields += 1;
  }

  if (formValues.technologies && formValues.technologies.length > 0) {
    filledFields += 1;
  }

  if (formValues.databases && formValues.databases.length > 0) {
    filledFields += 1;
  }

  return (filledFields / allFields) * 100;
};
