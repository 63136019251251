import { FC } from 'react';

interface Props {
  size?: number;
}

export const JournalIcon: FC<Props> = ({ size = 24 }) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.2 7.2H16.8H7.2ZM7.2 10.4H16.8H7.2ZM12 13.6H16.8H12ZM12 16.8H16.8H12ZM4 19.52V4.48C4 4.3527 4.05057 4.23061 4.14059 4.14059C4.23061 4.05057 4.3527 4 4.48 4H19.52C19.6473 4 19.7694 4.05057 19.8594 4.14059C19.9494 4.23061 20 4.3527 20 4.48V19.52C20 19.6473 19.9494 19.7694 19.8594 19.8594C19.7694 19.9494 19.6473 20 19.52 20H4.48C4.3527 20 4.23061 19.9494 4.14059 19.8594C4.05057 19.7694 4 19.6473 4 19.52V19.52Z"
      stroke="currentColor"
      strokeWidth="1.49999"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.70001 17V14H7.20001V17H7.70001Z"
      fill="currentColor"
      stroke="currentColor"
      strokeWidth="1.50001"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
