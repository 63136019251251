import { Alert, Button, confirmDialog } from '@geeckocom/core-ui';
import { PlusCircleIcon } from '@heroicons/react/outline';
import { XIcon } from '@heroicons/react/solid';
import { useTranslations } from 'next-intl';
import { FC, Fragment, useEffect } from 'react';
import { FormProvider, UnpackNestedValue, useFieldArray, useForm } from 'react-hook-form';

import { ResumeBuilderFormProps } from '@/features/resume-builder/forms/types';
import { ResumeFormResource } from '@/features/resume-builder/model';
import { useGeneratedApi } from '@/hooks/useApi';
import { ApiError, ResumeCertificate, ResumeUniversity } from '@/shared/api';
import { FormField } from '@/shared/components/form-field';
import { FormInput } from '@/shared/components/form-input';
import { Heading } from '@/shared/components/heading';
import { WideButton } from '@/shared/components/wide-button/WideButton';
import { FormPartialDate } from '@/shared/data-components/form-partial-date';
import { usePreventFormLeaving } from '@/shared/hooks/use-prevent-form-leaving';
import { handleValidationErrors } from '@/shared/lib/handle-validation-errors';
import { PageTitle } from '@/shared/page-title';

export const ResumeEducationForm: FC<ResumeBuilderFormProps> = ({ resume, onFieldChanged, onSubmitted }) => {
  const t = useTranslations();
  const api = useGeneratedApi();

  const formMethods = useForm<ResumeFormResource>({ defaultValues: resume });
  const {
    control,
    handleSubmit,
    formState: { isSubmitting, isSubmitted, isSubmitSuccessful, isDirty },
    watch,
    getValues,
    setError,
    trigger,
  } = formMethods;

  const isResumeCompleted = resume.state === 'completed';

  usePreventFormLeaving(isSubmitting, isSubmitSuccessful, isDirty);

  // Update fields calculation
  const formFields = watch();

  useEffect(() => {
    onFieldChanged('education', formFields as ResumeFormResource);
  }, [formFields]);

  // Universities
  const {
    fields: universitiesFields,
    append: appendUniversity,
    remove: removeUniversity,
  } = useFieldArray<ResumeFormResource, 'universities'>({
    control,
    name: 'universities',
  });

  // Certificates
  const {
    fields: certificatesFields,
    append: appendCertificate,
    remove: removeCertificate,
  } = useFieldArray<ResumeFormResource, 'certificates'>({
    control,
    name: 'certificates',
  });

  const handleFormSubmit = async (data: UnpackNestedValue<ResumeFormResource>) => {
    try {
      const response = await api.resume.patchApiResumeEducation({
        universities: (data.universities || []) as ResumeUniversity[],
        certificates: (data.certificates || []) as ResumeCertificate[],
      });

      if (response.data) {
        await onSubmitted(response.data);
      }
    } catch (e) {
      if (e instanceof ApiError) {
        if (e.status === 422) {
          handleValidationErrors(setError, e.body.errors);
        }
      }
    }
  };

  return (
    <FormProvider {...formMethods}>
      <PageTitle title={t('features.resume_builder.steps.education.title')} />
      <form onSubmit={handleSubmit(handleFormSubmit)}>
        <div className="container">
          <div className="mb-8">
            <Heading>{t('features.resume_builder.resume_education_form.title')}</Heading>
          </div>

          <div className="mb-8">
            <Alert>{t('features.resume_builder.resume_education_form.hint_text')}</Alert>
          </div>

          <section>
            <h2 className="text-xl text-text-secondary font-bold mb-11">
              {t('features.resume_builder.resume_education_form.main_education_title')}
            </h2>
            {universitiesFields.length > 0 ? (
              universitiesFields.map((university, universityIndex) => {
                const universityName = watch(`universities.${universityIndex}.name` as `universities.0.name`);
                const faculty = watch(`universities.${universityIndex}.faculty` as `universities.0.faculty`);
                const isEmpty = !universityName && !faculty;

                return (
                  <Fragment key={university.id}>
                    {universityIndex > 0 ? <div className="h-[1px] bg-neutral-lines-one mt-10 mb-14" /> : null}
                    <div className="row gy-5">
                      <div className="col-24 md:col-12">
                        <FormField
                          label={t('features.resume_builder.resume_education_form.university_name_label')}
                          htmlFor={`resume-builder--${universityIndex}--university-name`}
                          required
                        >
                          <FormInput
                            id={`resume-builder--${universityIndex}--university-name`}
                            name={`universities.${universityIndex}.name` as `universities.0.name`}
                            control={control}
                            rules={{ required: t('features.resume_builder.validation.required_field') }}
                          />
                        </FormField>
                      </div>
                      <div className="col-24 md:col-12">
                        <FormField
                          label={t('features.resume_builder.resume_education_form.faculty_label')}
                          htmlFor={`resume-builder--${universityIndex}--faculty`}
                          required
                        >
                          <FormInput
                            id={`resume-builder--${universityIndex}--faculty`}
                            name={`universities.${universityIndex}.faculty` as `universities.0.faculty`}
                            control={control}
                            rules={{ required: t('features.resume_builder.validation.required_field') }}
                          />
                        </FormField>
                      </div>
                    </div>
                    <div className="mt-5">
                      <FormField label={t('features.resume_builder.resume_education_form.study_period_label')}>
                        <div className="row gy-5">
                          <div className="col-24 md:col-11">
                            <FormPartialDate
                              control={control}
                              watch={watch}
                              trigger={trigger}
                              getValues={getValues}
                              isSubmitted={isSubmitted}
                              monthFieldName={
                                `universities.${universityIndex}.from_month` as `universities.0.from_month`
                              }
                              monthId={`resume-builder--university--${university.id}-from-month`}
                              yearFieldName={`universities.${universityIndex}.from_year` as `universities.0.from_year`}
                              yearId={`resume-builder--university--${university.id}-from-year`}
                              withoutPast
                              nullable
                            />
                          </div>

                          <div className="col-2">
                            <label className="text-xs">&nbsp;</label>
                            <div className="h-10 flex items-center justify-center text-xl text-slate-400">&ndash;</div>
                          </div>

                          <div className="col-24 md:col-11">
                            <FormPartialDate
                              control={control}
                              watch={watch}
                              trigger={trigger}
                              getValues={getValues}
                              isSubmitted={isSubmitted}
                              monthFieldName={`universities.${universityIndex}.to_month` as `universities.0.to_month`}
                              monthId={`resume-builder--university--${university.id}-to-month`}
                              yearFieldName={`universities.${universityIndex}.to_year` as `universities.0.to_year`}
                              yearId={`resume-builder--university--${university.id}-to-year`}
                              currentFieldName={`universities.${universityIndex}.current` as `universities.0.current`}
                              currentLabel={t('features.resume_builder.resume_education_form.till_present')}
                              withoutPast
                              previousMonthFieldName={
                                `universities.${universityIndex}.from_month` as `universities.0.from_month`
                              }
                              previousYearFieldName={
                                `universities.${universityIndex}.from_year` as `universities.0.from_year`
                              }
                              nullable
                            />
                          </div>
                        </div>
                      </FormField>
                    </div>

                    <div>
                      <Button
                        type="button"
                        variant="ghost"
                        color="danger"
                        leadingIcon={<XIcon className="h-5 w-5" />}
                        onClick={() => {
                          if (!isEmpty) {
                            confirmDialog({
                              message: t('features.resume_builder.resume_education_form.university_remove.message'),
                              title: t('features.resume_builder.resume_education_form.university_remove.title'),
                              confirmButton: t(
                                'features.resume_builder.resume_education_form.university_remove.delete_button',
                              ),
                              cancelButton: t(
                                'features.resume_builder.resume_education_form.university_remove.cancel_button',
                              ),
                              onConfirm: async () => {
                                removeUniversity(universityIndex);
                              },
                            });
                          } else {
                            removeUniversity(universityIndex);
                          }
                        }}
                      >
                        {t('features.resume_builder.resume_education_form.remove_button')}
                      </Button>
                    </div>
                  </Fragment>
                );
              })
            ) : (
              <Alert>{t('features.resume_builder.resume_education_form.no_universities')}</Alert>
            )}

            <div className="mt-10">
              <WideButton
                onClick={() => {
                  appendUniversity({});
                }}
              >
                <div className="flex items-center space-x-1">
                  <span>
                    <PlusCircleIcon className="w-6 h-6" />
                  </span>
                  <span>{t('features.resume_builder.resume_education_form.add_university')}</span>
                </div>
              </WideButton>
            </div>
          </section>

          <section>
            <h2 className="mb-11 mt-16 max-w-xs text-xl text-text-primary font-bold">
              {t('features.resume_builder.resume_education_form.additional_education_title')}
            </h2>
            {certificatesFields.length > 0 ? (
              certificatesFields.map((certificate, certificateIndex) => {
                const certificateName = watch(`certificates.${certificateIndex}.name` as `certificates.0.name`);
                const organization = watch(
                  `certificates.${certificateIndex}.organization` as `certificates.0.organization`,
                );
                const isEmpty = !certificateName && !organization;

                return (
                  <Fragment key={certificate.id}>
                    {certificateIndex > 0 ? <div className="h-[1px] bg-neutral-lines-one mt-10 mb-14" /> : null}
                    <div className="row gy-5">
                      <div className="col-24 md:col-12">
                        <FormField
                          label={t('features.resume_builder.resume_education_form.certificate_name')}
                          htmlFor={`resume-builder--${certificateIndex}--certificate-name`}
                          required
                        >
                          <FormInput
                            id={`resume-builder--${certificateIndex}--certificate-name`}
                            name={`certificates.${certificateIndex}.name` as `certificates.0.name`}
                            control={control}
                            rules={{ required: t('features.resume_builder.validation.required_field') }}
                          />
                        </FormField>
                      </div>
                      <div className="col-24 md:col-12">
                        <FormField
                          label={t('features.resume_builder.resume_education_form.issuing_organization')}
                          htmlFor={`resume-builder--${certificateIndex}--organization`}
                          required
                        >
                          <FormInput
                            id={`resume-builder--${certificateIndex}--organization`}
                            name={`certificates.${certificateIndex}.organization` as `certificates.0.organization`}
                            control={control}
                            rules={{ required: t('features.resume_builder.validation.required_field') }}
                          />
                        </FormField>
                      </div>
                    </div>
                    <div className="mt-4">
                      <FormField label={t('features.resume_builder.resume_education_form.issue_date')} required>
                        <div className="row gy-5">
                          <div className="col-24 md:col-11">
                            <FormPartialDate
                              control={control}
                              watch={watch}
                              trigger={trigger}
                              getValues={getValues}
                              isSubmitted={isSubmitted}
                              monthFieldName={
                                `certificates.${certificateIndex}.issue_month` as `certificates.0.issue_month`
                              }
                              monthId={`resume-builder--certificate--${certificate.id}-from-issue_month`}
                              yearFieldName={
                                `certificates.${certificateIndex}.issue_year` as `certificates.0.issue_year`
                              }
                              yearId={`resume-builder--certificate--${certificate.id}-issue-year`}
                              withoutPast
                            />
                          </div>

                          <div className="col-2">
                            <label className="text-xs">&nbsp;</label>
                            <div className="h-10 flex items-center justify-center text-xl text-slate-400">&ndash;</div>
                          </div>

                          <div className="col-24 md:col-11">
                            <FormPartialDate
                              control={control}
                              watch={watch}
                              trigger={trigger}
                              getValues={getValues}
                              isSubmitted={isSubmitted}
                              monthFieldName={
                                `certificates.${certificateIndex}.expiration_month` as `certificates.0.expiration_month`
                              }
                              monthId={`resume-builder--certificate--${certificate.id}-expiration-month`}
                              yearFieldName={
                                `certificates.${certificateIndex}.expiration_year` as `certificates.0.expiration_year`
                              }
                              yearId={`resume-builder--certificate--${certificate.id}-expiration-year`}
                              currentFieldName={
                                `certificates.${certificateIndex}.no_expiration` as `certificates.0.no_expiration`
                              }
                              currentLabel={t('features.resume_builder.resume_education_form.not_expire')}
                              previousMonthFieldName={
                                `certificates.${certificateIndex}.issue_month` as `certificates.0.issue_month`
                              }
                              previousYearFieldName={
                                `certificates.${certificateIndex}.issue_year` as `certificates.0.issue_year`
                              }
                            />
                          </div>
                        </div>
                      </FormField>
                    </div>

                    <div className="flex mt-8 row gy-5">
                      <div className="col-24 md:col-12">
                        <FormField
                          label={t('features.resume_builder.resume_education_form.credential_id')}
                          htmlFor={`resume-builder--${certificateIndex}--credential_id`}
                        >
                          <FormInput
                            id={`resume-builder--${certificateIndex}--credential_id`}
                            name={`certificates.${certificateIndex}.credential_id` as `certificates.0.credential_id`}
                            control={control}
                          />
                        </FormField>
                      </div>
                      <div className="col-24 md:col-12">
                        <FormField
                          label={t('features.resume_builder.resume_education_form.credential_url')}
                          htmlFor={`resume-builder--${certificateIndex}--credential_url`}
                        >
                          <FormInput
                            id={`resume-builder--${certificateIndex}--credential_url`}
                            name={`certificates.${certificateIndex}.credential_url` as `certificates.0.credential_url`}
                            control={control}
                          />
                        </FormField>
                      </div>
                    </div>

                    <div>
                      <Button
                        type="button"
                        variant="ghost"
                        color="danger"
                        leadingIcon={<XIcon className="h-5 w-5" />}
                        onClick={() => {
                          if (!isEmpty) {
                            confirmDialog({
                              message: t(
                                'features.resume_builder.resume_education_form.additional_education_remove.message',
                              ),
                              title: t(
                                'features.resume_builder.resume_education_form.additional_education_remove.title',
                              ),
                              confirmButton: t(
                                'features.resume_builder.resume_education_form.additional_education_remove.delete_button',
                              ),
                              cancelButton: t(
                                'features.resume_builder.resume_education_form.additional_education_remove.cancel_button',
                              ),
                              onConfirm: async () => {
                                removeCertificate(certificateIndex);
                              },
                            });
                          } else {
                            removeCertificate(certificateIndex);
                          }
                        }}
                      >
                        {t('features.resume_builder.resume_education_form.remove_button')}
                      </Button>
                    </div>
                  </Fragment>
                );
              })
            ) : (
              <Alert>{t('features.resume_builder.resume_education_form.no_certificates')}</Alert>
            )}

            <div className="mt-10">
              <WideButton
                onClick={() => {
                  appendCertificate({});
                }}
              >
                <div className="flex items-center space-x-1">
                  <span>
                    <PlusCircleIcon className="w-6 h-6" />
                  </span>
                  <span>{t('features.resume_builder.resume_education_form.add_additional_education')}</span>
                </div>
              </WideButton>
            </div>
          </section>

          <div className="flex space-x-4 mt-12">
            <Button type="submit" isLoading={isSubmitting} size="lg">
              {isResumeCompleted
                ? t('features.resume_builder.submit_button_save')
                : t('features.resume_builder.submit_button_continue')}
            </Button>
          </div>
        </div>
      </form>
    </FormProvider>
  );
};

export { calculateEducationFormFillRate } from './model';
