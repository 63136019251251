import { useQuery } from 'react-query';
import { UseQueryResult } from 'react-query/types/react/types';

import { ResumeFormResource } from '@/features/resume-builder';
import { useUtilityApi } from '@/hooks/useApi';
import { WorkFeature } from '@/shared/api-utility';

export const useGetFeatures = (): UseQueryResult<WorkFeature[]> => {
  const api = useUtilityApi();
  return useQuery(['work features preferences'], async () => {
    const response = await api.enums.getApiEnumsWorkFeatures();
    return response.data;
  });
};

export const calculatePreferencesFormFillRate = (formValues: ResumeFormResource): number => {
  const allFields = 5;
  let filledFields = 0;

  if (formValues.preferable_features) {
    const selectedWorkFeatures = formValues.preferable_features.filter((item) => !!item).length;
    filledFields += selectedWorkFeatures > 5 ? 5 : selectedWorkFeatures;
  }

  return (filledFields / allFields) * 100;
};
