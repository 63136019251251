import { useTranslations } from 'next-intl';
import { FC } from 'react';

import { EnrichedResumeEnumTool, EnrichedResumeTool } from '@/shared/api';

interface Props {
  specializations: Array<EnrichedResumeEnumTool>;
  programming_languages: Array<EnrichedResumeTool>;
  technologies: Array<EnrichedResumeTool>;
  databases: Array<EnrichedResumeTool>;
}

interface SectionProps {
  title: string;
  items: Array<EnrichedResumeTool>;
}

const ResumeExperienceStackSection: FC<SectionProps> = ({ title, items }) => {
  if (items.length === 0) {
    return null;
  }

  return (
    <div className="flex items-baseline">
      <div className="text-sm leading-normal shrink-0 text-text-primary opacity-90 w-[136px]">{title}</div>
      <ul>
        {items.map(({ name, slug, icon_url }) => (
          <li
            key={slug}
            className="inline-block mr-2 mb-2 px-2 py-[2px] text-[13px] border border-neutral-lines-one rounded-lg text-text-primary font-semibold text-sm leading-normal"
          >
            {icon_url ? (
              <img src={icon_url} alt="" className="w-4 h-4 inline-block mr-1.5 rounded inline-block" />
            ) : null}
            {name}
          </li>
        ))}
      </ul>
    </div>
  );
};

export const ResumeExperienceStackInfo: FC<Props> = ({
  specializations,
  programming_languages,
  technologies,
  databases,
}) => {
  const t = useTranslations();

  if (!specializations.length && !programming_languages.length && !technologies.length && !databases.length) {
    return null;
  }

  return (
    <div className="mt-4">
      {specializations.length > 0 && (
        <div className="flex items-baseline">
          <div className="text-sm shrink-0 leading-normal text-text-primary opacity-90 w-[136px]">
            {t('entities.resume.resume_experience_item.stack_info.specialization_label')}
          </div>
          <ul>
            {specializations.map(({ slug, name }) => (
              <li
                key={slug}
                className="inline-block mr-2 mb-2 px-2 py-[2px] text-[13px] border border-neutral-lines-one rounded-lg text-text-primary font-semibold text-sm leading-normal"
              >
                {name}
              </li>
            ))}
          </ul>
        </div>
      )}
      <ResumeExperienceStackSection
        title={t('entities.resume.resume_experience_item.stack_info.programming_languages_label')}
        items={programming_languages}
      />
      <ResumeExperienceStackSection
        title={t('entities.resume.resume_experience_item.stack_info.technologies_label')}
        items={technologies}
      />
      <ResumeExperienceStackSection
        title={t('entities.resume.resume_experience_item.stack_info.databases_label')}
        items={databases}
      />
    </div>
  );
};
