import { FC } from 'react';

interface Props {
  size?: number;
}

export const BitbucketIcon: FC<Props> = ({ size = 36 }) => (
  <svg width={size} height={size} fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4.52 5a.525.525 0 0 0-.396.173.495.495 0 0 0-.117.406l2.176 12.855c.027.157.11.3.235.404a.709.709 0 0 0 .447.162h10.44a.522.522 0 0 0 .336-.117.496.496 0 0 0 .176-.302l2.176-13a.486.486 0 0 0-.117-.405.511.511 0 0 0-.395-.173L4.519 5Zm9.163 9.29H10.35l-.903-4.586h5.042l-.807 4.587Z"
      fill="#2684FF"
    />
    <path
      d="M19.288 9.698h-4.807l-.807 4.583h-3.33l-3.93 4.54a.705.705 0 0 0 .447.165h10.435a.52.52 0 0 0 .336-.117.495.495 0 0 0 .176-.302l1.48-8.87Z"
      fill="url(#a)"
    />
    <defs>
      <linearGradient id="a" x1="20.4" y1="10.981" x2="15.047" y2="18.791" gradientUnits="userSpaceOnUse">
        <stop offset=".18" stopColor="#0052CC" />
        <stop offset="1" stopColor="#2684FF" />
      </linearGradient>
    </defs>
  </svg>
);
