import Router from 'next/router';
import { useTranslations } from 'next-intl';
import { useEffect, useState } from 'react';

export const usePreventFormLeaving = (isSubmitting, isSubmitSuccessful, isDirty) => {
  const isServer = typeof window === 'undefined';
  const t = useTranslations();
  const [blockNavigation, setBlockNavigation] = useState(false);

  const checkRouteStart = () => {
    if (blockNavigation) {
      // eslint-disable-next-line no-restricted-globals,no-alert
      if (!confirm(t('shared.prevent-form-leaving.confirm_message'))) {
        if (Router.router) {
          Router.router.abortComponentLoad('', { shallow: false });
          Router.router.events.emit('routeChangeError');
          throw new Error('Abort page navigation, please ignore this error');
        }
      }
    }

    return true;
  };

  useEffect(() => {
    Router.events.on('routeChangeStart', checkRouteStart);

    if (isDirty && !isSubmitSuccessful && !isSubmitting) {
      setBlockNavigation(true);
    } else {
      setBlockNavigation(false);
    }

    if (isServer) {
      return;
    }
    if (blockNavigation) {
      window.onbeforeunload = (event: BeforeUnloadEvent) => {
        const message = t('shared.prevent-form-leaving.confirm_message');
        if (event) {
          event.returnValue = message;
        }
        return message;
      };
    } else {
      window.onbeforeunload = null;
    }

    return () => {
      Router.events.off('routeChangeStart', checkRouteStart);
      window.onbeforeunload = null;
    };
  });
};
