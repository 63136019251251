import { Chip, confirmDialog } from '@geeckocom/core-ui';
import clsx from 'clsx';
import { useTranslations } from 'next-intl';
import { FC, useCallback, useId, useMemo } from 'react';
import {
  Controller,
  FieldPathValue,
  FieldValues,
  Path,
  UnpackNestedValue,
  useFormContext,
  UseFormReturn,
} from 'react-hook-form';

import { useFetchAreasAndSpecializations, useFetchTools } from '@/entities/skill-set-builder/model';
import { NoTargetIcon } from '@/entities/skill-set-builder/NoTargetIcon';
import { TargetIcon } from '@/entities/skill-set-builder/TargetIcon';
import { useUtilityApi } from '@/hooks/useApi';
import { useCurrentUser } from '@/queries/auth/useCurrentUser';
import { EnrichedResumeTechnologyStackTool, ResumeTechnologyStackTool } from '@/shared/api';
import { Specialization, SpecializationAreaEnum, SpecializationAreaMap, ToolResource } from '@/shared/api-utility';
import { ChipsGroupSkeleton } from '@/shared/components/chips-group-skeleton';
import { FormError } from '@/shared/components/form-error';
import { FormField } from '@/shared/components/form-field';
import { ModernSelect } from '@/shared/components/modern-select';
import { getId } from '@/shared/lib/get-id';

export type SkillsSetBuilderFormValues = {
  specialization_areas?: Array<ResumeTechnologyStackTool>;
  specializations?: Array<ResumeTechnologyStackTool> | null;
  programming_languages?: Array<ResumeTechnologyStackTool> | null;
  technologies?: Array<ResumeTechnologyStackTool> | null;
  databases?: Array<ResumeTechnologyStackTool> | null;
};

interface SkillSetBuilderSectionProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends Path<TFieldValues> = Path<TFieldValues>,
> {
  formContext: UseFormReturn<TFieldValues>;
  fieldName: TName;
  tools: ToolResource[];
  loadTools: (query: string) => Promise<ToolResource[]>;
  empty?: boolean;
  emptyText?: string;
  selectPlaceholderText?: string;
  withoutJobSearch?: boolean;
  creatable?: boolean;
}

type ToolWithSlug = {
  id?: number;
  slug?: string | null;
};

const getToolIdentifier = (tool: ToolWithSlug): string | undefined => {
  if (tool.slug) {
    return `slug-${tool.slug}`;
  }
  if (tool.id) {
    return `id-${tool.id}`;
  }
  return undefined;
};

const toolsMatcher = (a: ToolWithSlug, b: ToolWithSlug): boolean => {
  const aId = getToolIdentifier(a);
  const bId = getToolIdentifier(b);

  return !!aId && !!bId && aId === bId;
};

function SkillSetBuilderSection<
  TFieldValues extends FieldValues = FieldValues,
  TName extends Path<TFieldValues> = Path<TFieldValues>,
>(props: SkillSetBuilderSectionProps<TFieldValues, TName>) {
  const {
    formContext,
    fieldName,
    tools,
    loadTools,
    empty,
    emptyText,
    selectPlaceholderText,
    creatable,
    withoutJobSearch,
  } = props;

  const toolsValue: EnrichedResumeTechnologyStackTool[] = formContext.watch(fieldName) ?? [];
  const selectedToolsSlugs = toolsValue.map((item) => item.slug);
  const toolsValueMap: Record<string, EnrichedResumeTechnologyStackTool> = useMemo(() => {
    const result: Record<string, EnrichedResumeTechnologyStackTool> = {};
    toolsValue?.forEach((tool) => {
      const key = getToolIdentifier(tool);
      if (key) {
        result[key] = tool;
      }
    });
    return result;
  }, [toolsValue]);

  const toolsSlugs: string[] = useMemo(
    () => (tools?.map((tool) => getToolIdentifier(tool) as string) || []).filter(Boolean),
    [tools],
  );

  const toolsNames: string[] = useMemo(
    () => (toolsValue?.map((tool) => tool.name?.toLowerCase() as string) || []).filter(Boolean),
    [tools],
  );

  if (empty) {
    return <Chip disabled>{emptyText}</Chip>;
  }

  return (
    <Controller
      name={fieldName}
      control={formContext.control}
      render={({ fieldState, formState }) => (
        <div>
          <div className={clsx('-m-2 flex flex-wrap rounded', !!fieldState.error && 'bg-red-50')}>
            {[
              ...tools,
              ...toolsValue.filter((toolItem) => !toolsSlugs.includes(getToolIdentifier(toolItem) as string)),
            ].map((tool) => {
              const key = getToolIdentifier(tool);
              if (!key) {
                return null;
              }

              const toolValue = toolsValueMap[key];

              return (
                <div key={key} className="m-2">
                  <Chip
                    active={!!toolValue}
                    partial={!withoutJobSearch && !toolValue?.job_search}
                    onClick={() => {
                      let newToolsValue: ResumeTechnologyStackTool[];
                      if (toolValue) {
                        if (!toolValue.job_search || withoutJobSearch) {
                          newToolsValue = [...toolsValue.filter((s) => !toolsMatcher(s, toolValue))];
                        } else {
                          newToolsValue = toolsValue.map((s) => {
                            if (toolsMatcher(s, toolValue)) {
                              return {
                                ...tool,
                                ...s,
                                job_search: false,
                              };
                            }
                            return s;
                          });
                        }
                      } else {
                        newToolsValue = [...toolsValue, { ...tool, slug: tool.slug!, job_search: true }];
                      }

                      formContext.setValue(
                        fieldName,
                        newToolsValue as UnpackNestedValue<FieldPathValue<TFieldValues, TName>>,
                      );
                      if (formState.isSubmitted) {
                        formContext.trigger(fieldName);
                      }
                    }}
                  >
                    {tool.name}
                    {toolValue && !withoutJobSearch && !toolValue?.job_search && (
                      <span className="ml-2">
                        <NoTargetIcon />
                      </span>
                    )}
                    {toolValue && !withoutJobSearch && toolValue?.job_search && (
                      <span className="ml-2">
                        <TargetIcon />
                      </span>
                    )}
                  </Chip>
                </div>
              );
            })}
            <div className="m-2 min-w-[280px]">
              <ModernSelect<ToolResource>
                async
                loadOptions={loadTools}
                getValue={(tool) => getToolIdentifier(tool) as string}
                getLabel={(tool) => tool.name}
                placeholder={selectPlaceholderText}
                renderOption={(option) => (
                  <div className={`flex items-center ${selectedToolsSlugs.includes(option.slug!) && 'opacity-40'}`}>
                    {option.icon_url ? (
                      <div className="w-4 h-4 mr-2">
                        <img className="w-4 h-4 rounded" src={option.icon_url} alt="" />
                      </div>
                    ) : null}
                    <span>{option.name}</span>
                  </div>
                )}
                cacheOptions={false}
                loadOnInit
                value={null}
                renderValue={(option) => (
                  <div className="flex items-center">
                    {option.icon_url ? (
                      <div className="w-4 h-4 mr-2">
                        <img className="w-4 h-4 rounded" src={option.icon_url} alt="" />
                      </div>
                    ) : null}
                    <span>{option.name}</span>
                  </div>
                )}
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                getNewValue={(newName) => ({
                  id: getId(),
                  name: newName,
                  job_search: true,
                })}
                onChange={(newValue) => {
                  if (selectedToolsSlugs.includes(newValue?.slug ?? '')) {
                    return;
                  }
                  if (newValue) {
                    formContext.setValue(fieldName, [
                      ...toolsValue,
                      { ...newValue, slug: newValue.slug, job_search: true },
                    ] as UnpackNestedValue<FieldPathValue<TFieldValues, TName>>);
                    if (formState.isSubmitted) {
                      formContext.trigger(fieldName);
                    }
                  }
                }}
                isValidNewOption={(newOption) => !!newOption.length && !toolsNames.includes(newOption.toLowerCase())}
                creatable={creatable}
              />
            </div>
          </div>
          {fieldState.error?.message ? <FormError>{fieldState.error.message}</FormError> : null}
        </div>
      )}
    />
  );
}

interface SkillSetBuilderProps {
  limitAreas?: SpecializationAreaEnum[];
  limitSpecializations?: string[];
  withoutJobSearch?: boolean;
}

export const SkillSetBuilder: FC<SkillSetBuilderProps> = ({ limitAreas, limitSpecializations, withoutJobSearch }) => {
  const t = useTranslations();
  const api = useUtilityApi();
  const formContext = useFormContext<SkillsSetBuilderFormValues>();
  const currentUser = useCurrentUser();

  if (!formContext) {
    throw new Error('Form context is not defined. Wrap your form into <FormProvider /> component');
  }

  const areasAndSpecs = useFetchAreasAndSpecializations();
  const areas = useMemo(() => {
    if (limitAreas && limitAreas.length > 0) {
      return areasAndSpecs.data?.filter((area) => limitAreas.includes(area.id)) || [];
    }

    return areasAndSpecs.data || [];
  }, [areasAndSpecs.data, limitAreas]);

  const areasMap = useMemo(() => {
    const result: Record<string, SpecializationAreaMap> = {};

    areas.forEach((area) => {
      result[area.id] = area;
    });

    return result;
  }, [areas]);

  const areasValue = formContext.watch('specialization_areas') || [];
  const areasValueMap: Record<string, ResumeTechnologyStackTool> = useMemo(() => {
    const result: Record<string, ResumeTechnologyStackTool> = {};
    areasValue?.forEach((area) => {
      if (area.slug) {
        result[area.slug] = area;
      }
    });
    return result;
  }, [areasValue]);
  const isOtherAreaSelected = areasValueMap.other;

  const specializations = useMemo(() => {
    const result: Specialization[] = [];
    areasAndSpecs.data?.forEach((area) => {
      if (area.id && area.specializations && areasValueMap[area.id]) {
        result.push(...area.specializations);
      }
    });
    return limitSpecializations && limitSpecializations.length > 0
      ? result.filter((s) => limitSpecializations.includes(s.id))
      : result;
  }, [areasAndSpecs, areasValueMap, limitSpecializations]);

  const componentId = useId();

  const specializationsValue: ResumeTechnologyStackTool[] = formContext.watch('specializations') || [];
  const specializationsValueMap: Record<string, ResumeTechnologyStackTool> = useMemo(() => {
    const result: Record<string, ResumeTechnologyStackTool> = {};
    specializationsValue?.forEach((spec) => {
      if (spec.slug) {
        result[spec.slug] = spec;
      }
    });
    return result;
  }, [specializationsValue]);

  const programmingLanguages = useFetchTools('lang', specializationsValue);
  const programmingLanguagesValue: ResumeTechnologyStackTool[] = formContext.watch('programming_languages') || [];
  const programmingLanguagesSlugsIncluded = useMemo(
    () => [
      ...new Set([
        ...(programmingLanguages.data || []).map((lang) => lang.slug!),
        ...programmingLanguagesValue.map((lang) => lang.slug!),
      ]),
    ],
    [programmingLanguages.data, programmingLanguagesValue.length],
  );

  const loadOtherLanguages = useCallback(
    async (query: string) => {
      const response = await api.dictionaries.getApiDictionariesTools('lang', null, null, query, currentUser.data?.id);

      return (response.data || []).filter((item) => !programmingLanguagesSlugsIncluded.includes(item.slug!));
    },
    [api, programmingLanguagesSlugsIncluded, currentUser.data?.id],
  );

  const technologies = useFetchTools('tech', specializationsValue, programmingLanguagesValue);
  const technologiesValue: ResumeTechnologyStackTool[] = formContext.watch('technologies') || [];
  const technologiesSlugsIncluded = useMemo(
    () => [...(technologies.data || []).map((lang) => lang.slug!), ...technologiesValue.map((lang) => lang.slug!)],
    [technologies.data, technologiesValue],
  );

  const loadOtherTechnologies = useCallback(
    async (query: string) => {
      const response = await api.dictionaries.getApiDictionariesTools('tech', null, null, query, currentUser.data?.id);

      return (response.data || []).filter((item) => !technologiesSlugsIncluded.includes(item.slug!));
    },
    [api, technologiesSlugsIncluded, currentUser.data?.id],
  );

  const databases = useFetchTools('tech.db', specializationsValue);
  const databasesValue: ResumeTechnologyStackTool[] = formContext.watch('databases') || [];
  const databasesSlugsIncluded = useMemo(
    () => [...(databases.data || []).map((lang) => lang.slug!), ...databasesValue.map((lang) => lang.slug!)],
    [databases.data, databasesValue],
  );

  const loadOtherDatabases = useCallback(
    async (query: string) => {
      const response = await api.dictionaries.getApiDictionariesTools(
        'tech.db',
        null,
        null,
        query,
        currentUser.data?.id,
      );

      return (response.data || []).filter((item) => !databasesSlugsIncluded.includes(item.slug!));
    },
    [api, databasesSlugsIncluded, currentUser.data?.id],
  );

  return (
    <>
      <div role="group" aria-labelledby={`skills-set-builder-${componentId}-areas`}>
        <FormField
          label={t('entities.skill_set_builder.section_areas_label')}
          labelId={`skills-set-builder-${componentId}-areas`}
          required
        >
          {areasAndSpecs.isLoading ? <ChipsGroupSkeleton variant={2} /> : null}
          {!areasAndSpecs.isLoading && areasAndSpecs.data ? (
            <Controller
              name="specialization_areas"
              control={formContext.control}
              rules={{ required: t('features.resume_builder.validation.required_multi_field') }}
              render={({ fieldState, formState }) => (
                <div>
                  <div className={clsx('-m-2 flex flex-wrap rounded', !!fieldState.error && 'bg-red-50')}>
                    {areas.map((area) => {
                      const areaValue = areasValueMap[area.id];
                      const areaData = areasMap[area.id];

                      if (!areaData) {
                        return null;
                      }

                      return (
                        <div key={area.id} className="m-2">
                          <Chip
                            active={!!areaValue}
                            onClick={() => {
                              // Prepare new result
                              let newAreas: ResumeTechnologyStackTool[];
                              let unsetSpecIds: string[] = [];
                              if (areaValue) {
                                newAreas = areasValue.filter((value) => {
                                  if (value.slug !== areaValue.slug) {
                                    return true;
                                  }

                                  unsetSpecIds = areaData.specializations?.map((spec) => spec.id) || [];
                                  return false;
                                });
                              } else {
                                newAreas = [...areasValue, { slug: area.id, name: area.name }];
                              }

                              // Check, whether we have specs that don't satisfy new filter
                              if (unsetSpecIds.length > 0) {
                                const notMatchedSpecs = [...specializationsValue].filter(
                                  (spec) => unsetSpecIds.indexOf(spec.slug!) >= 0,
                                );

                                if (notMatchedSpecs.length > 0) {
                                  confirmDialog({
                                    title: t('entities.skill_set_builder.unset_specialization_confirm_title'),
                                    message: t('entities.skill_set_builder.unset_specialization_confirm_message', {
                                      // TODO: Replace id with name
                                      unsetSpecs: notMatchedSpecs.map((spec) => spec.slug).join(', '),
                                      unsetSpecsCount: notMatchedSpecs.length,
                                    }),
                                    confirmButton: t('entities.skill_set_builder.unset_specialization_confirm_button'),
                                    cancelButton: t(
                                      'entities.skill_set_builder.unset_specialization_confirm_cancel_button',
                                    ),
                                    onConfirm: async () => {
                                      formContext.setValue('specialization_areas', newAreas);
                                      if (formState.isSubmitted) {
                                        formContext.trigger('specialization_areas');
                                      }
                                    },
                                  });
                                  return;
                                }
                              }

                              formContext.setValue('specialization_areas', newAreas);
                              if (formState.isSubmitted) {
                                formContext.trigger('specialization_areas');
                              }
                            }}
                          >
                            {area.name}
                          </Chip>
                        </div>
                      );
                    })}
                  </div>
                  {fieldState.error?.message ? <FormError>{fieldState.error.message}</FormError> : null}
                </div>
              )}
            />
          ) : null}
        </FormField>
      </div>
      <div className="mt-9" role="group" aria-labelledby={`skills-set-builder-${componentId}-specializations`}>
        <FormField
          label={t('entities.skill_set_builder.section_specializations_label')}
          labelId={`skills-set-builder-${componentId}-specializations`}
          required={!isOtherAreaSelected}
        >
          {areasAndSpecs.isLoading ? <ChipsGroupSkeleton variant={3} /> : null}
          {!areasAndSpecs.isLoading && specializations ? (
            <Controller
              name="specializations"
              control={formContext.control}
              rules={{
                validate: (value) => {
                  if (isOtherAreaSelected) {
                    return true;
                  }

                  return value && value.length > 0
                    ? true
                    : t('features.resume_builder.validation.required_multi_field');
                },
              }}
              render={({ fieldState, formState }) => (
                <div>
                  {specializations.length > 0 ? (
                    <>
                      <div className={clsx('-m-2 flex flex-wrap rounded', fieldState.invalid && 'bg-red-50')}>
                        {specializations.map((spec) => {
                          const specializationValue = specializationsValueMap[spec.id];

                          return (
                            <div key={spec.id} className="m-2">
                              <Chip
                                active={!!specializationValue}
                                partial={!withoutJobSearch && !specializationValue?.job_search}
                                onClick={() => {
                                  let newSpecializations: ResumeTechnologyStackTool[];
                                  if (specializationValue) {
                                    if (!specializationValue.job_search || withoutJobSearch) {
                                      newSpecializations = [
                                        ...specializationsValue.filter((s) => s.slug !== specializationValue.slug),
                                      ];
                                    } else {
                                      newSpecializations = specializationsValue.map((s) => {
                                        if (s.slug === specializationValue.slug) {
                                          return {
                                            ...s,
                                            job_search: false,
                                          };
                                        }
                                        return s;
                                      });
                                    }
                                  } else {
                                    newSpecializations = [
                                      ...specializationsValue,
                                      { slug: spec.id, name: spec.name, job_search: true },
                                    ];
                                  }

                                  formContext.setValue('specializations', newSpecializations);
                                  if (formState.isSubmitted) {
                                    formContext.trigger('specializations');
                                  }
                                }}
                              >
                                {spec.name}
                                {specializationValue && !withoutJobSearch && !specializationValue?.job_search && (
                                  <span className="ml-2">
                                    <NoTargetIcon />
                                  </span>
                                )}
                                {specializationValue && !withoutJobSearch && specializationValue?.job_search && (
                                  <span className="ml-2">
                                    <TargetIcon />
                                  </span>
                                )}
                              </Chip>
                            </div>
                          );
                        })}
                      </div>
                      {fieldState.error?.message ? <FormError>{fieldState.error.message}</FormError> : null}
                    </>
                  ) : (
                    <Chip disabled>{t('entities.skill_set_builder.specializations_empty_select_areas')}</Chip>
                  )}
                </div>
              )}
            />
          ) : null}
        </FormField>
      </div>
      <div className="mt-9" role="group" aria-labelledby={`skills-set-builder-${componentId}-programming-languages`}>
        <FormField
          label={t('entities.skill_set_builder.section_programming_languages_label')}
          labelId={`skills-set-builder-${componentId}-programming-languages`}
        >
          {programmingLanguages.isLoading ? <ChipsGroupSkeleton variant={1} /> : null}
          {!programmingLanguages.isLoading ? (
            <SkillSetBuilderSection
              formContext={formContext}
              fieldName="programming_languages"
              tools={programmingLanguages.data || []}
              loadTools={loadOtherLanguages}
              empty={areasValue.length === 0 || specializationsValue.length === 0}
              emptyText={t('entities.skill_set_builder.specializations_empty_select_specialization')}
              selectPlaceholderText={t('entities.skill_set_builder.other_languages_placeholder')}
              withoutJobSearch={withoutJobSearch}
            />
          ) : null}
        </FormField>
      </div>
      <div className="mt-9" role="group" aria-labelledby={`skills-set-builder-${componentId}-technologies`}>
        <FormField
          label={t('entities.skill_set_builder.section_technologies_label')}
          labelId={`skills-set-builder-${componentId}-technologies`}
        >
          {technologies.isLoading ? <ChipsGroupSkeleton variant={4} /> : null}
          {!technologies.isLoading ? (
            <SkillSetBuilderSection
              formContext={formContext}
              fieldName="technologies"
              tools={technologies.data || []}
              loadTools={loadOtherTechnologies}
              empty={programmingLanguagesValue.length === 0}
              emptyText={t('entities.skill_set_builder.specializations_empty_select_language')}
              selectPlaceholderText={t('entities.skill_set_builder.other_technologies_placeholder')}
              withoutJobSearch={withoutJobSearch}
              creatable
            />
          ) : null}
        </FormField>
      </div>
      <div className="mt-9" role="group" aria-labelledby={`skills-set-builder-${componentId}-databases`}>
        <FormField
          label={t('entities.skill_set_builder.section_databases_label')}
          labelId={`skills-set-builder-${componentId}-databases`}
        >
          {databases.isLoading ? <ChipsGroupSkeleton variant={5} /> : null}
          {!databases.isLoading ? (
            <SkillSetBuilderSection
              formContext={formContext}
              fieldName="databases"
              tools={databases.data || []}
              loadTools={loadOtherDatabases}
              empty={specializationsValue.length === 0}
              emptyText={t('entities.skill_set_builder.specializations_empty_select_language')}
              selectPlaceholderText={t('entities.skill_set_builder.other_databases_placeholder')}
              withoutJobSearch={withoutJobSearch}
              creatable
            />
          ) : null}
        </FormField>
      </div>
    </>
  );
};
