import { ButtonHTMLAttributes, DetailedHTMLProps, ForwardedRef, forwardRef } from 'react';

import styles from './WideButton.module.css';

type Props = DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>;

const WideButtonInner = (props: Props, ref: ForwardedRef<HTMLButtonElement>) => (
  <button type="button" ref={ref} {...props} className={styles.Button} />
);

export const WideButton = forwardRef<HTMLButtonElement, Props>(WideButtonInner);
